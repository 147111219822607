import {useState, useCallback, useEffect, useRef} from 'react';
import {useRecoilState} from 'recoil';
import {companiesState, domainsState, aclsState} from './authAtom';
import {CompanyData, DomainData, AclData, AccessTokenData} from './authType';
import {v4 as uuid} from 'uuid';
import Parse from 'parse';

export const generateRandomToken = (len = 60) => {
  const chars: string[] = [];
  const token_len = len < 16 || len > 128 ? 60 : len;
  while (chars.length < token_len) {
    Array.prototype.push.call(chars, ...uuid().replaceAll('-', ''));
  }

  const token = chars.slice(0, token_len).join('');
  return token;
};

export const useCompany = () => {
  const [companies, setCompanies] = useRecoilState(companiesState);

  const reloadCompanies = useCallback(
    async (): Promise<boolean> =>
      Parse.Cloud.run('getCompanies')
      .then((result) => {
        setCompanies(result?.companies);
        return Boolean(result?.companies);
      })
      .catch((err) => {
        console.error(`failed to getCompanies: `, err);
        return false;
      }),
    [setCompanies]
  );

  const hasCompany = useCallback(
    async (companyId: string): Promise<boolean> =>
      Parse.Cloud.run('hasCompany', {
        companyId,
      })
      .then((res) => res?.has)
      .catch((err) => {
        console.error(`failed to hasCompany [${companyId}]: `, err);
        return false;
      }),
    []
  );

  const addCompany = useCallback(
    async (data: CompanyData): Promise<boolean> =>
      Parse.Cloud.run('addCompany', {
        companyId: data.id,
        name: data.name,
        description: data.description,
      })
      .then((_res) => reloadCompanies())
      .catch((err) => {
        console.error(`failed to addCompany: `, err);
        return false;
      }),
    [reloadCompanies]
  );

  const updateCompany = useCallback(
    async (data: CompanyData): Promise<boolean> =>
      Parse.Cloud.run('updateCompany', {
        companyId: data.id,
        name: data.name,
        description: data.description,
      })
      .then((_res) => reloadCompanies())
      .catch((err) => {
        console.error(`failed to updateCompany: `, err);
        return false;
      }),
    [reloadCompanies]
  );

  useEffect(() => {
    reloadCompanies();
  }, [reloadCompanies]);

  return {
    companies,
    reloadCompanies,
    hasCompany,
    addCompany,
    updateCompany,
  };
};

const safeJsonString = (json_string: string | string[]) =>
  typeof json_string === 'string' ? json_string : JSON.stringify(json_string);

export const useDomain = () => {
  const [domains, setDomains] = useRecoilState(domainsState);

  const getCompanyDomains = useCallback(
    async (companyId: string): Promise<boolean> => {
      if (companyId === undefined) {
        setDomains([]);
        return false;
      }
      return Parse.Cloud.run('getCompanyDomains', {
        companyId,
      })
      .then((res) => {
        const new_domains: DomainData[] = res?.domains.map((domain: any) => {
          domain.domain_info = JSON.stringify(domain.domain_info, null, 4);
          return domain;
        });

        setDomains(new_domains);
        return true;
      })
      .catch((err) => {
        console.error(`failed to getCompanyDomains [${companyId}]: `, err);
        return false;
      });
    },
    [setDomains]
  );

  const hasDomain = useCallback(
    async (domainId: string): Promise<boolean> =>
      Parse.Cloud.run('hasDomain', {
        domainId,
      })
      .then((res) => res?.has)
      .catch((err) => {
        console.error(`failed to hasDomain [${domainId}]: `, err);
        return false;
      }),
    []
  );

  const addDomain = useCallback(
    async (data: DomainData): Promise<boolean> =>
      Parse.Cloud.run('addDomain', {
        domainId: data.id,
        companyId: data.company,
        domainInfo: safeJsonString(data.domain_info),
      })
      .then((_res) => getCompanyDomains(data.company))
      .catch((err) => {
        console.error(`failed to addDomain [${data.id}]: `, err);
        return false;
      }),
    [getCompanyDomains]
  );

  const updateDomain = useCallback(
    async (data: DomainData) =>
      Parse.Cloud.run('updateDomain', {
        domainId: data.id,
        domainInfo: safeJsonString(data.domain_info),
      })
      .then((_res) => getCompanyDomains(data.company))
      .catch((err) => {
        console.error(`failed to updateDomain [${data.id}]: `, err);
        return false;
      }),
    [getCompanyDomains]
  );

  return {
    domains,
    getCompanyDomains,
    hasDomain,
    addDomain,
    updateDomain,
  };
};

export const useAcl = () => {
  const [acls, setAcls] = useRecoilState(aclsState);

  const getAcls = useCallback(
    async () =>
      Parse.Cloud.run('getAcls')
      .then((res) => {
        setAcls(
          res?.acls.map((acl: any) => {
            acl.acl_info = JSON.stringify(acl.acl_info, null, 4);
            return acl;
          })
        );
        return true;
      })
      .catch((err) => {
        console.error(`failed to getAcls: `, err);
        return false;
      }),
    [setAcls]
  );

  const hasAcl = useCallback(
    async (aclId: string): Promise<boolean> =>
      Parse.Cloud.run('hasAcl', {
        aclId,
      })
      .then((res) => res?.has)
      .catch((err) => {
        console.error(`failed to hasAcl [${aclId}]: `, err);
        return false;
      }),
    []
  );

  const addAcl = useCallback(
    async (data: AclData): Promise<boolean> =>
      Parse.Cloud.run('addAcl', {
        aclId: data.id,
        aclInfo: safeJsonString(data.acl_info),
      })
      .then((_res) => getAcls())
      .catch((err) => {
        console.error(`failed to addAcl [${data.id}]: `, err);
        return false;
      }),
    [getAcls]
  );

  const updateAcl = useCallback(
    async (data: AclData): Promise<boolean> =>
      Parse.Cloud.run('updateAcl', {
        aclId: data.id,
        aclInfo: safeJsonString(data.acl_info),
      })
      .then((_res) => getAcls())
      .catch((err) => {
        console.error(`failed to updateAcl [${data.id}]: `, err);
        return false;
      }),
    [getAcls]
  );

  useEffect(() => {
    getAcls();
  }, [getAcls]);

  return {
    acls,
    getAcls,
    hasAcl,
    addAcl,
    updateAcl,
  };
};

interface SelectedIds {
  companyId: string;
  domainId?: string;
}

const didAllDomain = 'all';

export const useAccessToken = () => {
  const [accessTokens, setAccessTokens] = useState<AccessTokenData[]>([]);
  const companyRawTokens = useRef<any[]>([]);
  const selected = useRef({
    companyId: '',
  } as SelectedIds);

  const selectAccessTokens = (rawTokens: any[], domainId?: string) => {
    setAccessTokens(
      rawTokens
      .filter((token: any) => {
        if (!domainId) return true;
        const {domain_ids = []} = token;
        return (
          domain_ids.includes(domainId) || domain_ids.includes(didAllDomain)
        );
      })
      .map((token: any) => {
        token.domain_ids = safeJsonString(token.domain_ids || []);
        token.acl_ids = token.acl_ids && safeJsonString(token.acl_ids);
        return token as AccessTokenData;
      })
    );
  };

  const setRawTokens = useCallback((rawTokens: any[], domainId?: string) => {
    companyRawTokens.current = rawTokens;
    selectAccessTokens(rawTokens, domainId);
  }, []);

  const getTokens = useCallback(
    async (
      companyId: string,
      domainId: string | undefined
    ): Promise<boolean> => {
      if (companyId === undefined || companyId === '') {
        setAccessTokens([]);
        setRawTokens([]);
        return false;
      }

      const {domainId: currentDomainId, companyId: currentCompanyId} =
        selected.current;
      if (currentCompanyId === companyId) {
        if (currentDomainId === domainId) return false;
        selected.current.domainId = domainId;
        selectAccessTokens(companyRawTokens.current, domainId);
        return true;
      }

      selected.current.companyId = companyId;
      return Parse.Cloud.run('getDomainTokens', {
        companyId,
      })
      .then((res) => {
        setRawTokens(res?.tokens || []);
        return true;
      })
      .catch((err) => {
        console.error(
          `failed to getDomainTokens [${companyId} - ${domainId}]: `,
          err
        );
        return false;
      });
    },
    [setRawTokens]
  );

  const reloadTokens = useCallback(async (): Promise<boolean> => {
    const {companyId, domainId} = selected.current;
    selected.current = {companyId: ''};
    return getTokens(companyId, domainId);
  }, [getTokens]);

  const addToken = useCallback(
    async (data: AccessTokenData): Promise<boolean> =>
      Parse.Cloud.run('addToken', {
        accessToken: data.access_token,
        companyId: data.company,
        domainIds: safeJsonString(data.domain_ids),
        ...(data.acl_ids && {aclIds: safeJsonString(data.acl_ids)}),
      })
      .then((_res) => reloadTokens())
      .catch((err) => {
        console.error(`failed to addToken [${data.access_token}]: `, err);
        return false;
      }),
    [reloadTokens]
  );

  const updateToken = useCallback(
    async (data: AccessTokenData): Promise<boolean> =>
      Parse.Cloud.run('updateToken', {
        accessToken: data.access_token,
        domainIds: safeJsonString(data.domain_ids),
        ...(data.acl_ids && {aclIds: safeJsonString(data.acl_ids)}),
      })
      .then((_res) => reloadTokens())
      .catch((err) => {
        console.error(`failed to updateToken [${data.access_token}]: `, err);
        return false;
      }),
    [reloadTokens]
  );

  const refreshToken = useCallback(
    async (accessToken: string, newToken: string): Promise<boolean> =>
      Parse.Cloud.run('refreshToken', {
        accessToken,
        newToken,
      })
      .then((_res) => reloadTokens())
      .catch((err) => {
        console.error(
          `failed to refreshToken [${accessToken} => ${newToken}]: `,
          err
        );
        return false;
      }),
    [reloadTokens]
  );

  const validatePublicToken = useCallback(async () => {
    const {companyId} = selected.current;
    if (!companyId || companyId === '') return;

    const publicToken: any = companyRawTokens.current.find((token: any) => {
      const {domain_ids = []} = token;
      return domain_ids.includes(didAllDomain);
    });

    if (publicToken !== undefined) {
      if (publicToken.access_token) {
        refreshToken(publicToken.access_token as string, generateRandomToken());
      }
      return;
    }

    return addToken({
      access_token: generateRandomToken(),
      company: companyId,
      domain_ids: JSON.stringify([didAllDomain]),
    } as AccessTokenData);
  }, [addToken, refreshToken]);

  const copyToken = useCallback(
    async (accessToken: string, newToken: string): Promise<boolean> =>
      Parse.Cloud.run('copyToken', {
        accessToken,
        newToken,
      })
      .then((_res) => reloadTokens())
      .catch((err) => {
        console.error(
          `failed to copyToken [${accessToken} => ${newToken}]: `,
          err
        );
        return false;
      }),
    [reloadTokens]
  );

  return {
    accessTokens,
    getTokens,
    addToken,
    updateToken,
    refreshToken,
    copyToken,
    validatePublicToken,
  };
};
