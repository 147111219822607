import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthManagerHome from './AuthManagerHome';

const AuthManager = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`}>
        <AuthManagerHome />
      </Route>
    </Switch>
  );
};

export default AuthManager;
