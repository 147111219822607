import {Checkbox, Button, Col, Divider, Form, Input, Modal, Row, Select, Space, Spin, Typography} from "antd";
import {useAuth} from "../hooks/auth";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useState, useEffect, useCallback} from "react";
import {passwordPolicy} from "../helper";

interface UserApi {
  createUser?: (userForm: any) => Promise<void>,
  updateUser?: (objectId: String, userForm: any) => Promise<void>,
  deleteUser?: (objectId: String) => Promise<void>,
}

interface UserPanelProps {
  objectId: string;
  initialUser: any,
  isPending: boolean,
  api: UserApi,
  onCreate: () => void;
  onUpdate: (logout: boolean) => void;
  onDelete: () => void;
  onCancel: () => void;
  roles: any[],
  companies: any[],
}


const UserManagerPanel = ({
                            objectId,
                            initialUser,
                            isPending,
                            api,
                            onCreate,
                            onUpdate,
                            onDelete,
                            onCancel,
                            roles,
                            companies,
                          }: UserPanelProps) => {

  const {user, isKKMaster} = useAuth();
  const isMine = (user === undefined ? 0 : user.id) === objectId;
  const isOwner = (user === undefined ? false : user.get("owner"));

  // mode
  const mode = (objectId === "new") ? "new" : "edit"
  const isEditMode = mode === 'edit';
  const isNewMode = mode === 'new';

  // form - company
  const [companyOptions, setCompanyOptions] = useState<any[]>([]);
  useEffect(() => {
    if (companies !== undefined) {
      setCompanyOptions(companies.map((e) => ({
        label: e.name,
        value: e.id,
      })));
    }
  }, [companies]);

  // form - role input
  const [role, setRole] = useState("");
  const [roleMessage, setRoleMessage] = useState("");

  useEffect(() => {
    const r = roles.find((e) => e.value === role);
    if (r !== undefined) {
      setRoleMessage(r.extra);
    }
  }, [role, roles]);

  // form
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      role: initialUser.roles !== undefined ? initialUser.roles[0] : "",
      ...initialUser
    })
    if (initialUser.roles !== undefined) {
      setRole(initialUser.roles[0])
    }
  }, [form, initialUser]);


  const passwordValidator = useCallback((value, required) => passwordPolicy(value, required), []);

  // event
  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const safeConfirm = (title: string, content: string, confirmFunc: () => void) => {
    Modal.confirm({
      title,
      content,
      icon: <ExclamationCircleOutlined/>,
      okText: '네',
      okType: 'danger',
      cancelText: '아니오',
      onOk() {
        confirmFunc();
      },
      onCancel() {
      },
    });
  };

  const showCancelConfirm = () => {
    Modal.confirm({
      title: '작성중인 내용을 취소하겠습니까?',
      icon: <ExclamationCircleOutlined/>,
      okText: '네',
      okType: 'danger',
      cancelText: '아니오',
      onOk() {
        onCancel();
      },
      onCancel() {
      },
    });
  };

  return (
    <>
      {isEditMode && isPending ? (
        <Spin
          tip="Loading..."
          style={{position: 'fixed', top: '50%', left: '50%'}}
        ></Spin>
      ) : (
        <Col>
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => {
              safeConfirm(
                objectId === undefined ? "사용자를 추가합니다." : "사용자 정보를 수정합니다.",
                "수정하신 내용을 저장하시겠습니까?",
                () => {
                  if (isEditMode) {
                    api.updateUser && api.updateUser(objectId, values).then(() => {
                      onUpdate(isMine && (values.email !== initialUser.email || values.password !== undefined));
                    });
                  } else {
                    api.createUser && api.createUser(values).then(() => {
                      onCreate();
                    })
                  }
                },
              );
            }}
            onFinishFailed={onFinishFailed}
          >

            {isKKMaster &&
              (<Form.Item
                data-test="error-message-of-company-in-bill"
                name="company"
                label="회사"
                required-mark="true"
                rules={[{required: true, message: '회사를 선택해주세요.'}]}
              >
                <Select options={companyOptions} disabled={!isNewMode}/>
              </Form.Item>)
            }

            <Form.Item
              data-test="error-message-of-name-in-add-use"
              name="name"
              label="이름"
              required-mark="true"
              rules={[
                {required: true, message: '이름을 입력해주세요'},
                {min: 2, message: "이름은 최소 2글자 이상입니다."}
              ]}
            >
              <Input data-test="test_name"/>
            </Form.Item>

            <Form.Item
              data-test="error-message-of-email-in-add-use"
              name="email"
              label="이메일"
              required-mark="true"
              rules={[
                {required: true, message: '이메일을 입력해주세요'},
                {pattern: RegExp(/^\S+@\S+\.\S+$/), message: "이메일 형식을 입력해주세요."}
              ]}
            >
              <Input data-test="test_name"/>
            </Form.Item>

            <Form.Item
              data-test="error-message-of-phone-no-in-sign"
              name="phoneNo"
              label="전화번호"
              required-mark="true"
              rules={[{required: true, message: '전화번호를 입력해주세요.'}]}
            >
              <Input data-test="sign_phone_no"/>
            </Form.Item>

            <Form.Item
              data-test="error-message-of-password-in-sign"
              name="password"
              label="비밀번호"
              required-mark={isNewMode ? "true" : "false"}
              rules={isNewMode ? [
                {required: true, message: "비밀번호는 필수 입니다."},
                {validator: (_, value) => passwordValidator(value, true)}
              ] : [
                {validator: (_, value) => passwordValidator(value, false)}
              ]}
            >
              <Input data-test="sign_password" type="password"/>
            </Form.Item>

            <Form.Item
              data-test="error-message-of-role-in-sign"
              name="role"
              label="권한"
              required-mark="true"
              rules={[{required: true, message: '권한을 선택해주세요.'}]}
              extra={roleMessage}
            >
              <Select options={roles} disabled={isMine} value={role} onChange={(value) => {
                setRole(value);
              }}/>
            </Form.Item>

            {role === "domain-master" && isOwner ? (
              <Form.Item
                data-test="error-message-of-owner-in-sign"
                valuePropName="checked"
                name="owner"
              >
                <Checkbox data-test="sign_owner" disabled={isMine}>소유자 변경</Checkbox>
              </Form.Item>
            ) : (<></>)}

            <Divider/>
            <Row justify="space-between" align="middle">
              {
                (isEditMode && objectId !== undefined && objectId !== "new" && !isMine) ? (
                  <Typography.Text
                    data-test="btn-delete-in-user-manager-panel"
                    type="danger"
                    underline
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      safeConfirm(
                        "사용자 삭제",
                        "사용자를 삭제하시겠습니까?",
                        () => {
                          api.deleteUser && api.deleteUser(objectId).then(() => {
                            onDelete();
                          });
                        });
                    }}
                  >
                    삭제
                  </Typography.Text>
                ) : (
                  <div/>
                )
              }

              <Space>
                <Button
                  data-test="btn-cancel-in-user-manager"
                  htmlType="button"
                  onClick={() => {
                    if (isEditMode || isNewMode) {
                      showCancelConfirm();
                    } else {
                      onCancel();
                    }
                  }}
                >
                  취소
                </Button>
                <Button
                  data-test="btn-save-in-user-manager"
                  loading={isPending}
                  type="primary"
                  htmlType="submit"
                >
                  저장
                </Button>
              </Space>
            </Row>
          </Form>
        </Col>
      )}
    </>
  );

}

export default UserManagerPanel;