/* eslint-disable no-template-curly-in-string */
import { FC } from 'react';
import { Form, Button, Modal, Input } from 'antd';
import { ModalMode, AccessTokenData, CompanyData } from '../hooks/authType';
import { generateRandomToken } from '../hooks/authManager';

interface AuthAccessTokenModalProps {
  mode: ModalMode;
  company: CompanyData;
  close: () => void;
  addToken: (data: AccessTokenData) => Promise<boolean>;
  updateToken: (data: AccessTokenData) => Promise<boolean>;
  token: AccessTokenData;
}

/*
2739e304158cee4ff57a258d2cae3216d0ef7dc60a1d040bb4f3a5db28b9
*/

export const AuthAccessTokenModal: FC<AuthAccessTokenModalProps> = ({
  mode,
  company,
  close,
  addToken,
  updateToken,
  token,
}) => {
  const [form] = Form.useForm();
  // const { Text } = Typography;

  const edit_mode = mode === 'edit';

  const handleCancel = () => {
    form.resetFields();
    close();
  };

  const handleOk = () => {
    form.submit();
  };

  const onFinish = (values: any) => {
    const { access_token, domain_ids, acl_ids } = values;
    const new_access_token = {
      access_token,
      company: company.id,
      domain_ids,
      acl_ids,
    };
    if (edit_mode) {
      updateToken(new_access_token).then((updated) => {
        if (updated) {
          close();
        } else {
          alert('failed to update access token');
        }
      });
      return;
    }

    addToken(new_access_token)
      .then((saved) => {
        if (saved) {
          close();
        } else {
          alert('failed to save access token.');
        }
      })
      .catch((err) => {
        console.error('failed to save access token', err);
        alert('failed to save access token.');
      });
  };

  // const onChange = (value: any) => {
  //   console.log('access token values (onChange): ', value);
  // };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      string: '${label} is not a valid string!',
    },
    string: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const created_access_token = edit_mode ? undefined : generateRandomToken();

  const initialValues = {
    ...(created_access_token && {
      access_token: created_access_token,
    }),
    company: company.id,
    ...(edit_mode && token),
  };

  return (
    <>
      <Modal
        key={'access_token_modal'}
        visible={true}
        title={'엑세스 토큰 ' + (mode === 'add' ? '추가' : '편집')}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={[
          <div key="buttons" style={{ textAlign: 'center' }}>
            <Button key="cancel" onClick={handleCancel}>
              취소
            </Button>
            <Button key="save" type="primary" onClick={handleOk}>
              저장
            </Button>
          </div>,
        ]}
      >
        <Form
          form={form}
          onFinish={onFinish}
          // onChange={onChange}
          labelCol={{ span: 4 }}
          layout="horizontal"
          // labelAlign="left"
          key={'token_edit_box'}
          initialValues={initialValues}
          validateMessages={validateMessages}
        >
          <Form.Item
            key={'access_token'}
            name="access_token"
            label="엑세스 토큰"
            // wrapperCol={{ span: 14 }}
          >
            <Input disabled={true}></Input>
          </Form.Item>
          <Form.Item
            key={'token_company_id'}
            name="company"
            label="회사"
            wrapperCol={{ span: 6 }}
          >
            <Input disabled={true}></Input>
          </Form.Item>
          <Form.Item
            key={'token_domain_ids'}
            name="domain_ids"
            label="사용 서비스"
            rules={[{ type: 'string', min: 0, max: 500 }]}
          >
            <Input.TextArea showCount autoSize={true} />
          </Form.Item>
          <Form.Item
            key={'token_acl_ids'}
            name="acl_ids"
            label="ACL"
            rules={[{ type: 'string', min: 0, max: 500 }]}
          >
            <Input.TextArea showCount autoSize={true} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AuthAccessTokenModal;
