import Parse from 'parse';

export interface ParseFindJSONResultWithCountType {
    results: any[];
    count: number;
}

export interface FindSchemaListOptions {
    limit?: number;
    skip?: number;
    keyword?: RegExp;
}

export interface FindImporterListOptions {
    page: number,
    pageSize: number,
    keyword: RegExp
}

export const cloudSchema = {
    list: (options: FindSchemaListOptions): Promise<ParseFindJSONResultWithCountType> => {
        return Parse.Cloud.run("listSchema", options);
    },
    listAll: (kmService: string): Promise<any[]> => {
        return Parse.Cloud.run("listSchemaAll", {kmService});
    },
    create: (data: any) => {
        return Parse.Cloud.run("createSchema", data)
    },
    get: (objectId: string): Promise<Parse.Object<Parse.Attributes>> => {
        return Parse.Cloud.run("getSchema", {objectId});
    },
    update: (objectId: string, data: any) => {
      return Parse.Cloud.run("updateSchema", {objectId, data});
    },
    delete: (objectId: string) => {
      return Parse.Cloud.run("deleteSchema", {objectId});
    },
    hasImporterItem: (objectId: string) => {
        return Parse.Cloud.run('schemaHasImporterItem', {schemaId: objectId})
    }
};

export const cloudImporterItem = {
    list: (options: FindImporterListOptions): Promise<ParseFindJSONResultWithCountType> => {
        return Parse.Cloud.run("listImporterItem", options);
    },
}

export const cloudFunctionGetRdbTableInfo = (id: string) => {
    return Parse.Cloud.run('get-rdb-info', {
        dbInfoId: id,
    });
};

export const cloudFunctionRunRdbSql = (objectId: string, sql: string) => {
    return Parse.Cloud.run('run-rdb-sql', {
        dbInfoId: objectId,
        sql,
    });
};


export const cloudCompany = {
    listAll: (): Promise<any[]> => {
        return Parse.Cloud.run("listCompanyAll");
    },
}

export const cloudUser = {
    signUp: async (data: any) => {
        return Parse.Cloud.run("signUp", data)
            .then((r) => {
                if (r === false) return Promise.reject();
                return Promise.resolve();
            });
    },
    list: async (): Promise<ParseFindJSONResultWithCountType> => {
        return Parse.Cloud.run("listUser");
    },
    create: async (data: any) => {
        return Parse.Cloud.run("createUser", data)
            .then((r) => {
                if (r === false) return Promise.reject();
                return Promise.resolve();
            });
    },
    get: async (objectId: string): Promise<Parse.User<Parse.Attributes>> => {
        return Parse.Cloud.run("getUser", {objectId});
    },
    update: async (objectId: string, data: any) => {
        return Parse.Cloud.run("updateUser", {objectId, data,})
            .then((r) => {
                if (r === false) return Promise.reject();
                return Promise.resolve();
            });
    },
    delete: async (objectId: string) => {
        return Parse.Cloud.run("deleteUser", {objectId})
            .then((r) => {
                if (r === false) return Promise.reject();
                return Promise.resolve();
            });
    }
}

export const cloudKMService = {
    list: (): Promise<ParseFindJSONResultWithCountType> => {
        return Parse.Cloud.run("listKMService");
    },
    listAll: (company: string): Promise<any[]> => {
        return Parse.Cloud.run("listKMServiceAll", {company});
    },
    create: (data: any): Promise<void> => {
        return Parse.Cloud.run("createKMService", data)
            .then((r) => {
                if (!r.success) return Promise.reject(r);
                return Promise.resolve();
            });
    },
    get: (objectId: string): Promise<Parse.Object<Parse.Attributes>> => {
        return Parse.Cloud.run("getKMService", {objectId});
    },
    update: (objectId: string, data: any) => {
        return Parse.Cloud.run("updateKMService", {objectId, data})
            .then((r) => {
                if (r === false) return Promise.reject();
                return Promise.resolve();
            });
    },
    delete: (objectId: string) => {
        return Parse.Cloud.run("deleteKMService", {objectId})
            .then((r) => {
                if (r === false) return Promise.reject();
                return Promise.resolve();
            });
    },
    start: (objectId: string) => {
        return Parse.Cloud.run("startKMService", {objectId})
            .then((r) => {
                if (r === false) return Promise.reject();
                return Promise.resolve();
            });
    },
    stop: (objectId: string) => {
        return Parse.Cloud.run("stopKMService", {objectId})
            .then((r) => {
                if (r === false) return Promise.reject();
                return Promise.resolve();
            });
    },
}

export const cloudKMServiceUsageMonth = {
    list: (): Promise<any[]> => {
        return Parse.Cloud.run("listKMServiceUsageMonths");
    },
    listByDomain: (domain: string): Promise<any[]> => {
        return Parse.Cloud.run("listKMServiceUsageMonthsByDomain", {
            domain,
        });
    },
}

export const cloudBill = {
    list: (): Promise<ParseFindJSONResultWithCountType> => {
        return Parse.Cloud.run("listBill");
    },
    listOptions: () => {
        return Parse.Cloud.run("listBillOptions")
    },
    listOptionsByDomain: (domain: string) => {
        return Parse.Cloud.run("listBillOptionsByDomain", {
            domain,
        });
    },
    create: (data: any) => {
        return Parse.Cloud.run("createBill", data)
            .then((r) => {
                if (r === false) return Promise.reject();
                return Promise.resolve();
            });
    },
    get: (objectId: string): Promise<Parse.Object<Parse.Attributes>> => {
        return Parse.Cloud.run("getBill", {objectId});
    },
    update: (objectId: string, data: any) => {
        return Parse.Cloud.run("updateBill", {objectId, data})
            .then((r) => {
                if (r === false) return Promise.reject();
                return Promise.resolve();
            });
    },
    delete: (objectId: string) => {
        return Parse.Cloud.run("deleteBill", {objectId})
            .then((r) => {
                if (r === false) return Promise.reject();
                return Promise.resolve();
            });
    },
}

export const cloudTenant = {
    list: (): Promise<ParseFindJSONResultWithCountType> => {
        return Parse.Cloud.run("listTenant");
    },
    create: (data: any) => {
        return Parse.Cloud.run("createTenant", data)
            .then((r) => {
                if (r === false) return Promise.reject();
                return Promise.resolve();
            });
    },
    get: (objectId: string): Promise<Parse.Object<Parse.Attributes>> => {
        return Parse.Cloud.run("getTenant", {objectId});
    },
    update: (objectId: string, data: any) => {
        return Parse.Cloud.run("updateTenant", {objectId, data})
            .then((r) => {
                if (r === false) return Promise.reject();
                return Promise.resolve();
            });
    },
    delete: (objectId: string) => {
        return Parse.Cloud.run("deleteTenant", {objectId})
            .then((r) => {
                if (r === false) return Promise.reject();
                return Promise.resolve();
            });
    },
}

export const cloudGraphMap = {
    extend: (): Promise<any[]> => {
        return Parse.Cloud.run("extendGraphMap");
    },
}

