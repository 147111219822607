import { useEffect, useState } from 'react';
import { useAccessToken } from '../hooks/authManager';
import { Table, Typography, Button } from 'antd';
import { useRecoilState } from 'recoil';
import {
  selectedCompanyState,
  selectedDomainState,
  selectedTokenState,
} from '../hooks/authAtom';
import { ModalMode } from '../hooks/authType';
import AuthAccessTokenModal from './AuthAccessTokenModal';

const { Title } = Typography;

const AuthAccessToken: React.FC<{}> = () => {
  const [token, selectToken] = useRecoilState(selectedTokenState);
  const [company] = useRecoilState(selectedCompanyState);
  const [domain] = useRecoilState(selectedDomainState);
  const {
    accessTokens,
    getTokens,
    addToken,
    updateToken,
    validatePublicToken,
  } = useAccessToken();
  const [modalMode, setModalMode] = useState('close' as ModalMode);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const openAddToken = () => setModalMode('add');
  const openEditToken = () => token?.access_token && setModalMode('edit');
  const closeEditToken = () => setModalMode('close');

  useEffect(() => {
    // console.log('echo: selected company: ', company.id);
    getTokens(company.id, domain.id);
  }, [company.id, domain.id, getTokens]);

  useEffect(() => {
    if (selectedRowKeys.length === 0) return;
    const selected_id = selectedRowKeys[0];
    const found_token = accessTokens.find(
      (token) => token.access_token === selected_id
    );
    if (found_token) {
      selectToken(found_token);
    }
  }, [accessTokens, selectToken, selectedRowKeys]);

  const columns = [
    {
      title: '토큰',
      dataIndex: 'access_token',
    },
    {
      title: '회사',
      dataIndex: 'company',
    },
    {
      title: '서비스',
      dataIndex: 'domain_ids',
    },
    {
      title: '접근 권한',
      dataIndex: 'acl_ids',
      render: (v: string) => v || 'none',
    },
  ];

  return (
    <>
      <div className="part_header" id="access_token_header">
        <Title> 토큰 </Title>
      </div>
      <Table
        sortDirections={['ascend', 'descend', 'ascend']}
        rowKey={'access_token'}
        columns={columns}
        dataSource={accessTokens}
        rowSelection={{
          selectedRowKeys,
          type: 'radio',
          onChange: (selectedKeys: React.Key[], selectedRows: any) => {
            // selectToken(selectedRows[0]);
            setSelectedRowKeys(selectedKeys);
          },
        }}
        pagination={{
          hideOnSinglePage: true,
        }}
      ></Table>
      <div className="right_buttons" id="access_token_buttons">
        <Button shape="round" onClick={validatePublicToken}>
          공용 토큰 추가/갱신
        </Button>
        <Button shape="round" onClick={openAddToken}>
          추가
        </Button>
        <Button
          shape="round"
          onClick={openEditToken}
          disabled={!token?.access_token}
        >
          변경
        </Button>
      </div>
      {modalMode !== 'close' ? (
        <AuthAccessTokenModal
          key={'auth_access_token_modal'}
          mode={modalMode}
          company={company}
          close={closeEditToken}
          addToken={addToken}
          updateToken={updateToken}
          token={token}
        ></AuthAccessTokenModal>
      ) : undefined}
    </>
  );
};

export default AuthAccessToken;
