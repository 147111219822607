import { useState } from 'react';
import { useDeleteFileRequest } from '../hooks/file';
import { useSaveDBConnectionRequest } from '../hooks/DBConnectionInfo';
// import { useAuth } from '../hooks/auth';
import _ from 'lodash';
import Parse from 'parse';
import {
  Form,
  Button,
  Modal,
  Upload,
  message,
  Typography,
  Cascader,
  Radio,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

export const UploadModal = ({ isModalOpen, onSuccess, onCancel }: any) => {
  const [{ pending: isPending }, { run: saveDBConnection }]: any =
    useSaveDBConnectionRequest();
  const [, { run: deleteFileUsingParseAPI }] = useDeleteFileRequest();
  const [form] = Form.useForm();
  const { Text } = Typography;
  // const { user } = useAuth();
  const [fileType, setFileType] = useState('.sql');
  const options = [
    {
      value: '대교',
      label: '대교',
      children: [
        {
          value: 'USM',
          label: 'USM',
        },
      ],
    },
  ];

  const props = {
    // file 업로드 구현
    name: 'file',
    // https://stackoverflow.com/a/58921190
    customRequest: (options: any) => {
      const { onSuccess, onError, file, onProgress } = options;

      const parseFile = new Parse.File(file.name, file);

      parseFile
        .save({
          progress: (
            progressValue: number,
            loaded: number,
            total: number,
            { type }: any
          ) => {
            if (type === 'upload' && progressValue !== null) {
              // Update the UI using progressValue
              onProgress({ percent: (loaded / total) * 100 });
            }
          },
        })
        .then(() => {
          onSuccess(parseFile.toJSON());
        })
        .catch((e) => {
          onError(e);
        });
    },
    // delete file 기능 구현
    onRemove: (file: any) => {
      deleteFileUsingParseAPI(file.response.name)
        .then(() => message.success(`${file.name} file removed successfully`))
        .catch((e) => {
          message.error(`${file.name} file remove failed.`);
          throw new Error(e);
        });
    },
    onChange(info: any) {
      // console.log('change', info);
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleCancel = () => {
    form.resetFields();
    // resetRadioAndDisableState();
    onCancel();
  };

  const handleOk = () => {
    form.submit();
    // resetRadioAndDisableState();
  };

  const onFinish = (values: any) => {
    // console.log('Success:', values);
    form.resetFields();
    const fileList = values.fileUploader.fileList;
    const { fileInfo } = values;
    const [provider, service] = fileInfo;
    const name = _.map(fileList, (fi) => fi.name).join(', ');
    const files = _.map(fileList, (fi) => {
      // @ts-ignore
      return Parse.File.fromJSON({
        __type: 'File',
        ...fi.response,
      });
    });
    saveDBConnection({
      name,
      provider,
      service,
      files,
    })
      .then((result: any) => {
        onSuccess(result);
      })
      .catch((e: any) => {
        throw new Error(e);
      });
  };

  const onChange = (value: any) => {
    // console.log(value);
  };

  return (
    <Modal
      title="파일 업로드"
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
      footer={[
        <div style={{ textAlign: 'center' }}>
          <Button key="cancel" onClick={handleCancel}>
            취소
          </Button>
          <Button type="primary" loading={isPending} onClick={handleOk}>
            확인
          </Button>
        </div>,
      ]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        labelAlign="left"
        initialValues={{ fileType: '.sql' }}
      >
        <div style={{ marginBottom: '12px' }}>
          <Text>파일 정보 설정</Text>
        </div>

        <Form.Item name="fileInfo">
          <Cascader
            defaultValue={['대교', 'USM']}
            options={options}
            onChange={onChange}
          />
        </Form.Item>
        <Form.Item name="fileType">
          <Radio.Group
            disabled={
              form.getFieldValue('fileUploader') &&
              form.getFieldValue('fileUploader').fileList.length > 0
            }
            onChange={(e) => {
              setFileType(e.target.value);
            }}
            value={fileType}
          >
            <Radio value={'.sql'}>SQL</Radio>
            <Radio value={'.csv'}>CSV</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'fileUploader'}
          extra={`파일당 최대 100MB까지 등록할 수 있습니다\nSQL 파일은 단일 업로드만 가능하고, CSV 파일은 복수 업로드가 가능합니다`}
          style={{ whiteSpace: 'pre-line' }}
        >
          {/* @ts-ignore */}
          <Upload {...props} accept={fileType}>
            <Button
              disabled={
                fileType === '.sql' &&
                form.getFieldValue('fileUploader') &&
                form.getFieldValue('fileUploader').fileList.length > 0
              }
              icon={<UploadOutlined />}
            >
              파일 추가
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};
