import { atom } from 'recoil';
import { CompanyData, DomainData, AclData, AccessTokenData } from './authType';

export const companiesState = atom({
  key: 'companiesState',
  default: [] as CompanyData[],
});

export const domainsState = atom({
  key: 'domainsState',
  default: [] as DomainData[],
});

export const aclsState = atom({
  key: 'aclsState',
  default: [] as AclData[],
});

export const selectedCompanyState = atom({
  key: 'selectedCompanyState',
  default: {} as CompanyData,
});

export const selectedDomainState = atom({
  key: 'selectedDomainState',
  default: {} as DomainData,
});

export const selectedAclState = atom({
  key: 'selectedAclState',
  default: {} as AclData,
});

export const selectedTokenState = atom({
  key: 'selectedTokenState',
  default: {} as AccessTokenData,
});
