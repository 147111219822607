import { useEffect } from 'react';
import { Form, Button, Select, Cascader, Col } from 'antd';
import _ from 'lodash';

const { Option, OptGroup } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const secondLayout = {
  wrapperCol: { span: 20, offset: 4 },
};
const MatchingForm = ({
  schema,
  table,
  onFinish,
  onFinishFailed,
  schemaList,
  onChangeOrderRule,
}: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form, schema, table]);
  const options = _.map(
    _.filter(schemaList, (item) => item.type === 'node'),
    (v, k) => {
      return {
        value: v.objectId,
        label: v.name,
        children: _.map(v.props, (v, k) => ({
          value: v.name,
          label: v.name,
        })),
      };
    }
  );
  const orderOptions = _.map(table.columns, (v, k) => {
    return {
      value: v.COLUMN_NAME,
      label: v.COLUMN_NAME,
      children: [
        { value: 'ASC', label: '오름차순' },
        { value: 'DESC', label: '내림차순' },
      ],
    };
  });
  const onFinishInner = (values: any) => {
    form.resetFields();
    const matchingList = _.map(
      _.pickBy(values, (v) => _.identity(v.property !== undefined)),
      (v, k) => {
        return v.property === 'from' || v.property === 'to'
          ? {
              column: k,
              targetType: v.property,
              target: {
                schemaName: _.find(
                  schemaList,
                  (schema) => schema.objectId === v.schema[0]
                ).name,
                schemaObjectId: v.schema[0],
                propertyName: v.schema[1],
              },
            }
          : v.property === 'fromTo'
          ? {
              column: k,
              targetType: v.property,
              target: {
                schemaName: _.find(
                  schemaList,
                  (schema) => schema.objectId === v.schema[0]
                ).name,
                schemaObjectId: v.schema[0],
                propertyName: v.schema[1],
                orderPropertyName: v.order[0],
                orderAscending: v.order[1] === 'ASC' ? true : false,
              },
            }
          : {
              column: k,
              targetType: 'property',
              target: {
                propertyName: v.property,
              },
            };
      }
    );

    const dataObj = {
      tableName: table.tableName,
      schemaName: schema.name,
      schemaType: schema.type,
      matchingList,
      createdAt: new Date(),
    };
    onFinish(dataObj);
  };
  const matchSameName = () => {
    const sameNames = _.filter(table.columns, (c) =>
      _.find(schema.props, (p) => p.name === c.COLUMN_NAME)
    ).map((c) => c.COLUMN_NAME);
    const fieldsValue = _.reduce(
      sameNames,
      (a, v) => ({ ...a, [v]: { property: v } }),
      {}
    );
    form.setFieldsValue(fieldsValue);
  };
  return (
    <Col>
      <Button onClick={() => matchSameName()}>자동 매칭</Button>
      <Form
        labelAlign={'left'}
        {...layout}
        form={form}
        // onFinish={onFinish}
        onFinish={onFinishInner}
        onFinishFailed={onFinishFailed}
        // onChange={(c) => console.log(c)}
      >
        {_.map(table.columns, (col, index) => {
          const columnName = col.COLUMN_NAME;
          return (
            <div key={index}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldsValue }) => (
                  <Form.Item name={[columnName, 'property']} label={columnName}>
                    <Select placeholder="선택 안함" allowClear>
                      <OptGroup label="properties">
                        {schema &&
                          _.map(schema, (v, k) =>
                            k === 'props'
                              ? _.map(v, (property, idx) => (
                                  <Option
                                    key={idx}
                                    value={property.name}
                                    disabled={_.find(
                                      getFieldsValue(),
                                      (column) =>
                                        column.property === property.name
                                    )}
                                  >
                                    {property.name} ( type: {property.type} )
                                  </Option>
                                ))
                              : null
                          )}
                      </OptGroup>
                      {schema.type === 'relation' && (
                        <OptGroup label="relation type">
                          <>
                            <Option
                              value="from"
                              disabled={_.find(
                                getFieldsValue(),
                                (column) =>
                                  column.property === 'from' ||
                                  column.property === 'fromTo'
                              )}
                            >
                              From
                            </Option>
                            <Option
                              value="to"
                              disabled={_.find(
                                getFieldsValue(),
                                (column) =>
                                  column.property === 'to' ||
                                  column.property === 'fromTo'
                              )}
                            >
                              To
                            </Option>
                            <Option
                              value="fromTo"
                              disabled={_.find(
                                getFieldsValue(),
                                (column) =>
                                  column.property === 'from' ||
                                  column.property === 'to' ||
                                  column.property === 'fromTo'
                              )}
                            >
                              From/To
                            </Option>
                          </>
                        </OptGroup>
                      )}
                    </Select>
                  </Form.Item>
                )}
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                  getFieldValue([columnName]) &&
                  _.includes(
                    ['from', 'to', 'fromTo'],
                    getFieldValue([columnName]).property
                  ) ? (
                    <>
                      <Form.Item
                        {...secondLayout}
                        name={[columnName, 'schema']}
                      >
                        <Cascader options={options} placeholder="선택안함" />
                      </Form.Item>
                      {getFieldValue([columnName]).property === 'fromTo' && (
                        <Form.Item
                          {...secondLayout}
                          name={[columnName, 'order']}
                        >
                          <Cascader
                            options={orderOptions}
                            placeholder="정렬"
                            onChange={(value) => {
                              onChangeOrderRule({
                                key: value[0],
                                direction: value[1],
                              });
                            }}
                          />
                        </Form.Item>
                      )}
                    </>
                  ) : null
                }
              </Form.Item>
            </div>
          );
        })}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            매칭 정보 추가
          </Button>
        </Form.Item>
      </Form>
    </Col>
  );
};

export default MatchingForm;

// {
//   tableName: '',
//   schemaName: '',
//   schemaschemaId : '',
//   schemaType: '', // node|relation
//   //---
//   matchingList: [
//   // relation 타입일때
//   {
//    column: '',
//    targetType: 'from', // from|to|fromTo|property
//    target: {
//     schema:'',
//     property:''
//    }
//   },
//   {
//    column: '',
//    targetType: 'fromTo', // from|to|fromTo|property
//    target: {
//     schema:'',
//     property:'',
//     orderBy: {}//order정보 그대로
//    }
//   }
//   ]
//   // node 타입일때
//   matchingList: [
//   {
//    column:'id',
//    targetType:'property',
//    target: {
//      property: 'id'
//    }
//   },
//   ]
//  }
