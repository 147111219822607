/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import {
  Table,
  Row,
  Col,
  Select,
  Typography,
  Empty,
  Button,
  Modal, Layout,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { useListWithIncrementalKey, useSchemaListState } from '../hooks';
// import { useDynamicList } from 'ahooks';
import MatchingForm from '../components/MatchingForm';
import { useRequest } from '../hooks/request';
import {
  useHistory,
  useLocation,
  useParams,
  // useRouteMatch,
} from 'react-router';
import dayjs from 'dayjs';
import {
  cloudFunctionGetRdbTableInfo,
  cloudFunctionRunRdbSql,
} from '../parse-queries';
import Parse from 'parse';
import { Link } from 'react-router-dom';

const { Option } = Select;
const { Text, Title } = Typography;
const { confirm } = Modal;

const ImporterDetail = () => {
  // router
  //@ts-ignore
  const history = useHistory();
  let { pathname } = useLocation();
  const { objectId } = useParams();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedTable, setSelectedTable] = useState();
  const [selectedSchema, setSelectedSchema] = useState();
  const [sampleOrderRule, setSampleOrderRule] = useState();
  const [sampleOrderInfoText, setSampleOrderInfoText] = useState('');

  const {
    list: matchedList,
    removeItems: removeMatchedItems,
    pushItem: pushMatchedItem,
  } = useListWithIncrementalKey([]);

  // const [
  //   { data: { results: schemaList, isPending: isLoadingSchemaList } = {} },
  //   { run: runSchemaListLoad },
  // ] = useRequest(findSchemaListWithCount);
  const [
    { data: schemaList = [], isPending: isLoadingSchemaList },
    { run: runSchemaListLoad },
  ] = useSchemaListState();
  //@ts-ignore
  // const [{ isPending: isLoadingSchemaList }, { run: runSchemaListLoad }] =
  //   useRequest(load);

  const [
    { data: tableList, isPending: isLoadingTableList },
    { run: runGetRdbSql },
  ] = useRequest(cloudFunctionGetRdbTableInfo);

  const [
    {
      data: sampleDataOfSelectedTable = [],
      isPending: isSampleDataOfSelectedTableLoading,
    },
    { run: runRdbSql },
  ] = useRequest(cloudFunctionRunRdbSql);

  useEffect(() => {
    runGetRdbSql(objectId);
  }, [objectId]);

  useEffect(() => {
    if (selectedTable) {
      const orderSQL = sampleOrderRule
        ? `ORDER BY ${sampleOrderRule.key} ${sampleOrderRule.direction}`
        : '';
      const sql = `SELECT * FROM ${selectedTable.tableName} ${orderSQL} LIMIT 100`;
      runRdbSql(objectId, sql);
      setSampleOrderInfoText(orderSQL);
    }
  }, [selectedTable, selectedSchema, sampleOrderRule, objectId]);

  useEffect(() => {
    runSchemaListLoad({});
  }, []);

  const tableNameColumns = [
    {
      title: '테이블명',
      dataIndex: 'tableName',
      key: 'tableName',
    },
  ];

  const sampleDataCols = _.chain(selectedTable && selectedTable.columns)
    .map((col, key) => ({
      title: col.COLUMN_NAME,
      dataIndex: col.COLUMN_NAME,
      render: (v) =>
        col.COLUMN_TYPE === 'timestamp' && v ? dayjs(v).format('L LT') : v,
    }))
    .map((v, idx) => ({ ...v, fixed: idx === 0 }))
    .value();

  const matchingSchemaTableCols = [
    {
      title: '테이블명',
      dataIndex: 'tableName',
      key: 'tableName',
      width: 150,
      fixed: 'left',
    },
    {
      title: '스키마명',
      dataIndex: 'schemaName',
      key: 'schemaName',
      width: 150,
    },
    {
      title: '스키마 타입',
      dataIndex: 'schemaType',
      key: 'schemaType',
      width: 150,
    },
    {
      title: '생성 시간',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (v) => {
        return dayjs(v).format('L LT');
      },
    },
    {
      title: '매칭 속성',
      dataIndex: 'matchingList',
      key: 'matchingList',
      width: 200,
      render: (v) => {
        return _.map(v, (item, idx) => {
          return (
            <span key={idx}>
              {item.column + '->' + JSON.stringify(item.target)}
              <br />
            </span>
          );
        });
      },
    },
  ];
  const onFinish = (values) => {
    // console.log('Success: ', values);
    pushMatchedItem(values);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };
  const emptySelectedRowKeys = () => {
    setSelectedRowKeys([]);
  };
  const deleteSelectedMatchedItems = () => {
    removeMatchedItems(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const showDeleteConfirm = () => {
    confirm({
      title: '선택 항목을 삭제하겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        deleteSelectedMatchedItems();
        emptySelectedRowKeys();
      },
      onCancel() {},
    });
  };
  const onClickMakeGraph = () => {
    // console.log(matchedList);
    const ImportJobInfo = Parse.Object.extend('ImportJobInfo');
    const newImportJobInfo = new ImportJobInfo({
      dbInfoId: objectId,
      matchingInfo: matchedList,
    });
    newImportJobInfo.save().then(() => {
      history.push(`${pathname}/jobs`);
    });
  };

  return (
    <Layout style={{padding: '24px', backgroundColor: 'white'}}>
      <Row justify={'space-between'}>
        <Title level={4}></Title>
        <Link to={`${pathname}/jobs`}>Import Job 목록 보기</Link>
      </Row>
      <Row>
        <Col span={8}>
          <Row
            justify={'space-between'}
            align="bottom"
            style={{ marginBottom: 20 }}
          >
            <Col span={10}>
              <Title level={5}>From 테이블</Title>
              <div>
                <Select
                  loading={isLoadingTableList}
                  style={{ width: '100%' }}
                  showSearch
                  placeholder="테이블을 선택해주세요"
                  onChange={(value) => {
                    setSelectedTable(
                      _.find(tableList, (t) => t.tableName === value)
                    );
                    setSampleOrderRule(null);
                  }}
                  value={selectedTable && selectedTable.tableName}
                  options={_.map(tableList, (t) => ({
                    label: t.tableName,
                    value: t.tableName,
                  }))}
                />
              </div>
            </Col>
            {/* <Table
            size={'small'}
            rowKey={'tableName'}
            // radioButton으로 row를 선택할 수 있는 기능
            rowSelection={{
              selectedRowKeys: [selectedTable && selectedTable.tableName],
              type: 'radio',
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectedTable(selectedRows[0]);
              },
            }}
            dataSource={tableList || []}
            columns={tableNameColumns}
            // radio 버튼을 누르지 않고 row를 눌러도 radioButton이 눌리도록
            onRow={(record) => {
              return {
                onClick: () => {
                  setSelectedTable({ ...record });
                },
              };
            }}
            pagination={false}
          /> */}
            <Col span={10}>
              {selectedTable && (
                <>
                  <Title level={5}>To 스키마</Title>
                  <div>
                    {/* ant.d select의 아이콘 정렬이 틀어지는 것 fix */}
                    <Select
                      loading={isLoadingSchemaList}
                      style={{ width: '100%' }}
                      showSearch
                      placeholder="스키마를 선택해주세요"
                      onChange={(value) => {
                        setSelectedSchema(
                          _.find(
                            schemaList,
                            ({ objectId }) => objectId === value
                          )
                        );
                        setSampleOrderRule(null);
                      }}
                      optionFilterProp={'label'}
                      options={_.chain(schemaList)
                        .groupBy('type')
                        .map((v, k) => ({
                          label: k,
                          options: _.map(v, (i) => ({
                            label: i.name,
                            value: i.objectId,
                          })),
                        }))
                        .value()}
                    ></Select>
                  </div>
                </>
              )}
            </Col>
          </Row>
          {selectedSchema ? (
            <MatchingForm
              table={selectedTable}
              schema={selectedSchema}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              schemaList={schemaList}
              onChangeOrderRule={setSampleOrderRule}
            />
          ) : (
            <Table
              style={{ width: '100%' }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="매칭할 테이블과 스키마를 선택하면 테이블 컬럼과 스키마 속성을 선택할 수 있습니다."
                    style={{ color: '#333' }}
                  />
                ),
              }}
            />
          )}
        </Col>
        <Col span={1}></Col>
        <Col span={15}>
          <Title level={5}>매칭한 스키마 목록</Title>
          <Row justify={'space-between'}>
            <Button
              htmlType="button"
              onClick={() => {
                showDeleteConfirm();
              }}
              disabled={selectedRowKeys.length === 0}
              type="danger"
            >
              Delete
            </Button>
            <Button type="primary" onClick={onClickMakeGraph}>
              Graph 생성하기
            </Button>
          </Row>
          <Table
            rowKey={'_key'}
            rowSelection={{
              ...rowSelection,
            }}
            columns={matchingSchemaTableCols}
            dataSource={matchedList}
            scroll={{ x: 1 }}
          ></Table>
        </Col>
        {/* <Col span={15} offset={1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 10px",
                }}
              >
                <h1>{`${selectedTable.tableName}' Columns`}</h1>
                <Select
                  loading={!schemaList}
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select a schema"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    console.log(value);
                  }}
                >
                  {schemaList.map((data, index) => (
                    <Option value={data.objectId}>{data.name}</Option>
                  ))}
                </Select>
              </div>

              <Table
                rowKey={"COLUMN_NAME"}
                dataSource={selectedTable.columns}
                columns={columnNameCols}
                scroll={{ y: 450 }}
                pagination={false}
              />
            </Col> */}
      </Row>
      <Row>
        <Col span={24} style={{ marginTop: `100px` }}>
          <Title level={4}>
            {selectedTable ? (
              <>
                <Text code>{selectedTable.tableName}</Text>
                <span>
                  테이블의 데이타
                  {sampleOrderRule &&
                    '(ORDER BY ' + JSON.stringify(sampleOrderRule) + ')'}
                </span>
              </>
            ) : (
              '위 테이블 목록에서 테이블을 선택하면 테이블의 Row가 표시됩니다.'
            )}
          </Title>
          <Table
            loading={isSampleDataOfSelectedTableLoading}
            size={'small'}
            rowKey={'id'}
            dataSource={sampleDataOfSelectedTable}
            columns={sampleDataCols}
            scroll={{ x: 1 }} // x 지정 안하면 화면 밖으로 나감
            // A fixed value which is greater than table width for scroll.x is recommended. The sum of unfixed columns should not greater than scroll.x.
            //https://ant.design/components/table/#components-table-demo-fixed-columns-header
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default ImporterDetail;
