import Graphin, { Behaviors } from '@antv/graphin';
import { Row, Col } from 'antd';
const { DragCanvas, ZoomCanvas, DragNode, ActivateRelations, ClickSelect } =
  Behaviors;

const NodePreview = ({ nodeTitle }) => {
  const data = {
    nodes: [
      {
        id: 'node-0',
        x: 155,
        y: 250,
        type: 'circle',
        size: 68,
        style: {
          fill: '#BCA480',
          stroke: '#BCA480',
          fillOpacity: 0.5,
        },
        //default size: 26
        label: nodeTitle ? nodeTitle : '',
      },
    ],
    edges: [],
  };
  return (
    <div>
      <Row>
        <Col span={24}>
          <Graphin data={data} layout={{ type: 'preset' }}>
            <DragCanvas disabled={true} />
            <ZoomCanvas enableOptimize disabled={true} />
            <DragNode disabled={true} />
            <ActivateRelations trigger=" click " disabled={true} />
            <ClickSelect disabled={true} />
          </Graphin>
        </Col>
      </Row>
    </div>
  );
};

export default NodePreview;
