import { useEffect, useState } from 'react';
import { useCompany } from '../hooks/authManager';
import { Table, Typography, Button } from 'antd';
import React from 'react';
import AuthCompanyModal from './AuthComponyModal';
import { ModalMode } from '../hooks/authType';
import { useRecoilState } from 'recoil';
import { selectedCompanyState } from '../hooks/authAtom';

import './AuthGeneral.css';

const { Title } = Typography;

const AuthCompany: React.FC = () => {
  const [company, selectCompany] = useRecoilState(selectedCompanyState);
  const { companies, addCompany, updateCompany, hasCompany } = useCompany();
  const [modalMode, setModalMode] = useState('close' as ModalMode);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const openAddCompany = () => setModalMode('add');
  const openEditCompany = () => company?.id && setModalMode('edit');
  const closeEditCompany = () => setModalMode('close');

  useEffect(() => {
    if (selectedRowKeys.length === 0) return;
    const selected_id = selectedRowKeys[0];
    const found_company = companies.find(
      (company) => company.id === selected_id
    );
    if (found_company) {
      selectCompany(found_company);
    }
  }, [companies, selectedRowKeys, selectCompany]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '이름',
      dataIndex: 'name',
    },
  ];

  return (
    <>
      <div className="part_header" id="company_header">
        <Title> 회사 </Title>
      </div>
      <Table
        sortDirections={['ascend', 'descend', 'ascend']}
        rowKey={'id'}
        columns={columns}
        dataSource={companies}
        rowSelection={{
          selectedRowKeys,
          type: 'radio',
          onChange: (selectedKeys: React.Key[], selectedRows: any) => {
            // console.log(
            //   `selected row keys: [${selectedRowKeys}]]`,
            //   selectedRows
            // );
            setSelectedRowKeys(selectedKeys);
          },
        }}
        pagination={{
          hideOnSinglePage: true,
        }}
      ></Table>
      <div className="right_buttons" id="company_buttons">
        <Button shape="round" onClick={openAddCompany}>
          추가
        </Button>
        <Button shape="round" onClick={openEditCompany} disabled={!company?.id}>
          변경
        </Button>
      </div>
      {modalMode !== 'close' ? (
        <AuthCompanyModal
          key={'auth_company_modal'}
          mode={modalMode}
          close={closeEditCompany}
          addCompany={addCompany}
          updateCompany={updateCompany}
          hasCompany={hasCompany}
          company={company}
        ></AuthCompanyModal>
      ) : undefined}
    </>
  );
};

export default AuthCompany;
