import { Node } from '@antv/graphin/lib/utils/Tree';
import _ from 'lodash';
import { useCallback, useRef, useState, useMemo, useEffect } from 'react';
import { useSelectedNodeNameState } from '../hooks/graphMap';
import {
  KMSNode,
  KMSRelation,
  KMSGraph,
  getNeighborWithNId,
  getMatrixWithNIds,
} from '../kms-request';

export type ExtraTransformFunction = (
  node: any,
  extraData?: any
) => Partial<Node>;

interface KKGraphDataOptions {
  extraTransformMap: { [k: string]: ExtraTransformFunction };
}
export function useKKGraphData(
  initialOptions: KKGraphDataOptions = {
    extraTransformMap: {},
  }
) {
  const [graphData, setGraphData] = useState({
    nodes: [] as any[],
    edges: [] as any[],
  });

  const [selectedNodeName] = useSelectedNodeNameState();

  const [propertyNames, setPropertyNames] = useState([]);

  const [extraDataMap, setExtraDataMap] = useState<any>({});
  const [extraTransformMap, setExtraTransformMap] = useState<{
    [k: string]: ExtraTransformFunction;
  }>(initialOptions.extraTransformMap);

  const expandedNodesMapRef = useRef<any>({});
  const expandedEdgesMapRef = useRef<any>({});

  useEffect(() => {
    if (selectedNodeName.type === 'node') {
      const pnameSet = new Set();
      graphData.nodes.forEach((n) => {
        if (
          n.kkSchemaName === selectedNodeName.name &&
          n.properties !== undefined
        ) {
          for (const pname in n.properties) {
            pnameSet.add(pname);
          }
          setPropertyNames([...pnameSet] as never[]);
        }
      });
    }
  }, [selectedNodeName, graphData]);

  const getPropertyNames = () => propertyNames;

  const addExpandedGraphData = (key: string, data: any) => {
    // reset이 아니면 현 graphData를 그대로 이용
    const existingNodes = key === 'reset' ? [] : graphData.nodes;
    const existingEdges = key === 'reset' ? [] : graphData.edges;

    // TODO: 추가하려는 node 중에 현재 존재하는 node가 있으면 추가 목록에서 제외
    // (성능 위해 나중에 map을 구성해야할수도)
    const newNodes = _.filter(
      data.nodes,
      (nn: any) => !_.find(existingNodes, (en) => en.id === nn.id)
    );

    // 추가하려는 노드들의 내부 관계 조회
    // getInternalRelationships(existingNodes, newNodes)
    getMatrixRelations(existingNodes, newNodes).then(({ edges = [] }) => {
      const newEdges = edges;
      const newData = {
        nodes: _.uniqBy(newNodes.concat(existingNodes), 'id'),
        edges: _.uniqBy(newEdges.concat(existingEdges), 'id'),
      };
      // console.log('newData', newData);
      setGraphData(newData);

      expandedNodesMapRef.current[key] = newNodes;
      expandedEdgesMapRef.current[key] = newEdges;
    });
  };

  const resetGraphDataWithKMSQuery = useCallback((func) => {
    return func.then((graph: KMSGraph) => {
      const nodesAndEdges = getNodesAndRelationsFromGraph(graph);
      addExpandedGraphData('reset', {
        nodes: nodesAndEdges.nodes,
        edges: [], // reset일 때는 edges는 internal edge를 찾는 과정이 필요하기 때문 비워서 모든 내부 관계 조회
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const getCurrentNeighbor = () => {};
  const findNodeNeighbourIds = (id: string) => {
    return graphData.edges
      .filter(
        (relationship: any) =>
          relationship.source === id || relationship.target === id
      )
      .map((relationship: any) => {
        if (relationship.target === id) {
          return relationship.source;
        }
        return relationship.target;
      });
  };

  const removeNeighbour = (node: any) => {
    const result = findCollapsingData(node, { nodes: [], edges: [] });
    setGraphData((gd) => {
      const newEdges = gd.edges.filter((e) => result.edges.indexOf(e) === -1);
      const newNodes = gd.nodes.filter((n) => result.nodes.indexOf(n) === -1);
      return {
        nodes: newNodes,
        edges: newEdges,
      };
    });
  };

  const findCollapsingData = (node: any, result: any) => {
    if (!expandedNodesMapRef.current[node.id]) {
      return;
    }
    expandedNodesMapRef.current[node.id].forEach((oneNode: any) => {
      findCollapsingData(oneNode, result);
      result.nodes = result.nodes.concat([oneNode]);
      result.edges = result.edges.concat(
        findAllEdgesToNode(graphData.edges, oneNode)
      );
    });
    expandedNodesMapRef.current[node.id] = null;
    return result;
  };

  const toggleNeighbour = (node: any) => {
    if (expandedNodesMapRef.current[node.id]) {
      removeNeighbour(node);
    } else {
      loadNeighbour(node);
    }
  };

  function addGraphData(id: string, graph: KMSGraph) {
    const nodesAndEdges = getNodesAndRelationsFromGraph(graph);
    // console.log('loadNeighbour', nodesAndEdges);
    addExpandedGraphData(id, nodesAndEdges);
  }

  function getAddGraphData(id: string) {
    return function (graph: KMSGraph) {
      addGraphData(id, graph);
    };
  }

  function loadNeighbour(node: any) {
    const currentNeighbourIds = findNodeNeighbourIds(node.id);
    // console.log('currentNeighbourIds', currentNeighbourIds);
    getNeighborWithNId(`${node.id}`, `${currentNeighbourIds.join(',')}`).then(
      getAddGraphData(node.id)
    );
  }

  // node = { nid: number, uid: string, labels: string[], properties: {} }
  function transformNode(node: KMSNode) {
    return {
      id: node.nid.toString(),
      kkSchemaName: node.labels[0],
      properties: node?.properties,
    };
  }

  // relation = { start: <node>, end: <node> }, rel: { type: string, properties: {} }}
  function transformRelation(relation: KMSRelation) {
    return {
      // type: item.type,
      // id: 'e_' + relation.nid.toString(),
      id: [relation.start.nid.toString(), relation.end.nid.toString()].join(
        '__'
      ),
      source: relation.start.nid.toString(),
      target: relation.end.nid.toString(),
      kkSchemaName: relation.rel.type,
      label: relation.rel.type,
      properties: relation.rel.properties,
      stateStyles: {
        selected: {
          // 클릭할 때 표시되는 요소들
          stroke: 'steelblue',
          lineWidth: 2,
          shadowColor: 'steelblue',
          shadowBlur: 10,
          'text-shape': {
            fontSize: 12,
            fill: 'steelblue',
          },
        },
      },
      // properties: itemIntToString(item.properties, converters)
    };
  }

  function getNodesAndRelationsFromGraph({ nodes, relations }: KMSGraph) {
    return {
      nodes: nodes.map(transformNode),
      edges: relations.map(transformRelation),
    };
  }

  function getMatrixRelations(existingNodes: any, newNodes: any) {
    let existingNodeIds = existingNodes.map((node: any) => node.id);
    const newNodeIds = newNodes.map((node: any) => node.id);
    existingNodeIds = existingNodeIds.concat(newNodeIds); // existing에서 newNodeIds를 넣어서 newNodeIds 간의 관계도 찾을 수 있다.
    // todo: 이런 멍청한 로직을 봤나... matrix relation체크를 할 것이 아니라 신규노드의 relation들만 다 가져오면 된다 - -;;;
    return getMatrixWithNIds(existingNodeIds, newNodeIds).then(
      getNodesAndRelationsFromGraph
    );
  }

  const graphDataWithExtraData = useMemo(() => {
    const nodes = _.map(graphData.nodes, (n) =>
      extraTransformMap[n.kkSchemaName]
        ? _.merge(
            {},
            n,
            extraTransformMap[n.kkSchemaName](n, extraDataMap[n.id])
          )
        : n
    );
    const edges = _.map(graphData.edges, (n) =>
      extraTransformMap[n.kkSchemaName]
        ? _.merge(
            {},
            n,
            extraTransformMap[n.kkSchemaName](n, extraDataMap[n.id])
          )
        : n
    );

    return {
      nodes,
      edges,
    };
  }, [graphData, extraDataMap, extraTransformMap]);

  const deleteItemById = (id: string) => {
    setGraphData((gData) => {
      return {
        nodes: _.filter(gData.nodes, (n) => n.id !== id),
        edges: _.filter(gData.edges, (e) => e.id !== id),
      };
    });
  };
  const addNode = (node: any) => {
    setGraphData((gData) => {
      return {
        nodes: _.uniqBy([...gData.nodes, node], 'id'),
        edges: gData.edges,
      };
    });
  };
  const addEdge = (edge: any) => {
    setGraphData((gData) => {
      return {
        nodes: gData.nodes,
        edges: _.uniqBy([...gData.edges, edge], 'id'),
      };
    });
  };

  return [
    {
      graphData,
      graphDataWithExtraData,
      extraDataMap,
    },
    {
      resetGraphDataWithKMSQuery,
      toggleNeighbour,
      setExtraDataMap,
      setExtraTransformMap,
      deleteItemById,
      addNode,
      addEdge,
      getAddGraphData,
      getNodesAndRelationsFromGraph,
      findNodeNeighbourIds,
      getPropertyNames,
    },
  ];
}

function findAllEdgesToNode(edges: any[], node: any) {
  return edges.filter(
    (relationship: any) =>
      relationship.source === node.id || relationship.target === node.id
  );
}
