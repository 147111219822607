import { useState, useContext, createContext, useEffect } from 'react';
import Parse from 'parse';
import { useHistory, useLocation } from 'react-router-dom';
import { setLoggerUser, clearSession } from '../helper/accessLogger';

type Auth = {
  user: Parse.User | undefined;
  roleNames: string[];
  isKKMaster: boolean;
  isDomainMaster: boolean;
  isCurriculumMaster: boolean;
  isContentMaster: boolean;
  login?: (id: string, pw: string) => Promise<Parse.User<Parse.Attributes>>;
  logOut?: () => Promise<Parse.User<Parse.Attributes>>;
};

export const authContext = createContext({
  user: undefined,
  roleNames: [],
  isKKMaster: false,
  isDomainMaster: false,
  isCurriculumMaster: false,
  isContentMaster: false,
} as Auth);

export const useAuth = () => {
  return useContext(authContext);
};

export const useProvideAuth = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [user, setUser] = useState(Parse.User.current());
  const [roleNames, setRoleNames] = useState<string[]>([]);
  const [isKKMaster, setKKMaster] = useState<boolean>(false);
  const [isDomainMaster, setDomainMaster ] = useState<boolean>(false)
  const [isCurriculumMaster, setCurriculumMaster] = useState<boolean>(false);
  const [isContentMaster, setContentMaster] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setLoggerUser(user.id, user.get('username'));

      const myRoleName = user.get("roleName");
      setKKMaster(myRoleName === "kk-master");
      setDomainMaster(myRoleName === "domain-master");
      setCurriculumMaster(myRoleName === "curr-master");
      setContentMaster(myRoleName === "con-master");

      Parse.Cloud.run('getMyRoleNames')
        .then((roleNames) => {
          setRoleNames(roleNames);
        })
        .catch((e) => {
          // console.log('##error', JSON.stringify(e));
          if (e.code === 209) {
            logOut().finally(() => {
              history.replace('/login', {
                from: pathname,
              });
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const logIn: Auth['login'] = async (id, pw) => {
    return Parse.User.logIn(id, pw).then((user) => {
      setUser(user);
      return user;
    });
  };

  const logOut = async () => {
    return Parse.User.logOut().finally(() => {
      setUser(undefined);
      clearSession();
      setRoleNames([]);
    });
  };

  return {
    user,
    roleNames,
    isKKMaster,
    isDomainMaster,
    isCurriculumMaster,
    isContentMaster,
    logIn,
    logOut,
  } as Auth;
};
