import AuthCompany from '../components/AuthCompany';
import AuthDomain from '../components/AuthDomain';
import AuthAcl from '../components/AuthAcl';
import AuthAccessToken from '../components/AuthAccessToken';
import styles from './AuthManagerHome.module.css';

const AuthManagerHome = () => {
  return (
    <div className={styles.auth_manager_home}>
      <AuthCompany />
      <AuthDomain />
      <AuthAcl />
      <AuthAccessToken />
    </div>
  );
};

export default AuthManagerHome;
