import _ from 'lodash';

const removeUntilFirstUnderBar = (data: string) => {
  const offset = data.indexOf('_') + 1;
  return data.substr(offset);
};

const removeExtraSpace = (data: string) => {
  return data.replace(/\s+/g, '');
};

const extractKorean = (data: string) => {
  const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
  return _.filter(data, (char: string) => !korean.test(char)).reduce(
    (prev: string, cur: string) => prev + cur,
    ''
  );
};

const stringToHex = function (str: string, amt = 40) {
  let hash = 0;
  if (str.length === 0) {
    return '#aaa';
  }
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    // eslint-disable-next-line no-bitwise
    hash = hash & hash;
  }
  let color = '';
  for (let i = 0; i < 3; i++) {
    // eslint-disable-next-line no-bitwise
    var value = (hash >> (i * 8)) & 255;
    color += ('00' + value.toString(16)).substr(-2);
  }

  return '#' + color;
};

function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : [255, 255, 255];
}

function getBlackOrWhiteForContrast(colorHex: string) {
  const rgb = hexToRgb(colorHex);
  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round(
    (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000
  );
  const textColour = brightness > 130 ? '#000000' : '#ffffff';
  return textColour;
}

const replaceToBoldWithCaseInsensitive = (src: string, target: string) => {
  if (src !== null && src.length > 0 && target !== null && target.length > 0) {
    const lowerSrc = src.toLocaleLowerCase();
    const lowerTarget = target.toLocaleLowerCase();
    const l = target.length;
    const newString: string[] = [];
    let pos = lowerSrc.indexOf(lowerTarget);
    let proceed = 0;
    while (pos !== -1) {
      newString.push(src.substring(proceed, pos));
      const stringToReplace = src.substring(pos, pos + l);
      newString.push(`<b>${stringToReplace}</b>`);
      proceed = pos + l;
      pos = lowerSrc.indexOf(lowerTarget, pos + l + 1);
      if (pos === -1) {
        newString.push(src.substring(proceed));
      }
    }

    if (newString.length > 0) {
      return newString.join('');
    }
  }
  return src;
};

const urlRewriteToShortenFilename = (url: string): string => {
  const posLastSlash = url.lastIndexOf('/');
  if (posLastSlash !== -1) {
    const uPos = url.indexOf('_', posLastSlash);
    if (uPos !== -1) {
      return [url.slice(0, uPos), '/', url.slice(uPos + 1)].join('');
    }
  }
  return url;
};

const download = (content: any, fileName: string, contentType: string) => {
  const a = document.createElement('a');
  const file = new Blob([content], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
};

const passwordPolicy = (value: string, required: false) => {
  if (!required && (value === undefined || value.length === 0)) {
    return Promise.resolve();
  }

  const continuousTest = (src: string, limit: number) => {
    let o = 0;
    let d = 0;
    let p = 0;
    let n = 0;
    let l = limit == null ? 4 : limit;
    for (let i = 0; i < src.length; i++) {
      const c = src.charCodeAt(i);
      if (i > 0 && (p = o - c) > -2 && p < 2 && (n = p === d ? n + 1 : 0) > l - 3) return false;
      d = p;
      o = c;
    }
    return true;
  }

  const combineRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  if (combineRegex.test(value)) {
    const repeatRegex = /(\w)\1\1\1/;
    if (repeatRegex.test(value)) {
      return Promise.reject("동일한 문자 4개는 연속으로 사용할 수 없습니다.");
    } else {
      if (continuousTest(value, 4)) {
        return Promise.resolve()
      } else {
        return Promise.reject("연속된 4자리 문자는 사용할 수 없습니다.")
      }
    }
  } else {
    return Promise.reject("비밀번호는 영문,숫자,특수문자 중 3가지 조합 8자리 이상입니다.")
  }
}


const commaWithNumber = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export {
  removeUntilFirstUnderBar,
  removeExtraSpace,
  stringToHex,
  getBlackOrWhiteForContrast,
  extractKorean,
  replaceToBoldWithCaseInsensitive,
  urlRewriteToShortenFilename,
  download,
  passwordPolicy,
  commaWithNumber
};
