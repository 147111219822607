import { Form, Select, Input } from 'antd';
import RelationPreview from './RelationPreview';

const RelationTypeMatchingFields = ({ form, schemaList }) => {
  return (
    <>
      <Form.Item
        name="fromNodeName"
        colon={false}
        rules={[{ required: true, message: 'Please select node name!' }]}
        label="From"
        labelAlign="left"
      >
        <Select
          placeholder="노드 선택"
          allowClear
          showSearch
          onChange={(value) => {
            if (value === undefined) return;
            form.setFieldsValue({
              fromNodeUniqKey: schemaList.find(
                (schemaInfo) => schemaInfo.name === value
              ).uniqPropName,
            });
          }}
          options={schemaList
            .filter((schemaInfo) => schemaInfo.type === 'node')
            .map((schemaInfo) => ({
              label: schemaInfo.name,
              value: schemaInfo.name,
            }))}
          data-test="from-schema"
        />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          return (
            getFieldValue('fromNodeName') && (
              <Form.Item
                name="fromNodeUniqKey"
                label="스키마 속성"
                labelAlign="left"
                colon={false}
              >
                <Input disabled={true} data-test="from-schema-uniq-prop" />
              </Form.Item>
            )
          );
        }}
      </Form.Item>
      <Form.Item
        name="toNodeName"
        colon={false}
        rules={[{ required: true, message: 'Please select node name!' }]}
        label="To"
        labelAlign="left"
      >
        <Select
          placeholder="노드 선택"
          allowClear
          showSearch
          onChange={(value) => {
            if (value === undefined) return;
            form.setFieldsValue({
              toNodeUniqKey: schemaList.find(
                (schemaInfo) => schemaInfo.name === value
              ).uniqPropName,
            });
          }}
          options={schemaList
            .filter((schemaInfo) => schemaInfo.type === 'node')
            .map((schemaInfo) => ({
              label: schemaInfo.name,
              value: schemaInfo.name,
            }))}
          data-test="to-schema"
        />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          return (
            getFieldValue('toNodeName') && (
              <Form.Item
                name="toNodeUniqKey"
                label="스키마 속성"
                labelAlign="left"
                colon={false}
              >
                <Input disabled={true} data-test="to-schema-uniq-prop" />
              </Form.Item>
            )
          );
        }}
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          return (
            <Form.Item name="preview" label="미리보기">
              <RelationPreview
                nodeSchemaFrom={getFieldValue('fromNodeName')}
                nodeSchemaTo={getFieldValue('toNodeName')}
                relationName={getFieldValue('schemaName')}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    </>
  );
};

export default RelationTypeMatchingFields;
