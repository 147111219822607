import { Form, Select, Row, Col, Input } from 'antd';
import _ from 'lodash';

const { Option } = Select;

const NodeTypeMatchingFields = ({ disabled }) => {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, setFieldsValue }) => {
        if (
          getFieldValue('labelPropName') &&
          !_.includes(
            _.map(getFieldValue('props'), 'name'),
            getFieldValue('labelPropName')
          )
        ) {
          setFieldsValue({ labelPropName: undefined });
        }

        !getFieldValue('uniqPropName') &&
          setFieldsValue({ uniqPropName: '_uid' });

        return (
          <Row>
            <Col span={24}>
              <Form.Item
                label="레이블"
                name="labelPropName"
                rules={[
                  {
                    required: true,
                    message: '레이블로 지정할 속성이름을 선택하세요',
                  },
                ]}
              >
                <Select
                  data-test="select-label-in-graph-schema-panel"
                  placeholder="레이블 지정"
                  disabled={disabled}
                  allowClear
                >
                  {getFieldValue('props') !== undefined
                    ? _.map(
                        getFieldValue('props'),
                        (v, idx) =>
                          v &&
                          v.name !== undefined && (
                            <Option
                              value={v.name}
                              key={idx}
                              data-test="option-label-in-graph-schema-panel"
                            >
                              {v.name}
                            </Option>
                          )
                      )
                    : null}
                </Select>
              </Form.Item>
              <Form.Item
                label="유니크"
                name="uniqPropName"
                rules={[
                  {
                    required: true,
                    message: '유니크로 지정할 속성이름을 선택하세요',
                  },
                ]}
              >
                <Input
                  data-test="input-schema-name-in-graph-schema-panel"
                  placeholder="유니크 id 이름"
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
};

export default NodeTypeMatchingFields;
