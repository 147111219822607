import { atom } from 'recoil';

export const ImporterItemSaveRequestState = atom({
  key: 'ImporterItemSaveState',
  default: '',
});

export const KKSchemaSaveRequestState = atom({
  key: 'KKSchemaSaveState',
  default: 0,
});

export const SelectedNodeName = atom({
  key: 'SelectedNodeName',
  default: {},
});

export const SelectedPropertyName = atom({
  key: 'SelectedPropertyName',
  default: {},
});
