import {Alert, Button, Col, Divider, Form, Input, message, Modal, Row, Select, Space, Spin, Typography} from "antd";
import {useAuth} from "../hooks/auth";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";

interface KMServiceApi {
  createKMService?: (kmServiceForm: any) => Promise<void>,
  updateKMService?: (objectId: String, kmServiceForm: any) => Promise<void>,
  deleteKMService?: (objectId: String) => Promise<void>,
}

interface KMManagerPanelProps {
  objectId: string;
  initialKMService: any;
  isPending: boolean;
  api: KMServiceApi;
  onCreate: () => void;
  onUpdate: () => void;
  onDelete: () => void;
  onCancel: () => void;
  statuses: any[],
  companies: any[],
}

const KMManagerPanel = ({
                          objectId,
                          initialKMService,
                          isPending,
                          api,
                          onCreate,
                          onUpdate,
                          onDelete,
                          onCancel,
                          statuses,
                          companies,
                        }: KMManagerPanelProps) => {


  const {isContentMaster, isKKMaster} = useAuth();

  // mode
  const mode = isContentMaster ? "readOnly" : (objectId === "new") ? "new" : "edit";
  const isEditMode = mode === 'edit';
  const isNewMode = mode === 'new';
  const isReadOnly = mode === "readOnly"

  // form - company
  const [companyOptions, setCompanyOptions] = useState<any[]>([]);
  useEffect(() => {
    if (companies !== undefined) {
      setCompanyOptions(companies.map((e) => ({
        label: e.name,
        value: e.id,
      })));
    }
  }, [companies]);

  // form
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialKMService);
  }, [form, initialKMService]);

  // event
  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const safeConfirm = (title: string, content: string, confirmFunc: () => void) => {
    Modal.confirm({
      title,
      content,
      icon: <ExclamationCircleOutlined/>,
      okText: '네',
      okType: 'danger',
      cancelText: '아니오',
      onOk() {
        confirmFunc();
      },
      onCancel() {
      },
    });
  };

  const showCancelConfirm = () => {
    Modal.confirm({
      title: '작성중인 내용을 취소하겠습니까?',
      icon: <ExclamationCircleOutlined/>,
      okText: '네',
      okType: 'danger',
      cancelText: '아니오',
      onOk() {
        onCancel();
      },
      onCancel() {
      },
    });
  };

  return (
    <>
      {isEditMode && isPending ? (
        <Spin
          tip="Loading..."
          style={{position: 'fixed', top: '50%', left: '50%'}}
        ></Spin>
      ) : (
        <Col>
          {isReadOnly && (
            <Alert
              message="수정 권한이 없습니다."
              type="info"
              showIcon
              style={{marginBottom: '15px'}}
            />
          )}

          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => {
              safeConfirm(
                objectId === undefined ? "지식맵을 추가합니다." : "지식맵 정보를 수정합니다.",
                "수정하신 내용을 저장하시겠습니까?",
                () => {
                  if (isEditMode) {
                    api.updateKMService && api.updateKMService(objectId, values).then(() => {
                      onUpdate();
                    });
                  } else {
                    api.createKMService && api.createKMService(values).then(() => {
                      onCreate();
                    })
                    .catch((err) => {
                      message.error(err.message);
                    })
                  }
                },
              );
            }}
            onFinishFailed={onFinishFailed}
          >

            {isKKMaster &&
              (<Form.Item
                data-test="error-message-of-company-in-bill"
                name="company"
                label="회사"
                required-mark="true"
                rules={[{required: true, message: '회사를 선택해주세요.'}]}
              >
                <Select options={companyOptions} disabled={!isNewMode}/>
              </Form.Item>)
            }

            <Form.Item
              data-test="error-message-of-name-in-add-use"
              name="name"
              label="이름"
              required-mark="true"
              rules={[
                {required: true, message: '이름을 입력해주세요'},
                {min: 2, message: "이름은 최소 2글자 이상입니다."}
              ]}>
              <Input data-test="test_name"/>
            </Form.Item>

            <Form.Item
              data-test="error-message-of-domain-in-sign"
              name="domain"
              label="지식맵 아이디 (영대문자 2~4자)"
              required-mark={isNewMode ? "true" : "false"}
              rules={
                isNewMode ?
                  [
                    {required: true, message: '지식맵 아이디는 필수입니다.'},
                    {pattern: RegExp(/^([A-Z]).{1,3}$/), message: "지식맵 아이디는 영어대문자로 2~4자입니다."}
                  ]
                  : []
              }
            >
              <Input data-test="sign_domain" disabled={!isNewMode}/>
            </Form.Item>

            {!isNewMode && isKKMaster ? (
                <Form.Item
                  data-test="error-message-of-status-in-kmservice"
                  name="status"
                  label="상태"
                  required-mark="true"
                  rules={[{required: true, message: '권한을 선택해주세요.'}]}
                >
                  <Select options={statuses}/>
                </Form.Item>)
              : (<></>)}

            <Divider/>
            <Row justify="space-between" align="middle">
              {isEditMode && objectId !== undefined ? (
                <Typography.Text
                  data-test="btn-delete-in-knowledge-panel"
                  type="danger"
                  underline
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    safeConfirm(
                      "지식맵 반납",
                      "지식맵을 반납하시겠습니까?",
                      () => {
                        api.deleteKMService && api.deleteKMService(objectId).then(() => {
                          onDelete();
                        });
                      });
                  }}
                >
                  반납
                </Typography.Text>
              ) : (
                <div/>
              )}

              <Space>
                <Button
                  data-test="btn-cancel-in-knowledge"
                  htmlType="button"
                  onClick={() => {
                    if (isEditMode || isNewMode) {
                      showCancelConfirm();
                    } else {
                      onCancel();
                    }
                  }}
                >
                  취소
                </Button>
                <Button
                  data-test="btn-save-in-knowledge"
                  loading={isPending}
                  type="primary"
                  htmlType="submit"
                  // disabled={isReadOnly}
                >
                  저장
                </Button>
              </Space>
            </Row>
          </Form>
        </Col>
      )}
    </>
  );

}

export default KMManagerPanel;