/* eslint-disable no-template-curly-in-string */
import { FC, useCallback } from 'react';
import { Form, Button, Modal, Input } from 'antd';
import { ModalMode, CompanyData } from '../hooks/authType';

interface AuthCompanyModalProps {
  mode: ModalMode;
  close: () => void;
  addCompany: (data: CompanyData) => Promise<boolean>;
  updateCompany: (data: CompanyData) => Promise<boolean>;
  hasCompany: (company_id: string) => Promise<boolean>;
  company: CompanyData;
}

export const AuthCompanyModal: FC<AuthCompanyModalProps> = ({
  mode,
  close,
  addCompany,
  updateCompany,
  hasCompany,
  company,
}) => {
  const [form] = Form.useForm();
  // const { Text } = Typography;

  const edit_mode = mode === 'edit';

  const validateCompanyId = useCallback(async (_, value) => {
    if (/\s/.test(value)) {
      return Promise.reject(new Error('회사 ID는 공백을 포함할 수 없습니다.'));
    }

    return Promise.resolve();
  }, []);

  const handleCancel = () => {
    form.resetFields();
    close();
  };

  const handleOk = () => {
    form.submit();
  };

  const onFinish = (values: any) => {
    // console.log('company values (onFinish): ', values);
    const { id, name, description } = values;
    const new_company = { id, name, description };
    if (edit_mode) {
      updateCompany(new_company).then((updated) => {
        if (updated) {
          close();
        } else {
          alert('failed to update company');
        }
      });
      return;
    }
    hasCompany(id)
      .then((exist_id) => {
        if (exist_id) {
          form.setFields([
            {
              name: 'id',
              errors: ['사용중인 ID입니다.'],
            },
          ]);
          return;
        }

        addCompany(new_company).then((saved) => {
          if (saved) {
            close();
          } else {
            alert('failed to save company.');
          }
        });
      })
      .catch((err) => {
        console.error('failed to save company', err);
        alert('failed to save company.');
      });
  };

  // const onChange = (value: any) => {
  //   console.log('company values (onChange): ', value);
  // };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      string: '${label} is not a valid string!',
    },
    string: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const initialValues = { ...(edit_mode && company) };

  return (
    <>
      <Modal
        key={'company_modal'}
        visible={true}
        title={'회사 ' + (mode === 'add' ? '추가' : '편집')}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={[
          <div style={{ textAlign: 'center' }}>
            <Button key="cancel" onClick={handleCancel}>
              취소
            </Button>
            <Button key="save" type="primary" onClick={handleOk}>
              저장
            </Button>
          </div>,
        ]}
      >
        <Form
          form={form}
          onFinish={onFinish}
          // onChange={onChange}
          labelCol={{ span: 4 }}
          layout="horizontal"
          // labelAlign="left"
          key={'company_edit_box'}
          initialValues={initialValues}
          validateMessages={validateMessages}
        >
          <Form.Item
            key={'company_id'}
            name="id"
            label="ID"
            wrapperCol={{ span: 6 }}
            rules={[
              { required: true },
              { type: 'string', min: 2, max: 20 },
              { validator: validateCompanyId },
            ]}
          >
            <Input disabled={edit_mode} placeholder="enter ID"></Input>
          </Form.Item>
          <Form.Item
            key={'company_name'}
            name="name"
            label="이름"
            wrapperCol={{ span: 6 }}
            rules={[{ required: true }, { type: 'string', min: 2, max: 20 }]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            key={'company_description'}
            name="description"
            label="설명"
            rules={[{ type: 'string', min: 0, max: 500 }]}
          >
            <Input.TextArea showCount autoSize={true} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AuthCompanyModal;
