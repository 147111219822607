import Parse from 'parse';

const storageKey = 'kkUserLogSession';
const userLogClass = 'LogUserAccess';
const updateInterval = 60 * 1000;
let parseObject: any = undefined;
let intervalId: number;

interface SessionObject {
  objectId?: string;
  userId?: string;
  userName?: string;
  createdTime: number;
  lastAccessTime: number;
}

const getUserAccessObject = async (newObject: boolean, logID?: string) => {
  if (parseObject === undefined) {
    if (!newObject && logID !== undefined) {
      parseObject = new Parse.Query(userLogClass).get(logID);
    }

    if (parseObject === undefined) {
      parseObject = new Parse.Object(userLogClass);
    }
  }

  return parseObject;
};

const saveUserAccess = async (
  sessionObject: SessionObject,
  newObject: boolean
) => {
  const userAccess = await getUserAccessObject(
    newObject,
    sessionObject.objectId
  );
  await userAccess.save({
    lifeTime: sessionObject.lastAccessTime - sessionObject.createdTime,
    userId: sessionObject.userId,
    userName: sessionObject.userName,
  });
  sessionObject.objectId = userAccess.id;
};

const updateSession = async (userId?: string, userName?: string) => {
  const now = Date.now();
  const sessionObjectStr = sessionStorage.getItem(storageKey);
  const sessionObject: SessionObject =
    sessionObjectStr === undefined || sessionObjectStr === null
      ? {
          createdTime: now,
          lastAccessTime: now,
        }
      : JSON.parse(sessionObjectStr as string);

  if (userId !== undefined && userName !== undefined) {
    if (sessionObject.userId === userId && sessionObject.userName === userName)
      return;
    sessionObject.userId = userId;
    sessionObject.userName = userName;
  }

  sessionObject.lastAccessTime = now;

  await saveUserAccess(sessionObject, sessionObjectStr === undefined);
  sessionStorage.setItem(storageKey, JSON.stringify(sessionObject));
};

const startIntervalUpdate = () => {
  if (intervalId !== undefined) {
    clearInterval(intervalId);
  }
  intervalId = setInterval(updateSession, updateInterval);
};

const setLoggerUser = async (userId: string, userName: string) => {
  await updateSession(userId, userName);
  startIntervalUpdate();
};

const clearSession = async () => {
  await updateSession();
  sessionStorage.removeItem(storageKey);
  clearInterval(intervalId);
};

const initLogger = async () => {
  await updateSession();
  startIntervalUpdate();
};

window.addEventListener('beforeunload', async (e) => {
  await updateSession();
});

export { initLogger, setLoggerUser, clearSession };
