import {Button, Drawer, Layout, message, Row, Space, Table, Typography} from "antd";
import {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useCompanyState, useKMServiceState} from "../hooks";
import dayjs from "dayjs";
import KMManagerPanel from "../components/KMManagerPanel";
import {useAuth} from "../hooks/auth";

const {Text} = Typography;

const statuses = [
  {
    label: '신청',
    value: 1,
  },
  {
    label: '생성중',
    value: 2,
  },
  {
    label: '실행',
    value: 3,
  },
  {
    label: '중지',
    value: 4,
  },
  {
    label: '삭제중',
    value: 5,
  }
]

const KMManager = () => {
  const history = useHistory();
  const {objectId} = useParams() as {
    objectId: string
  };

  const {isKKMaster, isContentMaster} = useAuth();

  const [companies, setCompanies] = useState();

  // api
  const [{}, {listAll: listCompanyAll}] = useCompanyState(); // eslint-disable-line no-empty-pattern

  const [
    {
      listData = [],
      listTotal,
      getData,
      isListing,
      isCreating,
      isGetting,
      isUpdating,
      isDeleting,
      isStarting,
      isStopping,
    },
    {
      listRun,
      createRun,
      getRun,
      updateRun,
      deleteRun,
      startRun,
      stopRun,
    },
  ] = useKMServiceState();

  // refresh
  const refreshPage = useCallback(() => {
    listRun && listRun.run();
    if (objectId !== "new") {
      getRun && getRun.run(objectId);
    }
    if (objectId !== undefined) {
      listCompanyAll && listCompanyAll().then((res: any) => {
        setCompanies(res.results);
      });
    }
  }, [objectId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refreshPage();
  }, [objectId]); // eslint-disable-line react-hooks/exhaustive-deps

  // render
  const [orderInfo] = useState({
    orderDirection: 'descend',
    selectedOrderField: 'updatedAt',
  });
  const {orderDirection, selectedOrderField} = orderInfo;
  const sorterTooltipMSG = orderDirection === 'ascend' ? '내림차순으로 정렬하기' : '오름차순으로 정렬하기';

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    let c = [
      {
        title: '이름',
        dataIndex: 'name',
        key: 'name',
        render: (v: string) => v,
        showSorterTooltip: {
          title: selectedOrderField === 'name' ? sorterTooltipMSG : '오름차순으로 정렬하기',
        },
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      },
      {
        title: '지식맵 아이디',
        dataIndex: 'domain',
        key: 'domain',
        render: (v: string) => v,
        showSorterTooltip: {
          title: selectedOrderField === 'domain' ? sorterTooltipMSG : '오름차순으로 정렬하기',
        },
        sorter: (a: any, b: any) => a.domain.localeCompare(b.domain),
      },
      {
        title: '상태',
        dataIndex: 'status',
        key: 'status',
        render: (v: number) => {
          let label = ""
          const target = statuses.find((e) => e.value === v);
          if (target !== undefined) {
            label = target.label;
          }

          return (
            <Text>{label}</Text>
          )
        },
        showSorterTooltip: {
          title: selectedOrderField === 'status' ? sorterTooltipMSG : '오름차순으로 정렬하기',
        },
        sorter: (a: any, b: any) => a.status.toString().localeCompare(b.status.toString()),
        width: 100,
      },
      {
        title: 'API 키',
        dataIndex: 'accessToken',
        key: 'accessToken',
        render: (v: string) => v,
      },
      {
        title: '생성일',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (v: string) => v && dayjs(v).format('L LT'),
        width: 200,
      },
      {
        title: '',
        // dataIndex: 'dashboard',
        key: 'dashboard',
        render: (_: any, {objectId, status}: any) => {
          return (
            <Space>
              {/*<Button*/}
              {/*  disabled={status !== 3}*/}
              {/*  onClick={(event) => {*/}
              {/*    onClickDashboard(event, objectId);*/}
              {/*  }}>*/}
              {/*  대시보드*/}
              {/*</Button>*/}
              <Button
                type={status === 3 ? undefined : "primary"}
                danger={status === 3}
                disabled={status === 1 || status === 2 || status === 5 || isStarting || isStopping}
                onClick={(event) => {
                  event.stopPropagation();

                  let func = null;
                  switch (status) {
                    case 3:
                      func = stopRun?.run;
                      break;
                    case 4:
                      func = startRun?.run;
                      break;
                  }
                  if (func === null) return false;

                  // @ts-ignore
                  return func(objectId).then((res) => {
                    refreshPage();
                    return res;
                  })
                }}
              >
                {status === 3 ? "중지" : "실행"}
              </Button>
            </Space>
          )
        },
        width: 150,
      },
    ]

    if (isKKMaster) {
      c = [
        {
          title: "회사",
          dataIndex: 'company',
          key: 'company',
          render: (v: string) => v,
          showSorterTooltip: {
            title: selectedOrderField === 'company' ? sorterTooltipMSG : '오름차순으로 정렬하기',
          },
          sorter: (a: any, b: any) => a.company.localeCompare(b.company),
        },
        ...c];
    }
    // @ts-ignore
    setColumns(c);

  }, [isKKMaster]); // eslint-disable-line react-hooks/exhaustive-deps

  // event
  const onDrawerClose = () => {
    history.replace('/kmManager');
  };

  const onClickDashboard = (e: any, objectId: string) => {
    e.stopPropagation();
    console.log(objectId);
  }

  return (
    <Layout style={{padding: '24px', backgroundColor: 'white'}}>
      <Space direction="vertical">
        <Row justify="space-between">
          <Space align="center">
            <Text style={{margin: 0}}>{`총 ${listTotal}개`}</Text>
          </Space>
          <div>
            <Button
              disabled={isContentMaster}
              type="default"
              onClick={() => {
                history.replace('/kmManager/new');
              }}
            >
              지식맵 신청
            </Button>
          </div>
        </Row>
        <Table
          sortDirections={['ascend', 'descend', 'ascend']}
          rowKey={'objectId'}
          rowSelection={{
            selectedRowKeys: [objectId],
            columnWidth: 0, // Set the width to 0
            renderCell: () => '', // Render nothing inside
            hideSelectAll: true,
            type: 'radio',
          }}
          columns={columns}
          dataSource={listData}
          loading={isListing}
          onRow={(record) => {
            return {
              onClick: () => {
                // @ts-ignore
                history.push(`/kmManager/${record.objectId}`);
              },
            };
          }}
        >
        </Table>
      </Space>

      <Drawer
        title={objectId !== 'new' ? '지식맵 수정' : '지식맵 신청'}
        placement="right"
        onClose={() => {
          onDrawerClose();
        }}
        visible={objectId !== undefined}
        width={350}
        mask={false}
        closable
        destroyOnClose
      >
        <KMManagerPanel
          objectId={objectId}
          isPending={isCreating || isGetting || isUpdating || isDeleting}
          initialKMService={getData}
          api={{
            createKMService: createRun?.run,
            updateKMService: updateRun?.run,
            deleteKMService: deleteRun?.run,
          }}
          onCreate={() => {
            message.info('지식맵을 신청하였습니다.');
            history.push(`/kmManager`);
            onDrawerClose();
          }}
          onUpdate={() => {
            message.info('지식맵 정보를 수정하였습니다.');
            history.push(`/kmManager`);
            onDrawerClose();
          }}
          onDelete={() => {
            message.info('지식맵을 삭제하였습니다.');
            history.push(`/kmManager`);
            onDrawerClose();
          }}
          onCancel={() => {
            history.push(`/kmManager`);
            onDrawerClose();
          }}
          statuses={statuses}
          companies={companies ?? []}
        />
      </Drawer>
    </Layout>
  );
}

export default KMManager;