import {
  removeUntilFirstUnderBar,
  urlRewriteToShortenFilename,
} from '../helper';

export const CycleWarningDetail = (
  statusDetail: { cycleInfo: any },
  failedInfoFile: Parse.File,
  title: any
) => {
  const msg = [title, ''];
  const ci = statusDetail.cycleInfo;
  const nodes = ci?.graph?.nodes;
  const nodeLabel =
    nodes !== undefined && nodes.length > 0 ? nodes[0].labels[0] : 'unknown';
  const cycles = ci?.cycles;
  const cycleRelations =
    cycles !== undefined
      ? cycles
          .filter(
            (c: { cycleGraph: { rels: string | any[] } }) =>
              c.cycleGraph.rels.length > 0
          )
          .map(
            (c: { sourceInfoAfterTrim: { relType: any } }) =>
              c.sourceInfoAfterTrim.relType
          )
      : [];
  msg.push(`사이클 발생 노드: ${nodeLabel}`);
  msg.push(`사이클이 발생한 릴레이션 수: ${cycleRelations.length}`);
  msg.push(`사이클이 발생한 릴레이션: [ ${cycleRelations.join(',')} ]`);

  const simpleFileName = removeUntilFirstUnderBar(failedInfoFile.name());
  // const urlRewritten = record.file.url;
  const urlRewritten = urlRewriteToShortenFilename(failedInfoFile.url());
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: `${msg.join('<br/>')}` }} />
      <div>
        <br />
        {'사이클 데이터 파일: '}
        <a href={urlRewritten} target="_blank" rel="noreferrer">
          {simpleFileName}
        </a>
      </div>
    </>
  );
};
