import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ImporterList from '../pages/ImporterList';
import ImporterDetail from '../pages/ImporterDetail';
import ImporterJobs from './ImporterJobs';

const ImporterWithSQL = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/new`}>
        <ImporterList withNewModal={true} />
      </Route>
      <Route path={`${match.path}/:objectId/jobs`}>
        <ImporterJobs />
      </Route>
      <Route path={`${match.path}/:objectId`}>
        <ImporterDetail />
      </Route>
      <Route path={`${match.path}`}>
        <ImporterList />
      </Route>
    </Switch>
  );
};

export default ImporterWithSQL;
