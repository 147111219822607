import {
    Row,
    Col,
    Tag,
    Typography,
    Space,
    InputNumber,
    Button,
    Form, Select,
} from 'antd';
import {getBlackOrWhiteForContrast} from '../helper';
import {pickColor} from '../helper/color';

import {useSelectedNodeNameState} from '../hooks/graphMap';
import _ from 'lodash';
import {useAuth} from "../hooks/auth";
import {useEffect, useState} from "react";

interface NameAndCount {
    name: string;
    count: number;
}

interface NodeAndEdgeSelectorApi {
    listKMServiceCall: (company: string) => void;
    listSchemaCall: (kmService: string) => void;
}

interface Props {
    nodes: NameAndCount[];
    edges: NameAndCount[];
    onSetAboutNodeNumberInput: (data: number) => {};
    onSetAboutRelNumberInput: (data: number) => {};
    api: NodeAndEdgeSelectorApi;
    companies: any[],
    kmServices: any[],
}

const NodeAndEdgeSelectorContainer: React.FC<Props> = ({
                                                           nodes = [],
                                                           edges = [],
                                                           onSetAboutNodeNumberInput,
                                                           onSetAboutRelNumberInput,
                                                           api,
                                                           companies,
                                                           kmServices,
                                                       }) => {

    const {isKKMaster} = useAuth();

    const [selectedNodeName, setSelectedNodeName] = useSelectedNodeNameState();
    const handleClickSelector = (info: NameAndCount, type: string): void => {
        setSelectedNodeName({
            name: info.name,
            type: type,
        });
    };

    // form - company
    const [companyOptions, setCompanyOptions] = useState<any[]>([]);
    useEffect(() => {
        if (companies !== undefined) {
            setCompanyOptions(companies.map((e) => ({
                label: e.name,
                value: e.id,
            })));
        }
    }, [companies]);

    // form - km services
    const [kmServiceOptions, setKMServiceOptions] = useState<any[]>([]);
    useEffect(() => {
        if (kmServices !== undefined) {
            setKMServiceOptions(kmServices.map((e) => ({
                label: e.name,
                value: e.domain,
            })));
        }
    }, [kmServices])


    const content = _.map({node: nodes, relation: edges}, (list, title) => {

        const viewType = title === 'node' ? '노드' : '릴레이션';
        return (
            <>
                <Row justify={'space-between'} align={'top'}>
                    <Typography.Title level={5}>{viewType}</Typography.Title>

                    <Space align={'center'}>
                        <Form
                            onFinish={(data) => {
                                const {maximumCount} = data;
                                title === 'node'
                                    ? onSetAboutNodeNumberInput(
                                        !maximumCount ? 100 : maximumCount
                                    )
                                    : onSetAboutRelNumberInput(
                                        !maximumCount ? 100 : maximumCount
                                    );
                            }}
                        >
                            <Typography.Text>표시수: </Typography.Text>

                            <Form.Item name="maximumCount" noStyle>
                                <InputNumber
                                    min={1}
                                    max={1000}
                                    placeholder="100"
                                    size={'small'}
                                    style={{width: '77px'}}
                                />
                            </Form.Item>
                            <Button size={'small'} htmlType="submit">
                                확인
                            </Button>
                        </Form>
                    </Space>
                </Row>
                <Row>
                    {list.map((info: NameAndCount, idx: number) => {
                        const color = pickColor(info.name, idx);
                        const bgColor = selectedNodeName.name === info.name ? color : '#ffffff';
                        return (
                            <Tag
                                style={{
                                    margin: 3,
                                    cursor: 'pointer',
                                    color: getBlackOrWhiteForContrast(bgColor),
                                    backgroundColor: bgColor,
                                    borderColor: color,
                                }}
                                onClick={() => {
                                    handleClickSelector(info, title.toLowerCase());
                                }}
                                key={info.name}
                            >{`${info.name} (${info.count})`}</Tag>
                        );
                    })}
                </Row>
            </>
        );
    });
    return (
        <Col>
            {isKKMaster && (
                <Row justify="space-between" style={{marginBottom: "20px"}}>
                    <Col span={5} style={{display: "flex", alignItems: "center"}}>
                        <Typography.Title style={{margin: 0}} level={5}>회사</Typography.Title>
                    </Col>
                    <Col span={18}>
                        <Select style={{width: "100%"}} options={companyOptions} onChange={(value: string) => {
                            api.listKMServiceCall && api.listKMServiceCall(value);
                        }} placeholder={"회사를 선택해주세요."}/>
                    </Col>
                </Row>
            )}

            <Row justify="space-between" style={{marginBottom: "20px"}}>
                <Col span={5} style={{display: "flex", alignItems: "center"}}>
                    <Typography.Title style={{margin: 0}} level={5}>지식맵</Typography.Title>
                </Col>
                <Col span={18}>
                    <Select style={{width: "100%"}} options={kmServiceOptions} onChange={(value: string) => {
                        api.listSchemaCall && api.listSchemaCall(value);
                    }} placeholder={"지식맵을 선택해주세요."}/>
                </Col>
            </Row>

            {content[0]}

            <br/>

            {content[1]}
        </Col>
    );
};

export type {NameAndCount};
export default NodeAndEdgeSelectorContainer;
