import React from 'react';
import {
  Button,
  Col,
  Row,
  Space,
  Modal,
  Typography,
  Tag,
  Divider,
  Tooltip,
} from 'antd';
import {
  ExclamationCircleOutlined,
  TagOutlined,
  KeyOutlined,
} from '@ant-design/icons';
import { useRequest } from '../hooks/request';
import Parse from 'parse';
import _ from 'lodash';
import { useAuth } from '../hooks/auth';

interface KKSchema {
  name: string;
  labelPropName: string;
  uniqPropName: string;
}
interface GraphItemDetailProps {
  onCancel: () => void;
  onDelete: (id: string) => void;
  graphItem: any;
  fromGraphItem?: any;
  toGraphItem?: any;
  kkSchemas: {
    main?: KKSchema;
    from?: KKSchema;
    to?: KKSchema;
  };
  neighbourNodeCnt: number;
  hiddenNeighbourNode: any;
  hiddenNeighbourRel?: any;
  curNeighbourNode: any;
  curNeighbourRel: any;
  onNeighborNodeClick: any;
}

const deleteGraphItem = ({
  graphItem,
  fromGraphItem,
  toGraphItem,
}: {
  graphItem: any;
  fromGraphItem: any;
  toGraphItem: any;
}) => {
  const newUserCreatedGraphChange = new Parse.Object('UserCreatedGraphChange');
  const data = fromGraphItem
    ? {
        type: 'delete',
        kkSchemaName: graphItem.kkSchemaName,
        data: {
          from: {
            kkSchemaName: fromGraphItem.kkSchemaName,
            properties: fromGraphItem.properties,
          },
          to: {
            kkSchemaName: toGraphItem.kkSchemaName,
            properties: toGraphItem.properties,
          },
        },
      }
    : {
        type: 'delete',
        kkSchemaName: graphItem.kkSchemaName,
        data: {
          properties: graphItem.properties,
        },
      };
  // console.log(data);
  return newUserCreatedGraphChange.save(data);
};

const GraphItemDetail: React.FC<GraphItemDetailProps> = ({
  kkSchemas,
  onCancel,
  onDelete,
  graphItem,
  fromGraphItem,
  toGraphItem,
  neighbourNodeCnt,
  hiddenNeighbourNode,
  hiddenNeighbourRel,
  curNeighbourNode,
  curNeighbourRel,
  onNeighborNodeClick,
}) => {
  const auth = useAuth();
  const isNodeType = !fromGraphItem;
  const [{ pending: isDeleting }, { run: runDelete }] =
    useRequest(deleteGraphItem);
  const showDeleteConfirm = () => {
    Modal.confirm({
      title: '정말로 선택하신 그래프 아이템을 삭제하겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okText: '삭제하기',
      okType: 'danger',
      cancelText: '아니요',
      //@ts-ignore
      confirmLoading: isDeleting,
      onOk() {
        // 해당 노드 삭제 로직
        // onFinish(initialItem, 'delete');
        runDelete({ graphItem, fromGraphItem, toGraphItem }).then(() => {
          onDelete(graphItem.id);
        });
      },
      onCancel() {},
    });
  };
  const propertiesToLi = (
    properties: any,
    kkSchema?: KKSchema,
    privateOnly = false
  ) => {
    return _.map(
      _.pickBy(properties, (x: string, k: string) => {
        const isPrivateKey = k.startsWith('_KK_') || k === 'relType';
        return privateOnly ? isPrivateKey : !isPrivateKey;
      }),
      (v, k) => {
        const tag =
          kkSchema?.labelPropName === k ? (
            <Tooltip title="레이블">
              <TagOutlined />
            </Tooltip>
          ) : kkSchema?.uniqPropName === k ? (
            <Tooltip title="유니크">
              <KeyOutlined />
            </Tooltip>
          ) : null;
        return (
          <span>
            {tag} {k}: {v}
          </span>
        );
      }
    );
  };
  const displaySections: {
    title: any;
    items: any[];
  }[] = [
    {
      title: '스키마 유형',
      items: [isNodeType ? '노드' : '릴레이션'],
    },
    {
      title: '스키마 이름',
      items: [graphItem.kkSchemaName],
    },
    {
      title: '스키마 속성',
      items: propertiesToLi(graphItem.properties, kkSchemas?.main),
    },
  ];
  if (!isNodeType) {
    displaySections.push({
      title: (
        <>
          <span>From </span>
          <Tag>{kkSchemas?.from?.name}</Tag>
        </>
      ),
      items: propertiesToLi(fromGraphItem.properties, kkSchemas?.from),
      // items: [<Tag style={{ marginLeft: '-17px' }}>{kkSchemas?.from?.name}</Tag>, ...propertiesToLi(fromGraphItem.properties, kkSchemas?.from)]
    });
    displaySections.push({
      title: (
        <>
          <span>To </span>
          <Tag>{kkSchemas?.to?.name}</Tag>
        </>
      ),
      items: propertiesToLi(toGraphItem.properties, kkSchemas?.to),
      // items: [<Tag style={{ marginLeft: '-17px' }}>{kkSchemas?.to?.name}</Tag>, ...propertiesToLi(toGraphItem.properties, kkSchemas?.to)]
    });
  }

  if (_.find(auth.roleNames, (rn) => rn.startsWith('laiv-'))) {
    displaySections.push({
      title: 'KK 내부 속성',
      items: propertiesToLi(graphItem.properties, kkSchemas?.main, true),
    });
  }

  const handleNodeNameOfNeighborClick = (e: any) => {
    console.log('selected neighbor node id:', e.target.dataset.value);
    onNeighborNodeClick(e.target.dataset.value);
  };

  return (
    <Col>
      <Space direction="vertical">
        {_.map(displaySections, ({ title, items }) => {
          return items.length ? (
            <Row key={title}>
              <Col>
                <Typography.Title level={5}>{title}</Typography.Title>
                <ul style={{ paddingInlineStart: '15px' }}>
                  {_.map(items, (v, idx) => (
                    <li key={idx}>{v}</li>
                  ))}
                </ul>
              </Col>
            </Row>
          ) : null;
        })}
      </Space>
      <Divider />
      <Row justify="space-between" align="middle">
        {/* {error ? error : null} */}
        <Typography.Text
          type="danger"
          underline
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            showDeleteConfirm();
          }}
        >
          삭제
        </Typography.Text>
        <Space>
          <Button
            htmlType="button"
            onClick={() => {
              onCancel();
            }}
          >
            닫기
          </Button>
        </Space>
      </Row>
      <Divider />
      <Row>
        <Col>
          <Typography.Title level={5}>
            연결된 노드수 : {neighbourNodeCnt}개
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <table
          style={{
            borderCollapse: 'separate',
            textAlign: 'center',
            fontSize: '90%',
          }}
        >
          <thead>
            <tr>
              <th>스키마</th>
              <th>노드명</th>
              <th>UID</th>
              <th>릴레이션</th>
            </tr>
          </thead>
          <tbody>
            {curNeighbourNode?.map((n: any, idx: number) => {
              return (
                <tr>
                  <td>{n.kkSchemaName}</td>
                  <Button
                    style={{
                      border: 'none',
                      borderBottom: '1px solid black',
                    }}
                  >
                    <td
                      data-value={n.id}
                      onClick={handleNodeNameOfNeighborClick}
                    >
                      {n.properties.title}
                    </td>
                  </Button>
                  <td>{n.properties.uid}</td>
                  <td>{curNeighbourRel[idx]?.kkSchemaName}</td>
                </tr>
              );
            })}
            {hiddenNeighbourNode?.map((n: any, idx: number) => {
              return (
                <tr>
                  <td>{n.kkSchemaName}</td>
                  <Button
                    style={{
                      border: 'none',
                      borderBottom: '1px solid black',
                    }}
                  >
                    <td
                      data-value={n.id}
                      onClick={handleNodeNameOfNeighborClick}
                    >
                      {n.properties.title}
                    </td>
                  </Button>
                  <td>{n.properties.uid}</td>
                  {hiddenNeighbourRel && <td>{hiddenNeighbourRel[idx]}</td>}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Row>
    </Col>
  );
};
export default GraphItemDetail;
