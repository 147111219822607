import {useState, useRef, useEffect} from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import {
  cloudSchema,
  cloudUser,
  cloudKMService,
  cloudBill,
  cloudCompany,
  cloudTenant,
  cloudImporterItem, cloudGraphMap, cloudKMServiceUsageMonth,
} from '../parse-queries';
import {useRequest} from './request';
import {
  getAllEntityCountByCompany as kmsGetAllEntityCountByCompany,
} from "../kms-request";
import Parse from "parse";

export const useKMSState = () => {

  const getAllEntityCountByCompany = (company) => {
    return kmsGetAllEntityCountByCompany(company).then((nodeAndRelation) => {
      return {
        nodes: nodeAndRelation.labels,
        edges: nodeAndRelation.relTypes,
      };
    })
  }
  const [allEntityCountByCompanyState, getAllEntityCountByCompanyRun] = useRequest(getAllEntityCountByCompany);

  return [
    {
      allEntityCountByCompanyState,
    },
    {
      getAllEntityCountByCompanyRun,
    }
  ];
}

export const useSchemaState = () => {
  const [listData, setListData] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [getData, setGetData] = useState({});

  // list
  const list = (options) =>
    cloudSchema.list(options).then((res) => {
      unstable_batchedUpdates(() => {
        const filteredResult = res.results || [];
        setListData(filteredResult);
        setListTotal(filteredResult.length);
      });
    });
  const [listState, listRun] = useRequest(list);

  const listAll = (kmService) => {
    return cloudSchema.listAll(kmService);
  }

  // create
  const create = (data) => {
    return cloudSchema.create(data);
  }
  const [createState, createRun] = useRequest(create);

  // get
  const get = async (objectId) => {
    if (objectId === undefined || objectId === "new") {
      unstable_batchedUpdates(() => {
        setGetData({});
      })
    } else {
      cloudSchema.get(objectId).then((res) => {
        unstable_batchedUpdates(() => {
          setGetData(res);
        })
      });
    }
  }
  const [getState, getRun] = useRequest(get);

  const getResult = async (objectId) => {
    return cloudSchema.get(objectId);
  }

  // update
  const update = (objectId, data) => {
    return cloudSchema.update(objectId, data);
  }
  const [updateState, updateRun] = useRequest(update);

  // delete
  const del = (objectId) => {
    return cloudSchema.delete(objectId);
  }
  const [deleteState, deleteRun] = useRequest(del);

  //
  const hasImporterItemResult = (objectId) => {
    return cloudSchema.hasImporterItem(objectId);
  }

  return [
    {
      listData: listData,
      listTotal: listTotal,
      getData: getData,
      isListing: listState.isPending,
      isCreating: createState.isPending,
      isGetting: getState.isPending,
      isUpdating: updateState.isPending,
      isDeleting: deleteState.isPending,
    },
    {
      listRun,
      listAll,
      createRun,
      getRun,
      getResult,
      updateRun,
      deleteRun,
      hasImporterItemResult,
    },
  ];
}

export const useSchemaListState = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const updateData = (newItem) => {
    if (newItem === undefined) return;
    const idx = data.findIndex((item) => item.objectId === newItem.objectId);
    if (idx !== -1) {
      data[idx] = newItem;
    }
    setData([...data]);
  };

  const load = (options) =>
    cloudSchema.list(options).then((res) => {
      unstable_batchedUpdates(() => {
        const filteredResult = res.results || [];
        setData(filteredResult);
        setTotal(filteredResult.length);
      });
    });
  const [{isPending}, {run}] = useRequest(load);

  return [
    {data, total, isPending},
    {run, updateData},
  ];
};

export const useImporterItemState = () => {
  const [listData, setListData] = useState([]);
  const [listTotal, setListTotal] = useState(0);

  // list
  const list = (options) =>
    cloudImporterItem.list(options).then((res) => {
      unstable_batchedUpdates(() => {
        const filteredResult = res.results || [];
        setListData(filteredResult);
        setListTotal(filteredResult.length);
      });
    });
  const [listState, listRun] = useRequest(list);

  // save
  const save = (data) => {
    const newIptItem = new Parse.Object('ImporterItem');
    return newIptItem.save(data);
  }
  const [saveState, saveRun] = useRequest(save);

  // exec
  const runImporterJob = (objectId) => {
    return Parse.Cloud.run('runImporterItemJob', {objectId});
  }
  const [runImporterJobState, runImporterJobRun] = useRequest(runImporterJob);

  return [
    {
      listData: listData,
      listTotal: listTotal,
      isListing: listState.isPending,
      isSaving: saveState.isPending,
      runImporterJobState,
    },
    {
      listRun,
      saveRun,
      runImporterJobRun,
    },
  ];

}

export const useListWithIncrementalKey = (initialState = [], keyName = '_key') => {
  const [list, setList] = useState(initialState);
  const counterRef = useRef(-1);

  const pushItem = (item) => {
    setList((l) => [
      ...l,
      {
        [keyName]: ++counterRef.current,
        ...item, //item이 이미 똑같은 key가 있으면 그것 사용
      },
    ]);
  };

  const removeItem = (key) => {
    setList((l) => l.filter((item) => item[keyName] !== key));
  };

  const removeItems = (keys) => {
    unstable_batchedUpdates(() => {
      keys.forEach(removeItem);
    });
  };

  return {list, pushItem, removeItem, removeItems};
};

export const useFirstRenderState = () => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    setIsFirstRender();
  }, []);
  return [isFirstRender];
};

export const useUserState = () => {
  const [listData, setListData] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [getData, setGetData] = useState({});

  // signUp
  const signUp = async (data) => {
    return cloudUser.signUp(data);
  }
  const [signUpState, signUpRun] = useRequest(signUp);

  // list
  const list = () => {
    cloudUser.list().then((res) => {
      unstable_batchedUpdates(() => {
        const filteredResult = res.results || [];
        setListData(filteredResult);
        setListTotal(filteredResult.length);
      });
    });
  }
  const [listState, listRun] = useRequest(list);

  // create
  const create = (data) => {
    return cloudUser.create(data);
  }
  const [createState, createRun] = useRequest(create);

  // get
  const get = (objectId) => {
    if (objectId === undefined || objectId === "new") {
      unstable_batchedUpdates(() => {
        setGetData({});
      })
    } else {
      cloudUser.get(objectId).then((res) => {
        unstable_batchedUpdates(() => {
          setGetData(res);
        })
      });
    }
  }
  const [getState, getRun] = useRequest(get);

  // update
  const update = (objectId, data) => {
    return cloudUser.update(objectId, data);
  }
  const [updateState, updateRun] = useRequest(update);

  // delete
  const del = (objectId) => {
    return cloudUser.delete(objectId);
  }
  const [deleteState, deleteRun] = useRequest(del);

  return [
    {
      listData: listData,
      listTotal: listTotal,
      getData: getData,
      isListing: listState.isPending,
      isCreating: createState.isPending,
      isGetting: getState.isPending,
      isUpdating: updateState.isPending,
      isDeleting: deleteState.isPending,
      isSigningUp: signUpState.isPending,
    },
    {
      signUpRun,
      listRun,
      createRun,
      getRun,
      updateRun,
      deleteRun,
    },
  ]
}

export const useKMServiceState = () => {
  const [listData, setListData] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [getData, setGetData] = useState({});

  // list
  const list = () =>
    cloudKMService.list().then((res) => {
      unstable_batchedUpdates(() => {
        const filteredResult = res.results || [];
        setListData(filteredResult);
        setListTotal(filteredResult.length);
      });
    });
  const [listState, listRun] = useRequest(list);

  const listAll = (company) => {
    return cloudKMService.listAll(company);
  }

  // create
  const create = (data) => {
    return cloudKMService.create(data);
  }
  const [createState, createRun] = useRequest(create);

  // get
  const get = async (objectId) => {
    if (objectId === undefined || objectId === "new") {
      unstable_batchedUpdates(() => {
        setGetData({});
      })
    } else {
      cloudKMService.get(objectId).then((res) => {
        unstable_batchedUpdates(() => {
          setGetData(res);
        })
      });
    }
  }
  const [getState, getRun] = useRequest(get);

  // update
  const update = (objectId, data) => {
    return cloudKMService.update(objectId, data);
  }
  const [updateState, updateRun] = useRequest(update);

  // delete
  const del = (objectId) => {
    return cloudKMService.delete(objectId);
  }
  const [deleteState, deleteRun] = useRequest(del);

  // exe
  const start = (objectId) => {
    return cloudKMService.start(objectId);
  }
  const [startState, startRun] = useRequest(start);

  const stop = (objectId) => {
    return cloudKMService.stop(objectId);
  }
  const [stopState, stopRun] = useRequest(stop);

  return [
    {
      listData: listData,
      listTotal: listTotal,
      getData: getData,
      isListing: listState.isPending,
      isCreating: createState.isPending,
      isGetting: getState.isPending,
      isUpdating: updateState.isPending,
      isDeleting: deleteState.isPending,
      isStarting: startState.isStarting,
      isStopping: stopState.isPending,
    },
    {
      listRun,
      listAll,
      createRun,
      getRun,
      updateRun,
      deleteRun,
      startRun,
      stopRun,
    },
  ];
};

export const useKMServiceUsageMonthState = () => {
  // list
  const listMonths = () => cloudKMServiceUsageMonth.list();
  const listMonthsByDomain = (domain) => cloudKMServiceUsageMonth.listByDomain(domain)

  return [
    {},
    {listMonths, listMonthsByDomain},
  ];
}


export const useBillState = () => {
  const [listData, setListData] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [getData, setGetData] = useState({});

  // list
  const list = () =>
    cloudBill.list().then((res) => {
      unstable_batchedUpdates(() => {
        const filteredResult = res.results || [];
        setListData(filteredResult);
        setListTotal(filteredResult.length);
      });
    });
  const [listState, listRun] = useRequest(list);

  const listOptions = () => {
    return cloudBill.listOptions();
  }

  const listOptionsByDomain = (domain) => {
    return cloudBill.listOptionsByDomain(domain);
  }

  // create
  const create = (data) => {
    return cloudBill.create(data);
  }
  const [createState, createRun] = useRequest(create);

  // get
  const get = async (objectId) => {
    if (objectId === undefined || objectId === "new") {
      unstable_batchedUpdates(() => {
        setGetData({});
      })
    } else {
      cloudBill.get(objectId).then((res) => {
        unstable_batchedUpdates(() => {
          setGetData(res);
        })
      });
    }
  }
  const [getState, getRun] = useRequest(get);

  // update
  const update = (objectId, data) => {
    return cloudBill.update(objectId, data);
  }
  const [updateState, updateRun] = useRequest(update);

  // delete
  const del = (objectId) => {
    return cloudBill.delete(objectId);
  }
  const [deleteState, deleteRun] = useRequest(del);

  return [
    {
      listData: listData,
      listTotal: listTotal,
      getData: getData,
      isListing: listState.isPending,
      isCreating: createState.isPending,
      isGetting: getState.isPending,
      isUpdating: updateState.isPending,
      isDeleting: deleteState.isPending,
    },
    {
      listRun,
      listOptions,
      listOptionsByDomain,
      createRun,
      getRun,
      updateRun,
      deleteRun,
    },
  ];
};


export const useCompanyState = () => {
  const listAll = () => {
    return cloudCompany.listAll();
  }

  return [
    {},
    {
      listAll,
    },
  ]
}

export const useTenantState = () => {
  const [listData, setListData] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [getData, setGetData] = useState({});

  // list
  const list = () =>
    cloudTenant.list().then((res) => {
      unstable_batchedUpdates(() => {
        const filteredResult = res.results || [];
        setListData(filteredResult);
        setListTotal(filteredResult.length);
      });
    });
  const [listState, listRun] = useRequest(list);

  // create
  const create = (data) => {
    return cloudTenant.create(data);
  }
  const [createState, createRun] = useRequest(create);

  // get
  const get = async (objectId) => {
    if (objectId === undefined || objectId === "new") {
      unstable_batchedUpdates(() => {
        setGetData({});
      })
    } else {
      return cloudTenant.get(objectId).then((res) => {
        unstable_batchedUpdates(() => {
          setGetData(res);
        })

        return res;
      });
    }
  }
  const [getState, getRun] = useRequest(get);

  // update
  const update = (objectId, data) => {
    return cloudTenant.update(objectId, data);
  }
  const [updateState, updateRun] = useRequest(update);

  // delete
  const del = (objectId) => {
    return cloudTenant.delete(objectId);
  }
  const [deleteState, deleteRun] = useRequest(del);

  return [
    {
      listData: listData,
      listTotal: listTotal,
      getData: getData,
      isListing: listState.isPending,
      isCreating: createState.isPending,
      isGetting: getState.isPending,
      isUpdating: updateState.isPending,
      isDeleting: deleteState.isPending,
    },
    {
      listRun,
      createRun,
      getRun,
      updateRun,
      deleteRun,
    },
  ];
};

export const useGraphMapState = () => {
  // 세션 타임 늘리는 용
  const extend = () => {
    return cloudGraphMap.extend();
  }

  return [
    {},
    {
      extend
    }
  ]
}