import { useRecoilState } from 'recoil';
import { SelectedNodeName, SelectedPropertyName } from '../state/atoms';

export const useSelectedNodeNameState = () => {
  const [selectedNodeName, setSelectedNodeName] =
    useRecoilState<any>(SelectedNodeName);
  return [selectedNodeName, setSelectedNodeName];
};

export const useSelectedPropertyNameState = () => {
  const [selectedPropertyName, setSelectedPropertyName] =
    useRecoilState<any>(SelectedPropertyName);
  return [selectedPropertyName, setSelectedPropertyName];
};
