import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ImporterItemList from './ImporterItemList';

const Importer = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`}>
        <ImporterItemList />
      </Route>
      {/* <Route path={`${match.path}/new`}>
        <ImporterList withNewModal={true} />
      </Route> */}
      {/* <Route path={`${match.path}/:objectId`}>
        <ImporterDetail />
      </Route> */}
      {/* <Route path={match.path}>
        <ImporterList />
      </Route> */}
    </Switch>
  );
};

export default Importer;
