import {Button, Col, Divider, Form, Input, Modal, Row, Select, Space, Spin, Typography} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";

interface TenantApi {
  createTenant?: (TenantForm: any) => Promise<void>,
  updateTenant?: (objectId: String, TenantForm: any) => Promise<void>,
  deleteTenant?: (objectId: String) => Promise<void>,
}

interface TenantPanelProps {
  objectId: string;
  initialTenant: any,
  isPending: boolean;
  api: TenantApi;
  onCreate: () => void;
  onUpdate: () => void;
  onDelete: () => void;
  onCancel: () => void;
  statuses: any[];
}

const TenantManagerPanel = ({
                              objectId,
                              initialTenant,
                              isPending,
                              api,
                              onCreate,
                              onUpdate,
                              onDelete,
                              onCancel,
                              statuses,
                            }: TenantPanelProps) => {

  // mode
  const mode = (objectId === "new") ? "new" : "edit";
  const isEditMode = mode === 'edit';
  const isNewMode = mode === 'new';

  // form - status input
  const [status, setStatus] = useState("");

  // form
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialTenant === null) return;

    form.setFieldsValue({
      status: initialTenant.status ?? "",
      ...initialTenant
    })
    if (initialTenant.status !== undefined) {
      setStatus(initialTenant.status)
    }
  }, [form, initialTenant]);

  // event
  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const safeConfirm = (title: string, content: string, confirmFunc: () => void) => {
    Modal.confirm({
      title,
      content,
      icon: <ExclamationCircleOutlined/>,
      okText: '네',
      okType: 'danger',
      cancelText: '아니오',
      onOk() {
        confirmFunc();
      },
      onCancel() {
      },
    });
  };

  const showCancelConfirm = () => {
    Modal.confirm({
      title: '작성중인 내용을 취소하겠습니까?',
      icon: <ExclamationCircleOutlined/>,
      okText: '네',
      okType: 'danger',
      cancelText: '아니오',
      onOk() {
        onCancel();
      },
      onCancel() {
      },
    });
  };

  return (
    <>
      {isEditMode && isPending ? (
        <Spin
          tip="Loading..."
          style={{position: 'fixed', top: '50%', left: '50%'}}
        ></Spin>
      ) : (
        <Col>
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => {
              safeConfirm(
                objectId === undefined ? "회사를 추가합니다." : "회사 정보를 수정합니다.",
                "회사 정보를 저장하시겠습니까?",
                () => {
                  if (isEditMode) {
                    api.updateTenant && api.updateTenant(objectId, values).then(() => {
                      onUpdate();
                    });
                  } else {
                    api.createTenant && api.createTenant(values).then(() => {
                      onCreate();
                    })
                  }
                },
              );
            }}
            onFinishFailed={onFinishFailed}
          >

            <Form.Item
              data-test="error-message-of-name-in-add-use"
              name="name"
              label="회사명"
              required-mark="true"
              rules={[{required: true, message: '이름을 입력해주세요'}]}>
              <Input data-test="test_name"/>
            </Form.Item>

            <Form.Item
              data-test="error-message-of-domain-in-sign"
              name="domain"
              label="회사아이디 (영대문자 2~4자)"
              required-mark="true"
              rules={[{required: true, message: '회사아이디를 입력해주세요.'}]}
            >
              <Input data-test="sign_Tenant" disabled={isEditMode}/>
            </Form.Item>

            {isEditMode ? (<></>) : (
              <Form.Item
                data-test="error-message-of-user-name-in-sign"
                name="userName"
                label="가입자 이름"
                required-mark="true"
                rules={[{required: true, message: '가입자 이름을 입력해주세요.'}]}
              >
                <Input data-test="sign_Tenant"/>
              </Form.Item>
            )}

            {isEditMode ? (<></>) : (
              <Form.Item
                data-test="error-message-of-email-in-sign"
                name="email"
                label="가입자 이메일"
                required-mark="true"
                rules={[{required: true, message: '가입자 이메일을 입력해주세요'}]}
              >
                <Input data-test="sign_email"/>
              </Form.Item>
            )}

            {isEditMode ? (<></>) : (
              <Form.Item
                data-test="error-message-of-phone-no-in-sign"
                name="phoneNo"
                label="가입자 전화번호"
                required-mark="true"
                rules={[{required: true, message: '가입자 전화번호를 입력해주세요.'}]}
              >
                <Input data-test="sign_phone_no"/>
              </Form.Item>
            )}

            {isEditMode ? (<></>) : (
              <Form.Item
                data-test="error-message-of-password-in-sign"
                name="password"
                label="비밀번호"
                required-mark={isNewMode ? "true" : "false"}
                rules={isNewMode ? [{required: true, message: '비밀번호를 입력해주세요'}] : []}
              >
                <Input data-test="sign_password" type="password"/>
              </Form.Item>
            )}

            {
              !isNewMode ? (
                  <Form.Item
                    data-test="error-message-of-role-in-sign"
                    name="status"
                    label="상태"
                    required-mark="true"
                    rules={[{required: true, message: '상태를 선택해 주세요.'}]}
                  >
                    <Select options={statuses} value={status} onChange={(value) => {
                      setStatus(value);
                    }}/>
                  </Form.Item>)
                : (<></>)
            }


            <Divider/>
            <Row justify="space-between" align="middle">
              {isEditMode ? (
                <Typography.Text
                  data-test="btn-delete-in-knowledge-panel"
                  type="danger"
                  underline
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    safeConfirm(
                      "회사 삭제",
                      "회사를 삭제하시겠습니까?",
                      () => {
                        api.deleteTenant && api.deleteTenant(objectId).then(() => {
                          onDelete();
                        });
                      });
                  }}
                >
                  삭제
                </Typography.Text>
              ) : (
                <div/>
              )}

              <Space>
                <Button
                  data-test="btn-cancel-in-knowledge"
                  htmlType="button"
                  onClick={() => {
                    if (isEditMode || isNewMode) {
                      showCancelConfirm();
                    } else {
                      onCancel();
                    }
                  }}
                >
                  취소
                </Button>
                <Button
                  data-test="btn-save-in-knowledge"
                  loading={isPending}
                  type="primary"
                  htmlType="submit"
                >
                  저장
                </Button>
              </Space>
            </Row>
          </Form>
        </Col>
      )}
    </>
  );
}

export default TenantManagerPanel;