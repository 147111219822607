import React, {useCallback, useEffect, useState} from 'react'
import dayjs from "dayjs";
import {Button, Drawer, Layout, List, message, Row, Space, Table, Typography} from "antd";
import {useHistory, useParams} from "react-router-dom";
import {useBillState, useCompanyState} from "../hooks";
import {useProvideAuth} from "../hooks/auth";
import BillPanel from "../components/BillPanel";
import {commaWithNumber} from "../helper";

const {Text} = Typography;

const statuses = [
  {
    label: '결제대기',
    value: 1,
  },
  {
    label: '결제완료',
    value: 2,
  },
]

const Bill = () => {
  const history = useHistory();
  const {objectId} = useParams() as {
    objectId: string
  };

  const {isKKMaster} = useProvideAuth();

  const [companies, setCompanies] = useState();

  // api
  const [{}, {listAll: listCompanyAll}] = useCompanyState(); // eslint-disable-line no-empty-pattern

  const [
    {
      listData = [],
      listTotal,
      getData,
      isListing,
      isCreating,
      isGetting,
      isUpdating,
      isDeleting,
    },
    {
      listRun,
      createRun,
      getRun,
      updateRun,
      deleteRun,
    },
  ] = useBillState();

  // refresh
  const refreshPage = useCallback(() => {
    listRun && listRun.run();

    if (objectId !== "new") {
      getRun && getRun.run(objectId);
    }

    if (objectId !== undefined) {
      listCompanyAll && listCompanyAll().then((res: any) => {
        setCompanies(res.results);
      });
    }

  }, [objectId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refreshPage();
  }, [objectId]); // eslint-disable-line react-hooks/exhaustive-deps

  // render
  const [orderInfo] = useState({
    orderDirection: 'descend',
    selectedOrderField: 'updatedAt',
  });
  const {orderDirection, selectedOrderField} = orderInfo;
  const sorterTooltipMSG = orderDirection === 'ascend' ? '내림차순으로 정렬하기' : '오름차순으로 정렬하기';

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    let c = [
      {
        title: '날짜',
        dataIndex: 'name',
        key: 'name',
        render: (v: string) => v,
        showSorterTooltip: {
          title: selectedOrderField === 'name' ? sorterTooltipMSG : '오름차순으로 정렬하기',
        },
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      },
      {
        title: '이용요금',
        dataIndex: 'fee',
        key: 'fee',
        render: (v: number) => commaWithNumber(v),
      },
      {
        title: '발행일시',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (v: string) => v && dayjs(v).format('L LT'),
      },
      {
        title: '결제일시',
        dataIndex: 'paidAt',
        key: 'paidAt',
        render: (v: string) => {
          if (v === undefined || v === null || v.length === 0) return "-";
          return v && dayjs(v).format('L LT');
        },
      },
      {
        title: '상태',
        dataIndex: 'status',
        key: 'status',
        render: (v: number) => {
          let label = ""
          const target = statuses.find((e) => e.value === v);
          if (target !== undefined) {
            label = target.label;
          }

          return (
            <Text>{label}</Text>
          )
        },
        showSorterTooltip: {
          title: selectedOrderField === 'status' ? sorterTooltipMSG : '오름차순으로 정렬하기',
        },
        sorter: (a: any, b: any) => a.status.toString().localeCompare(b.status.toString()),
      },
    ];

    if (isKKMaster) {
      c = [
        {
          title: "회사",
          dataIndex: 'company',
          key: 'company',
          render: (v: string) => v,
          showSorterTooltip: {
            title: selectedOrderField === 'company' ? sorterTooltipMSG : '오름차순으로 정렬하기',
          },
          sorter: (a: any, b: any) => a.company.localeCompare(b.company),
        },
        ...c];
    }
    // @ts-ignore
    setColumns(c);

  }, [isKKMaster, selectedOrderField, sorterTooltipMSG]);

  // event
  const onDrawerClose = () => {
    history.replace('/bill');
  };

  return (
    <Layout style={{padding: '24px', backgroundColor: 'white'}}>
      <Space direction="vertical">

        <Row justify="space-between">
          <Space align="center">
            <Text style={{margin: 0}}>{`총 ${listTotal}개`}</Text>
          </Space>
          {isKKMaster && (
            <div>
              <Button
                type="default"
                onClick={() => {
                  history.replace('/bill/new');
                }}
              >
                정산 발행
              </Button>
            </div>
          )}
        </Row>

        <Table
          sortDirections={['ascend', 'descend', 'ascend']}
          rowKey={'objectId'}
          rowSelection={{
            selectedRowKeys: [objectId],
            columnWidth: 0, // Set the width to 0
            renderCell: () => '', // Render nothing inside
            hideSelectAll: true,
            type: 'radio',
          }}
          columns={columns}
          dataSource={listData}
          loading={isListing}
          onRow={(record: any) => {
            return {
              onClick: () => {
                if (isKKMaster) {
                  history.push(`/bill/${record.objectId}`);
                }
              },
            };
          }}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <List
                  size="small"
                  header={<Typography.Title level={5} title={"지식맵"} style={{margin: 0}}>지식맵 서비스</Typography.Title>}
                  bordered
                  dataSource={[
                    {
                      name: "Node",
                      count: record.nodeCount,
                    },
                    {
                      name: "Relation",
                      count: record.relationCount
                    }
                  ]}
                  renderItem={(item) => {
                    return (<List.Item>
                      <List.Item.Meta title={" - " + item.name} style={{marginLeft: "10px"}}/>
                      <div>{commaWithNumber(item.count)} 개</div>
                    </List.Item>);
                  }}
                />
              )
            },
          }}
        >
        </Table>

        <Drawer
          title={objectId !== 'new' ? '정산 정보 수정' : '정산 정보 발행'}
          placement="right"
          onClose={() => {
            onDrawerClose();
          }}
          visible={objectId !== undefined}
          width={350}
          mask={false}
          closable
          destroyOnClose
        >
          <BillPanel
            objectId={objectId}
            isPending={isCreating || isGetting || isUpdating || isDeleting}
            initialBill={getData}
            api={{
              createBill: createRun?.run,
              updateBill: updateRun?.run,
              deleteBill: deleteRun?.run,
            }}
            onCreate={() => {
              message.info('정산 정보를 발행하였습니다.');
              history.push(`/bill`);
              onDrawerClose();
            }}
            onUpdate={() => {
              message.info('정산 정보를 수정하였습니다.');
              history.push(`/bill`);
              onDrawerClose();
            }}
            onDelete={() => {
              message.info('정산 정보를 삭제하였습니다.');
              history.push(`/bill`);
              onDrawerClose();
            }}
            onCancel={() => {
              history.push(`/bill`);
              onDrawerClose();
            }}
            statuses={statuses}
            companies={companies ?? []}
          />
        </Drawer>
      </Space>
    </Layout>
  )
}

export default Bill
