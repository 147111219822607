import { Row, Tag, Col } from 'antd';
import { getBlackOrWhiteForContrast } from '../helper';
import { useSelectedPropertyNameState } from '../hooks/graphMap';

type PropertyColorMap = {
  [propertyName: string]: string;
};

interface Props {
  properties: string[];
  propertyColorMap: PropertyColorMap;
}

const NodePropertySelectorContainer: React.FC<Props> = ({
  properties = [],
}) => {
  const [selectedPropertyName, setSelectedPropertyName] =
    useSelectedPropertyNameState();
  const handleClickSelector = (propertyName: string): void => {
    // console.log(propertyName);
    setSelectedPropertyName({
      name: propertyName,
    });
  };

  return (
    <>
      <Row align="middle" style={{ padding: '12px 24px' }}>
        <Col style={{ width: '120px' }}>{'화면에 표시할 속성'}</Col>
        {/* {console.debug(JSON.stringify(properties, null, 2))} */}
        {properties.map((propertyName: string) => {
          const color = '#cccccc';
          const bgColor =
            selectedPropertyName.name === propertyName ? color : '#ffffff';
          return (
            <Tag
              style={{
                margin: 3,
                cursor: 'pointer',
                color: getBlackOrWhiteForContrast(bgColor),
                backgroundColor: bgColor,
                borderColor: color,
              }}
              onClick={() => {
                handleClickSelector(propertyName);
              }}
              key={propertyName}
            >{`${propertyName}`}</Tag>
          );
        })}
      </Row>
    </>
  );
};

export default NodePropertySelectorContainer;
