import {authContext, useAuth, useProvideAuth} from './hooks/auth';
import {
  Switch,
  Route,
  Link,
  useLocation,
  useHistory,
  Redirect,
} from 'react-router-dom';
import {Menu, Layout, Button, Dropdown,} from 'antd';
import Home from './pages/Home';
import {
  UserOutlined,
  DeploymentUnitOutlined,
  CreditCardOutlined,
  SketchOutlined,
} from '@ant-design/icons';
import './App.less';
import SchemaList from './pages/SchemaList';
import Login from './pages/Login';
import Sign from "./pages/Sign";
import Importer from './pages/Importer';

import ImporterWithSQL from './pages/ImporterWithSQL';
import AuthManager from './pages/AuthManager';
import GraphMap from './pages/GraphMap';
import _ from 'lodash';
import logoImgSrc from './assets/images/logo.png';
import InnerContentWithKKBreadcrumb from './components/InnerContentWithKKBreadcrumb';
import UserManager from "./pages/UserManager";
import SignComplete from "./pages/SignComplete";
import KnowledgeServiceList from "./pages/KMManager";
import Bill from "./pages/Bill";
import TenantManager from "./pages/TenantManager";
import TenantManagerUsage from "./pages/TenantManagerUsage";

const {Header} = Layout;


function App() {
  const provideAuth = useProvideAuth();
  return (
    <authContext.Provider value={provideAuth}>
      <Layout style={{minHeight: '100vh'}}>
        <Switch>
          <Route path="/login"></Route>
          <Route path="/sign"></Route>
          <Route path="/">
            <DefaultKKHeader/>
          </Route>
        </Switch>
        <Layout>
          <Switch>
            <PrivateRoute path="/graphMap">
              <GraphMap/>
            </PrivateRoute>
            <Route path="/logIn">
              <Login/>
            </Route>
            <Route path="/sign/complete">
              <SignComplete/>
            </Route>
            <Route path="/sign">
              <Sign/>
            </Route>
            <Route path="/">
              <InnerContentWithKKBreadcrumb>
                <Switch>
                  <PrivateRoute path="/importer">
                    <Importer/>
                  </PrivateRoute>
                  <PrivateRoute path="/schema/:objectId">
                    <SchemaList/>
                  </PrivateRoute>
                  <PrivateRoute path="/schema">
                    <SchemaList/>
                  </PrivateRoute>
                  <PrivateRoute path="/importerWithSQL">
                    <ImporterWithSQL/>
                  </PrivateRoute>
                  <PrivateRoute path="/authManager">
                    <AuthManager/>
                  </PrivateRoute>
                  <PrivateRoute path="/userManager/:objectId">
                    <UserManager/>
                  </PrivateRoute>
                  <PrivateRoute path="/userManager">
                    <UserManager/>
                  </PrivateRoute>
                  <PrivateRoute path="/kmManager/:objectId">
                    <KnowledgeServiceList/>
                  </PrivateRoute>
                  <PrivateRoute path="/kmManager">
                    <KnowledgeServiceList/>
                  </PrivateRoute>
                  <PrivateRoute path="/bill/:objectId">
                    <Bill/>
                  </PrivateRoute>
                  <PrivateRoute path="/bill">
                    <Bill/>
                  </PrivateRoute>
                  <PrivateRoute role="kk-master" path="/tenantManager/:objectId/usage">
                    <TenantManagerUsage />
                  </PrivateRoute>
                  <PrivateRoute role="kk-master" path="/tenantManager/:objectId">
                    <TenantManager/>
                  </PrivateRoute>
                  <PrivateRoute role="kk-master" path="/tenantManager">
                    <TenantManager/>
                  </PrivateRoute>
                  <Route path="/">
                    <Home/>
                  </Route>
                </Switch>
              </InnerContentWithKKBreadcrumb>
            </Route>
          </Switch>
          {/* <Switch>
            <Route path="/importer">
              
            </Route>
          </Switch> */}
        </Layout>
        {/* <Footer style={{ textAlign: 'center' }}>
          <a href="https://laivdata.com">LA.IV DATA Co., Ltd.</a>
        </Footer> */}
      </Layout>
    </authContext.Provider>
  );
}

const PrivateRoute = ({children, role, ...rest}) => {
  const auth = useAuth();

  if ((auth.user) && (role !== undefined) && (role !== auth.user.get("roleName"))) {
    return <Route
      render={({location}) => (<Redirect to={{pathname: '/', state: {from: location}}}/>)}
    />
  }

  return (
    <Route
      {...rest}
      render={({location}) => {
        return auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/logIn',
              state: {from: location},
              // 코드 설명: login.tsx에서 location안의 From을 참고함으로써
              // login 성공 후 원래 가려던 페이지로 가는 것
            }}
          />
        );
      }}
    />
  );
};

const DefaultKKHeader = () => {
  let {pathname} = useLocation();
  const history = useHistory();
  const firstPath = pathname.split('/')[1];
  const {user, roleNames, isKKMaster, isDomainMaster, logOut} = useAuth();

  const roleDisplayMap = {
    'kk-master': 'KK 관리자',
    "domain-master": "도메인 관리자",
    "curr-master": "커리큘럼 마스터",
    "con-master": "컨텐츠 마스터",
  };

  return (
    <Header>
      <Link to="/">
        <img
          src={logoImgSrc}
          alt="logo"
          style={{
            height: '40px',
            float: 'left',
            margin: '12px 24px 0 0',
            // border: '1px solid #fff',
            // borderRadius: '2px',
          }}
        />
      </Link>
      <Menu theme="dark" mode="horizontal" selectedKeys={[firstPath]}>
        {isKKMaster && (
          <Menu.Item key="tenantManager" icon={<SketchOutlined/>}>
            <Link to="/tenantManager">테넌트 관리</Link>
          </Menu.Item>
        )}

        {(isKKMaster || isDomainMaster) && (
          <Menu.Item key="userManager" icon={<UserOutlined/>}>
            <Link to="/userManager">IAM</Link>
          </Menu.Item>
        )}

        {(user) && (
          <Menu.SubMenu key={'kkGraph'} icon={<DeploymentUnitOutlined/>} title='지식맵'>
            <Menu.Item key="kmManager">
              <Link to="/kmManager">지식맵 관리</Link>
            </Menu.Item>
            <Menu.Item key="schema">
              <Link to="/schema">그래프 스키마</Link>
            </Menu.Item>
            <Menu.Item key="importer">
              <Link to="/importer">대량 데이터 업로드</Link>
            </Menu.Item>
            {/*{_.includes(auth.roleNames, 'laiv-curr-master') && (*/}
            {/*  <Menu.Item key="importerWithSQL">*/}
            {/*    <Link to="/importerWithSQL">*/}
            {/*      <strike>대량 데이터 업로드</strike>(구버전)*/}
            {/*    </Link>*/}
            {/*  </Menu.Item>*/}
            {/*)}*/}
            <Menu.Item key="graphMap">
              <Link to="/graphMap">지식맵 보기</Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}


        {(isKKMaster || isDomainMaster) && (
          <Menu.Item key="bill" icon={<CreditCardOutlined/>}>
            <Link to="/bill">정산</Link>
          </Menu.Item>
        )}

        <Menu.Item key="inquiry" icon={<CreditCardOutlined/>}>
          <a target="_blank" rel="noreferrer"
             href="https://laivdata.notion.site/laivdata/fbb8b32c54884d50b6d0c145990c86c9">문의</a>
        </Menu.Item>

        {/*{auth.isKKMaster && (*/}
        {/*  <Menu.Item key="authManager" icon={<KeyOutlined/>}>*/}
        {/*    <Link to="/authManager">API 접근 권한 관리</Link>*/}
        {/*  </Menu.Item>*/}
        {/*)}*/}


      </Menu>
      <div
        style={{
          color: 'white',
          position: 'absolute',
          right: 50,
          top: 0,
        }}
      >
        {user ? (
          <>
            <Dropdown
              overlay={
                <Menu>
                  {_.map(roleNames, (r) => (
                    <Menu.Item key={r}>{roleDisplayMap[r] || r}</Menu.Item>
                  ))}
                  <Menu.Item>
                    <Link
                      data-test="option-logout-from-dropdown-in-header"
                      to={'#'}
                      onClick={() =>
                        logOut && logOut().then(() => history.replace('/login'))
                      }
                    >
                      로그 아웃
                    </Link>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                data-test="dropdown-in-header"
                icon={<UserOutlined/>}
                shape="round"
                ghost
              >
                {user.get('username')}
              </Button>
            </Dropdown>
          </>
        ) : (
          <Link to={'/logIn'}>
            <Button shape="round" ghost>
              로그인
            </Button>
          </Link>
        )}
      </div>
    </Header>
  );
};

export default App;
