// eslint-disable-next-line no-unused-vars
import {Button, Form, Input, Row, Col, message} from 'antd';
import {useHistory, useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import {useAuth} from '../hooks/auth';
import styles from './Login.module.css';

import {UserOutlined, LockOutlined} from '@ant-design/icons';
import logoImgSrc from '../assets/images/logo.png';

const Login = () => {
    const auth = useAuth();
    const history = useHistory();
    const location = useLocation();
    const {from} = location.state !== undefined && location.state;
    // 코드 해석: const { from } = location.state; 시에 undefined에 대해 구조 분해 할당이 불가능하다는 에러가 발생해서 location.state !== undefined라는 조건을 주었습니다.
    // 만약 location.state가 undefined일 경우 from에도 undefined가 저장됩니다.

    const onFinish = ({email, password}) => {
        auth
            .logIn(email.trim(), password.trim())
            .then(() => history.replace(from || {pathname: '/'}))
            .catch((e) => {
                if (e.code === 141) {
                    message.error(e.message);
                } else {
                    message.error('아이디 또는 비밀번호가 일치하지 않습니다.');
                }
                // console.log(JSON.stringify(e));
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    return (
        <Row justify="center" align="middle">
            <Col>
                <Link to="/">
                    <Row
                        style={{
                            padding: '10px 15px',
                            backgroundColor: '#001628',
                            marginTop: '50px',
                        }}
                        align="middle"
                    >
                        <img
                            src={logoImgSrc}
                            alt="logo"
                            style={{
                                height: '40px',
                                // border: '1px solid #fff',
                                // borderRadius: '2px',
                            }}
                        />
                    </Row>
                </Link>
                <Form
                    layout="vertical"
                    name="basic"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={styles.loginForm}
                >
                    <Form.Item
                        data-test="error-message-of-email-in-login"
                        name="email"
                        rules={[{required: true, message: '아이디를 입력해주세요'}]}
                    >
                        <Input
                            data-test="login_id"
                            prefix={<UserOutlined/>}
                            placeholder="이메일"
                        />
                    </Form.Item>

                    <Form.Item
                        data-test="error-message-of-pw-in-login"
                        name="password"
                        rules={[{required: true, message: '비밀번호를 입력해주세요'}]}
                    >
                        <Input.Password
                            prefix={<LockOutlined/>}
                            placeholder="비밀번호"
                            data-test="login_password"
                        />
                    </Form.Item>
                    {/* <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <a className={styles.loginForgotPW} href="">
            Forgot password
          </a>
        </Form.Item> */}

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className={styles.loginButton}
                        >
                            로그인
                        </Button>
                    </Form.Item>

                    <Row justify="center">
                        <Link className={styles.signLink} to={"/sign"}>
                            가입하기
                        </Link>
                        {/* Or <a href="">register now!</a> */}
                    </Row>
                </Form>


            </Col>
        </Row>
    );
};

export default Login;
