import React from 'react';
import { useDeleteFileRequest } from '../hooks/file';
import { Form, Button, Upload, message, UploadProps } from 'antd';

import { UploadOutlined } from '@ant-design/icons';
import Parse from 'parse';

const ParseFileFormItem = (props: UploadProps) => {
  const [, { run: deleteFileUsingParseAPI }] = useDeleteFileRequest();

  const uploaderProps = {
    name: 'file',
    // https://stackoverflow.com/a/58921190
    customRequest: (options: any) => {
      const { onSuccess, onError, file, onProgress } = options;

      const parseFile = new Parse.File(file.name, file);

      parseFile
        .save({
          progress: (
            progressValue: number,
            loaded: number,
            total: number,
            { type }: any
          ) => {
            if (type === 'upload' && progressValue !== null) {
              // Update the UI using progressValue
              onProgress({ percent: (loaded / total) * 100 });
            }
          },
        })
        .then(() => {
          onSuccess(parseFile.toJSON());
        })
        .catch((e) => {
          onError(e);
        });
    },
    onRemove: (file: any) => {
      if (file.status === 'done') {
        deleteFileUsingParseAPI(file.response.name)
          .then(() => message.success(`${file.name} file removed successfully`))
          .catch((e) => {
            message.error(`${file.name} file remove failed.`);
            throw new Error(e);
          });
      }
    },
    onChange(info: any) {
      // console.log('change', info);
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(info.file.error.message);
      }
    },
    accept: '.csv',
    maxCount: 1,
    ...props,
  };

  return (
    <Form.Item shouldUpdate noStyle>
      {() => {
        return (
          <Form.Item
            name={'fileUploader'}
            // extra={`파일당 최대 100MB까지 등록할 수 있습니다\nSQL 파일은 단일 업로드만 가능하고, CSV 파일은 복수 업로드가 가능합니다`}
            style={{ whiteSpace: 'pre-line' }}
            rules={[{ required: true, message: '파일을 업로드해주세요.' }]}
          >
            {/* @ts-ignore */}
            <Upload {...uploaderProps} data-test="file-uploader">
              <Button
                icon={<UploadOutlined />}
                disabled={uploaderProps.disabled}
                data-test="file-uploader-button"
              >
                파일 업로드
              </Button>
            </Upload>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default ParseFileFormItem;
