import ReactHtmlParser from 'react-html-parser';
// import _ from 'lodash';
import {useState, useEffect, useRef} from 'react';
import {
    Row,
    Col,
    Drawer,
    Table,
    Space,
    Button,
    Modal,
    message,
    Input,
    Form,
    Tag,
    Typography,
    Pagination, Layout,
} from 'antd';
import {useHistory, useParams} from 'react-router-dom';
import Parse from 'parse';
import SchemaPanel from '../components/SchemaPanel';
import dayjs from 'dayjs';
import styles from './SchemaList.module.css';
import {useCompanyState, useKMServiceState, useKMSState, useSchemaState} from '../hooks';
import {replaceToBoldWithCaseInsensitive, download} from '../helper';
import {CycleWarningDetail} from '../components/CycleWarningDetail';
import {useAuth} from '../hooks/auth';
import {
    getRelationMatch,
    getNodeMatch,
} from '../kms-request';

const createCsvStringifier = require('csv-writer').createObjectCsvStringifier;

const {Text} = Typography;
const {confirm} = Modal;

// export data //
const queryGetKKSchemaByName = (name) => {
    const query = new Parse.Query('KKSchema');
    query.equalTo('name', name);
    return query.find({json: true});
};

const exportSchema = async (schema) => {
    const schemaToExport = {
        type: schema.type,
        name: schema.name,
        labelName: schema.labelPropName,
        uniqPropName: schema.uniqPropName,
        props: schema.props,
    };

    const fileName = `schema_${schema.type}_${schema.name}.json`;
    download(JSON.stringify(schemaToExport, null, 2), fileName, 'text/plain');
};

const exportDataAsCsv = async (schema) => {
    // not support relation as csv
    if (schema.type === 'relation') return;
    // const dataToExport = [];
    const schemaProps = schema.props;
    const fileName = `data_${schema.type}_${schema.name}.csv`;

    const graph = await getNodeMatch(schema.name);
    const nodes = (graph && graph.nodes) || [];

    // add csv header
    const header = schemaProps
        .filter((p) => p.name !== undefined)
        .map((p) => {
            return {id: p.name, title: p.name};
        });

    const csvStringifier = createCsvStringifier({
        header,
    });

    const records = nodes.map((n) =>
        Object.fromEntries(header.map((h) => [h.id, n.properties[h.id]]))
    );

    download(
        csvStringifier.getHeaderString() + csvStringifier.stringifyRecords(records),
        fileName,
        'text/plain'
    );
};

const exportDataAsJson = async (schema) => {
    const dataToExport = {
        type: schema.type,
        name: schema.name,
        // ...(schema.props.length > 0 && {props: schema.props}),
    };
    const schemaProps = schema.props;
    const fileName = `data_${schema.type}_${schema.name}.json`;
    if (schema.type === 'relation') {
        const graph = await getRelationMatch(schema.name);
        const relations = (graph && graph.relations) || [];
        const clustered = {};
        const getClusterKey = (r) => r.start.labels[0] + '--' + r.end.labels[0];
        const nodeSchemas = {};

        const addSchema = (schemaName) => {
            if (nodeSchemas[schemaName] === undefined) {
                nodeSchemas[schemaName] = {};
            }
        };

        const getId = (nodeData) => {
            const schema = nodeSchemas[nodeData.labels[0]];
            // old uid name is 'uid'. should be removed later
            const uidName = (schema && schema.uniqPropName) || 'uid';
            return nodeData.properties[uidName] || nodeData.uid;
        };

        relations.forEach((r) => {
            // console.debug(r);
            const cKey = getClusterKey(r);
            let rels = clustered[cKey];
            if (rels === undefined) {
                clustered[cKey] = [];
                rels = clustered[cKey];

                addSchema(r.start.labels[0], nodeSchemas);
                addSchema(r.end.labels[0], nodeSchemas);
            }
        });

        // fill schema from Parse.Query
        const promises = [];
        Object.keys(nodeSchemas).forEach((nodeName) =>
            promises.push(queryGetKKSchemaByName(nodeName))
        );

        const schemas = await Promise.all(promises);
        schemas.forEach((s) => {
            nodeSchemas[s.name] = s;
        });

        relations.forEach((r) => {
            const sId = getId(r.start);
            const eId = getId(r.end);
            if (sId && eId) {
                const cKey = getClusterKey(r);
                clustered[cKey].push([sId, eId]);
            }
        });

        dataToExport['data'] = clustered;
    } else {
        const graph = await getNodeMatch(schema.name);
        const nodes = (graph && graph.nodes) || [];

        // console.debug(nodes);

        const simpleNodes = nodes.map((n) =>
            schemaProps.map((p) => n.properties[p.name])
        );

        dataToExport['data'] = simpleNodes;
    }
    download(JSON.stringify(dataToExport, null, 2), fileName, 'text/plain');
};

const exportData = async (schema) => {
    if (schema.type === 'relation') {
        exportDataAsJson(schema);
    } else {
        exportDataAsCsv(schema);
    }
};
////////////////

const SchemaList = () => {
    const history = useHistory();
    const {objectId} = useParams();

    const {user, isKKMaster, isContentMaster} = useAuth();

    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    // form 객체로 스키마 검색창의 값에 대해 상태관리 할 수 있지만 우리가 원하는 것은
    // input 창에 적히는 값을 그대로 추적하는 것이 아니라 검색(enter, 돋보기 클릭) 시점에서의 keyword이므로
    // 이를 상태관리하기 위해 새롭게 상태관리 변수를 추가했습니다.
    const [schemaSearchKeyword, setSchemaSearchKeyword] = useState(null);

    const [companies, setCompanies] = useState([]);
    const [kmServices, setKMServices] = useState([])

    const [entityCount, setEntityCount] = useState(null);

    // api
    const [{}, {listAll: listCompanyAll}] = useCompanyState(); // eslint-disable-line no-empty-pattern

    const [{}, {listAll: listKMServiceAll}] = useKMServiceState(); // eslint-disable-line no-empty-pattern

    const [{allEntityCountByCompanyState}, {getAllEntityCountByCompanyRun}] = useKMSState();

    const [
        {
            listData = [],
            listTotal,
            getData,
            isListing,
            isCreating,
            isGetting,
            isUpdating,
            isDeleting,
        },
        {
            listRun,
            createRun,
            getRun,
            getResult,
            updateRun,
            deleteRun,
            hasImporterItemResult,
        },
    ] = useSchemaState();

    // refresh
    const listKMServiceCall = (company) => {
        listKMServiceAll && listKMServiceAll(company).then((res) => {
            setKMServices(res.results);
        })
    }

    const refreshPage = (targetPage = pageNum) => {
        getAllEntityCountByCompanyRun.run(user.get('company')).then((res) => {
            setEntityCount(res);
        });

        listRun && listRun.run({
            // limit: 1000, not valid
            // skip: (targetPage - 1) * pageSize, not valid
            // keyword: schemaSearchKeyword,
            // orderKey: selectedOrderField,
            // orderDirection,
        });

        if (objectId !== "new") {
            getRun && getRun.run(objectId);
        }

        if (objectId !== undefined) {
            listCompanyAll && listCompanyAll().then((res) => {
                setCompanies(res.results);
            });

            listKMServiceCall();
        }
    };

    useEffect(() => {
        refreshPage();
    }, [pageNum, pageSize, objectId, schemaSearchKeyword /* orderDirection, selectedOrderField, */]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (listTotal <= (pageNum - 1) * pageSize) {
            setPageNum(Math.ceil(listTotal / pageSize) || 1);
        }
    }, [listTotal, pageNum, pageSize]);

    // render
    const [orderInfo, setOrderInfo] = useState({
        orderDirection: 'descend',
        selectedOrderField: 'updatedAt',
    });
    const {orderDirection, selectedOrderField} = orderInfo;
    const sorterTooltipMSG = orderDirection === 'ascend' ? '내림차순으로 정렬하기' : '오름차순으로 정렬하기';

    // form
    // 스키마 검색 input에 focus를 하기 위한 useRef object 생성
    const searchInputRef = useRef(null);
    // 스키마 검색 input의 제어를 위한 form 객체 생성(reset field를 사용하기 위함)
    const [form] = Form.useForm();

    // total이 현재 page보다 작으면 현재 페이지를 1 줄인다.
    //렌더안에
    const [cycleCheckingId, setCycleCheckingId] = useState('');

    const exportDataEnabled = (record) => {
        const countData = allEntityCountByCompanyState.data;
        if (countData === undefined) return false;
        const countList =
            record.type === 'node' ? countData.nodes : countData.edges;
        if (countList !== undefined) {
            const entity = countList.find((n) => n.name === record.name);
            return entity !== undefined && entity.count > 0;
        }
        return false;
    };

    const cycleCheckEnabled = (record) => {
        if (cycleCheckingId === '' && record.status !== 'pending') {
            const nodes = allEntityCountByCompanyState.data && allEntityCountByCompanyState.data.nodes;

            if (nodes !== undefined) {
                const node = nodes.find((n) => n.name === record.name);
                return node !== undefined && node.count > 0;
            }
        }
        return false;
    };

    const pendingCheck = (objectId) => {
        setTimeout(async () => {
            const cycleCheckPending = await getResult(objectId);
            if (cycleCheckPending.status !== 'pending') {
                refreshPage();
                setCycleCheckingId('');
            } else {
                pendingCheck(objectId);
            }
        }, 1000);
    };

    const schemaCycleCheck = ({objectId}) => {
        setCycleCheckingId(objectId);
        Parse.Cloud.run('runSchemaCycleCheck', {objectId});
        pendingCheck(objectId);
    };

    const showCycleInfoDetail = (record) => {
        confirm({
            icon: null,
            content: CycleWarningDetail(
                record.statusDetail,
                record.failedInfoFile,
                '노드 그래프에서 사이클이 발견되었습니다.'
            ),
            okText: '확인',
            cancelButtonProps: {style: {display: 'none'}},
        });
    };

    const getCycleInfoButton = (record) => {
        const currentChecking = cycleCheckingId === record.objectId;
        const hasChecked = record.status === 'fulfilled';
        const hasCycle = record.statusDetail !== undefined;
        // nothing to show
        if (!currentChecking && !hasChecked && record.status !== 'pending')
            return '';

        const isCycleCheckPending = currentChecking || record.status === 'pending';
        return (
            <Button
                style={{
                    background: '#FFFFFF',
                    boxSizing: 'border-box',
                    borderRadius: '20px',
                    marginLeft: '10px',
                }}
                disabled={isCycleCheckPending || (hasChecked && !hasCycle)}
                loading={isCycleCheckPending}
                onClick={(event) => {
                    event.stopPropagation();
                    showCycleInfoDetail(record);
                }}
            >
                {hasCycle ? '사이클 확인' : '사이클 없음'}
            </Button>
        );
    };

    // const rowSelection = {
    //   onChange: (selectedRowKeys, selectedRows) => {
    //     setSelectedSchemas(selectedRows);
    //   },
    // };

    // const openExportModal = () => {
    //   setExportModalVisible(true);
    // };

    const [columns, setColumns] = useState([]);
    useEffect(() => {
        let c = [
            {
                title: '스키마 이름',
                dataIndex: 'name',
                key: 'name',
                render: (schemaName) => {
                    // if (!schemaName.includes(schemaSearchKeyword)) {
                    //   return schemaName;
                    // }
                    // const containedBoldSchemaName = _.replace(
                    //   schemaName,
                    //   schemaSearchKeyword,
                    //   `<b>${schemaSearchKeyword}</b>`
                    // );
                    const containedBoldSchemaName = replaceToBoldWithCaseInsensitive(
                        schemaName,
                        schemaSearchKeyword
                    );

                    return containedBoldSchemaName !== schemaName ? (
                        <Text>{ReactHtmlParser(containedBoldSchemaName)}</Text>
                    ) : (
                        schemaName
                    );
                },
                showSorterTooltip: {
                    title:
                        selectedOrderField === 'name'
                            ? sorterTooltipMSG
                            : '오름차순으로 정렬하기',
                },
                sorter: (a, b) => a.name.localeCompare(b.name),
            },
            {
                title: '스키마 유형',
                dataIndex: 'type',
                key: 'type',
                showSorterTooltip: {
                    title:
                        selectedOrderField === 'type'
                            ? sorterTooltipMSG
                            : '오름차순으로 정렬하기',
                },
                sorter: (a, b) => a.type.localeCompare(b.type),
            },
            {
                title: '관리자',
                dataIndex: 'author',
                key: 'author',
                render: (v) => (v ? v.get("name") : '-'),
                sorter: (a, b) => a.author?.get("name").localeCompare(b.author?.get("name")),
                showSorterTooltip: {
                    title:
                        selectedOrderField === 'author'
                            ? sorterTooltipMSG
                            : '오름차순으로 정렬하기',
                },
            },
            {
                title: '생성일시',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (v) => v && dayjs(v).format('L LT'),
                sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
                defaultSortOrder: 'descend',
                showSorterTooltip: {
                    title:
                        selectedOrderField === 'createdAt'
                            ? sorterTooltipMSG
                            : '오름차순으로 정렬하기',
                },
            },
            {
                title: '수정일시',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                render: (v) => v && dayjs(v).format('L LT'),
                sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
                // defaultSortOrder: 'descend',
                showSorterTooltip: {
                    title:
                        selectedOrderField === 'updatedAt'
                            ? sorterTooltipMSG
                            : '오름차순으로 정렬하기',
                },
            },
            {
                title: '지식맵 데이터 검증',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                render: (status, record) => {
                    if (record.type !== 'node') return '';
                    return (
                        <>
                            <Button
                                style={{
                                    background: '#FFFFFF',
                                    boxSizing: 'border-box',
                                    borderRadius: '20px',
                                }}
                                disabled={!cycleCheckEnabled(record)}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    schemaCycleCheck(record);
                                }}
                            >
                                검사
                            </Button>
                            {getCycleInfoButton(record)}
                        </>
                    );
                },
            },

            {
                title: '내보내기',
                dataIndex: 'name',
                align: 'center',
                render: (data, record) => {
                    return (
                        <>
                            <Button
                                style={{
                                    background: '#FFFFFF',
                                    boxSizing: 'border-box',
                                    borderRadius: '20px',
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    exportSchema(record);
                                }}
                            >
                                스키마
                            </Button>
                            <Button
                                style={{
                                    background: '#FFFFFF',
                                    boxSizing: 'border-box',
                                    borderRadius: '20px',
                                    marginLeft: '10px',
                                }}
                                disabled={!exportDataEnabled(record)}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    exportData(record);
                                }}
                            >
                                데이터
                            </Button>
                        </>
                    );
                },
            },
        ];

        if (isKKMaster) {
            c = [
                {
                    title: "회사",
                    dataIndex: 'company',
                    key: 'company',
                    render: (v) => v,
                    showSorterTooltip: {
                        title: selectedOrderField === 'company' ? sorterTooltipMSG : '오름차순으로 정렬하기',
                    },
                    sorter: (a, b) => a.company.localeCompare(b.company),
                },
                ...c];
        }
        // @ts-ignore
        setColumns(c);

    }, [isKKMaster, entityCount, cycleCheckingId]); // eslint-disable-line react-hooks/exhaustive-deps

    // event
    const onDrawerClose = () => {
        history.replace('/schema');
    };

    return (
        <Layout style={{padding: '24px', backgroundColor: 'white'}}>
            {/* <SchemaExportModal
        modalVisible={exportModalVisible}
        setModalVisible={setExportModalVisible}
        schemasToExport={selectedSchemas}
        exportMsg={exportMsg}
        setExportMsg={setExportMsg}
        ref={searchInputRef}
      ></SchemaExportModal> */}
            <Space direction="vertical">
                <Row justify="space-between">
                    <Space align="center">
                        {schemaSearchKeyword ? (
                            <>
                                <Text style={{margin: 0}}>{`검색 결과: ${listTotal}건`}</Text>
                                <Tag
                                    closable
                                    onClose={() => {
                                        setSchemaSearchKeyword(null);
                                        setPageNum(1);
                                    }}
                                >
                                    {schemaSearchKeyword}
                                </Tag>
                            </>
                        ) : (
                            <Text style={{margin: 0}}>{`총 ${listTotal}개`}</Text>
                        )}
                    </Space>
                    <div>
                        {/* hide button before function will be completed */}
                        {/* <Button
              disabled={!isCurriculumMaster}
              style={{ right: '15px' }}
              type="default"
              onClick={() => setSelectModeEnabled(!selectModeEnabled)}
            >
              스키마 선택
            </Button> */}
                        <Button
                            disabled={isContentMaster}
                            type="default"
                            onClick={() => {
                                history.replace('/schema/new');
                            }}
                        >
                            스키마 생성
                        </Button>
                    </div>
                </Row>
                <Table
                    pagination={{
                        position: ['none', 'none'],
                        current: pageNum,
                        pageSize: pageSize,
                    }}
                    onChange={(pagination, filters, sorter) => {
                        // const { current, pageSize } = pagination;
                        // onPaginationChange(current, pageSize);
                        const {order, field} = sorter;
                        setOrderInfo({orderDirection: order, selectedOrderField: field});
                    }}
                    sortDirections={['ascend', 'descend', 'ascend']}
                    rowKey={'objectId'}
                    rowSelection={{
                        selectedRowKeys: [objectId],
                        columnWidth: 0, // Set the width to 0
                        renderCell: () => '', // Render nothing inside
                        hideSelectAll: true,
                        type: 'radio',
                    }}
                    columns={columns}
                    dataSource={listData}
                    loading={isListing}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                history.push(`/schema/${record.objectId}`);
                            },
                        };
                    }}
                />
                <Row gutter={[16, 0]} justify={'end'}>
                    <Col justify="end">
                        <Space
                            size="large"
                            align="center"
                            className={styles.tableControllerContainer}
                        >
                            <Form form={form} initialValues={{schemaSearchInput: ''}}>
                                <Form.Item name="schemaSearchInput" noStyle>
                                    <Input.Search
                                        className={styles.searchInput}
                                        placeholder="스키마 검색"
                                        ref={searchInputRef}
                                        loading={isListing}
                                        onSearch={(enterText) => {
                                            enterText === ''
                                                ? (() => {
                                                    setSchemaSearchKeyword(null);
                                                    setPageNum(1);
                                                })()
                                                : setSchemaSearchKeyword(enterText);
                                            form.resetFields();
                                            // setTimeout을 사용한 이유:
                                            // 사용하지 않으면 focus된다하더라도 onSearch가 호출 종료된 이후에 바로 blur되기 때문입니다.
                                            // setTimeout을 통해 onSearch 함수의 호출이 종료된 이후에
                                            // focus를 호출하기 위함입니다.
                                            setTimeout(
                                                () =>
                                                    searchInputRef.current.focus({
                                                        cursor: 'first',
                                                    }),
                                                0
                                            );
                                        }}
                                    />
                                </Form.Item>
                            </Form>
                            <Pagination
                                current={pageNum}
                                defaultPageSize={pageSize}
                                total={listTotal}
                                onChange={(inputPageNum, inputPageSize) => {
                                    setPageNum(inputPageNum);
                                    setPageSize(inputPageSize);
                                }}
                            />
                        </Space>
                    </Col>
                </Row>
            </Space>
            <Drawer
                title={objectId !== 'new' ? '스키마 수정' : '스키마 생성'}
                placement="right"
                onClose={() => {
                    onDrawerClose();
                }}
                visible={objectId}
                width={350}
                mask={false}
                closable
                destroyOnClose
            >
                <SchemaPanel
                    objectId={objectId}
                    isPending={isCreating || isGetting || isUpdating || isDeleting}
                    initialSchema={getData}
                    api={{
                        createSchema: createRun?.run,
                        updateSchema: updateRun?.run,
                        deleteSchema: deleteRun?.run,
                        hasImporterItemResult,
                        listKMServiceCall,
                    }}
                    onCreate={() => {
                        message.info('입력하신 스키마가 생성되었습니다.');
                        history.push(`/schema`);
                        setPageNum(1);
                        // refreshPage(objectId === 'new' ? 1 : undefined);
                        onDrawerClose();
                    }}
                    onUpdate={() => {
                        message.info('입력하신 스키마를 수정하였습니다.');
                        history.push(`/schema`);
                        setPageNum(1);
                        // refreshPage(objectId === 'new' ? 1 : undefined);
                        onDrawerClose();
                    }}
                    onDelete={() => {
                        message.info('선택하신 스키마 삭제가 완료되었습니다.');
                        history.push(`/schema`);
                        // refreshPage(1);
                        onDrawerClose();
                    }}
                    onCancel={() => {
                        history.push(`/schema`);
                        onDrawerClose();
                    }}
                    companies={companies}
                    kmServices={kmServices}
                />
            </Drawer>
        </Layout>
    );
};

export default SchemaList;
