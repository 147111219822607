import _ from 'lodash';
import {useEffect, useState} from 'react';
import {removeExtraSpace, extractKorean} from '../helper';
import {useAuth} from '../hooks/auth';
import {
    Form,
    Button,
    Radio,
    Input,
    Row,
    Space,
    Modal,
    Spin,
    Alert,
    Col,
    Typography,
    Divider, Select,
} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import NodeTypeMatchingFields from './NodeTypeMatchingFields';
import InputAndSelectFormList from './InputAndSelectFormList';

// const nodeUidName = '_uid';
// const systemNodeData = { name: nodeUidName, type: 'text' };
const typeDataToView = {
    숫자: 'number',
    텍스트: 'text',
};

// const typeViewToData = _.invert(typeDataToView);

const convertType = (typeName, table) => {
    if (table[typeName] === undefined) return typeName;
    return table[typeName];
};

// const propertyTypeConversionToData = (schema) => {
//   if (schema === undefined) return;
//   schema.props.forEach((p) => (p.type = convertType(p.type, typeViewToData)));
// };

const propertyTypeConversionToView = (schema) => {
    if (schema === undefined) return;
    schema.props.forEach((p) => (p.type = convertType(p.type, typeDataToView)));
};

const SchemaPanel = ({
                         objectId,
                         initialSchema,
                         isPending,
                         api,
                         onCreate,
                         onUpdate,
                         onDelete,
                         onCancel,
                         companies,
                         kmServices,
                     }) => {

    const {isContentMaster, isKKMaster} = useAuth();

    // mode
    const mode = isContentMaster ? "readOnly" : (objectId === "new") ? "new" : "edit";
    const isEditMode = mode === 'edit';
    const isNewMode = mode === 'new';
    const isReadOnly = mode === "readOnly"

    // api
    const [schemaHasImporterItem, setSchemaHasImporterItem] = useState(false);
    useEffect(() => {
        if (objectId !== "new" && objectId !== undefined) {
            api.hasImporterItemResult && api.hasImporterItemResult(objectId).then((res) => {
                setSchemaHasImporterItem(res);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objectId]);

    // form - company
    const [companyOptions, setCompanyOptions] = useState([]);
    useEffect(() => {
        if (companies !== undefined) {
            setCompanyOptions(companies.map((e) => ({
                label: e.name,
                value: e.id,
            })));
        }
    }, [companies]);

    // form - km services
    const [kmServiceOptions, setKMServiceOptions] = useState([]);
    useEffect(() => {
        if (kmServices !== undefined) {
            setKMServiceOptions(kmServices.map((e) => ({
                label: e.name,
                value: e.domain,
            })));
        }
    }, [kmServices])


    // form - static uid
    const [isStaticUid, setIsStaticUid] = useState(false);

    // form
    const [form] = Form.useForm();

    useEffect(
        () => {
            if (initialSchema && !initialSchema.props) {
                initialSchema.type = "node";
                initialSchema.props = [{type: 'text', name: '_uid'}];
            }

            // some old data has '숫자', '텍스트' type
            propertyTypeConversionToView(initialSchema);

            // staticUid
            const initProps = initialSchema?.props;
            setIsStaticUid(isNewMode || (isEditMode && initProps && initProps.length > 0 && initProps[0].name === '_uid'));

            form.setFieldsValue(initialSchema);
        }, // eslint-disable-next-line
        [form, initialSchema]
    );

    // event
    const saveSchema = async (values) => {
        const props = values.props.filter((prop) => prop.name !== undefined);
        const kkSchemaObj = {...values, props};

        let res = null;
        if (isEditMode) {
            if (api.updateSchema !== undefined) {
                res = api.updateSchema(objectId, kkSchemaObj);
            }

        } else {
            if (api.createSchema !== undefined) {
                res = api.createSchema(kkSchemaObj);
            }
        }

        if (res === null) return;

        // objectId가 포함되면 수정, 아니라면 새 KKSchema obj 생성
        res
            .then((obj) => {
                if (isEditMode) {
                    onUpdate();
                } else {
                    onCreate();
                }

                Modal.success({
                    content: `${obj.get("name")} 스키마가 저장되었습니다.`,
                    okText: '확인',
                    onOk() {
                    },
                });
            })
            .catch((e) => {
                console.log(e);

                if (e.code === 137) {
                    form.setFields([
                        {
                            name: 'name',
                            errors: [`${values.name}은 이미 사용 중인 이름입니다.`],
                        },
                    ]);
                } else if (e.code === 141) {
                    alert(e.message);
                    onCancel();
                } else {
                    alert(
                        '문제가 발생하여 삭제 할 수 없습니다. 관리자에게 문의 바랍니다.'
                    );
                }
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    const safeConfirm = (title, content, confirmFunc) => {
        Modal.confirm({
            title,
            content,
            icon: <ExclamationCircleOutlined/>,
            okText: '네',
            okType: 'danger',
            cancelText: '아니오',
            onOk() {
                confirmFunc();
            },
            onCancel() {
            },
        });
    };

    const showCancelConfirm = () => {
        Modal.confirm({
            title: '작성중인 내용을 취소하겠습니까?',
            icon: <ExclamationCircleOutlined/>,
            okText: '네',
            okType: 'danger',
            cancelText: '아니오',
            onOk() {
                onCancel();
            },
            onCancel() {
            },
        });
    };

    const deleteSchema = (id) => {
        api.deleteSchema && api.deleteSchema(id)
            .then(() => {
                onDelete();
            })
            .catch((e) => {
                if (e.code === 141) {
                    alert(e.message);
                } else {
                    alert(
                        '문제가 발생하여 삭제 할 수 없습니다. 관리자에게 문의 바랍니다.'
                    );
                }
                // console.log(JSON.stringify(e));
            });
    };

    const onDeleteClick = () => {
        const {name, objectId: id} = initialSchema;
        Modal.confirm({
            title: `${name} 스키마를 정말로 삭제하겠습니까?`,
            // content: '지식맵이 생성되어 있는 스키마일 경우 삭제할 수 없습니다.',
            icon: <ExclamationCircleOutlined/>,
            okText: '삭제하기',
            okType: 'danger',
            cancelText: '아니요',
            confirmLoading: isPending,
            onOk() {
                if (schemaHasImporterItem) {
                    safeConfirm(
                        '스키마를 삭제합니다.',
                        '지식맵이 생성되어있는 스키마입니다. 정말로 삭제하시겠습니까?',
                        () => deleteSchema(id)
                    );
                } else {
                    deleteSchema(id);
                }
            },
            onCancel() {
            },
        });
    };

    return (
        <>
            {isEditMode && isPending ? (
                <Spin
                    tip="Loading..."
                    style={{position: 'fixed', top: '50%', left: '50%'}}
                ></Spin>
            ) : (
                <Col>
                    {isReadOnly && (
                        <Alert
                            message='수정 권한이 없습니다.'
                            type="info"
                            showIcon
                            style={{marginBottom: '15px'}}
                        />
                    )}

                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={(values) => {
                            // todo: check modified

                            if (schemaHasImporterItem) {
                                safeConfirm(
                                    '스키마를 수정합니다.',
                                    '지식맵이 생성되어있는 스키마입니다. 스키마 수정을 진행하시겠습니까?',
                                    () => saveSchema(values)
                                );
                            } else {
                                saveSchema(values);
                            }
                        }}
                        onFinishFailed={onFinishFailed}
                    >

                        {isKKMaster && (
                            <Form.Item
                                data-test="error-message-of-company-in-schema"
                                name="company"
                                label="회사"
                                required-mark="true"
                                rules={[{required: true, message: '회사를 선택해주세요.'}]}
                            >
                                <Select options={companyOptions} disabled={isEditMode} onChange={(value) => {
                                    api.listKMServiceCall && api.listKMServiceCall(value);
                                    form.resetFields(["domain"])
                                }}/>
                            </Form.Item>
                        )}

                        <Form.Item
                            data-test="error-message-of-domain-in-schema"
                            name="domain"
                            label="지식맵"
                            required-mark="true"
                            rules={[{required: true, message: '지식맵을 선택해주세요.'}]}
                        >
                            <Select options={kmServiceOptions} disabled={isEditMode}/>
                        </Form.Item>


                        <Form.Item
                            label="스키마 유형"
                            name="type"
                            rules={[{required: true, message: '스키마 유형을 선택하세요'}]}
                        >
                            <Radio.Group
                                disabled={!isNewMode}
                                onChange={({target: {value}}) => {
                                    const props =
                                        form.getFieldValue('type') === 'node'
                                            ? [
                                                {
                                                    type: 'text',
                                                    name: '_uid',
                                                },
                                            ]
                                            : [];
                                    form.resetFields(["name", "props"]);
                                    form.setFieldsValue({
                                        type: value,
                                        props: props,
                                    });
                                }}
                            >
                                <Radio value="node">노드</Radio>
                                <Radio
                                    data-text="radio-rel-in-graph-schema-panel"
                                    value="relation"
                                >
                                    릴레이션
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate>
                            {({getFieldValue, setFieldsValue}) => {
                                const isNodeType = getFieldValue('type') === 'node';
                                const isPropNameRequired = getFieldValue('type') === 'node';

                                const isAlpha = (c) => {
                                    return (c >= 'A' && c <= 'Z') || (c >= 'a' && c <= 'z');
                                };

                                const validateFirstChar = (data) => {
                                    const idx = [...data].findIndex(isAlpha);
                                    return idx === -1 ? '' : data.substring(idx);
                                };

                                const trimRelNameChar = (c) => {
                                    return (c >= 'A' && c <= 'Z') ||
                                    (c >= '0' && c <= '9') ||
                                    c === '_'
                                        ? c
                                        : '';
                                };
                                const validateOnKKSchemaName = (data) => {
                                    data = validateFirstChar(data);
                                    return extractKorean(
                                        removeExtraSpace(
                                            isNodeType
                                                ? _.upperFirst(data)
                                                : [...data.toUpperCase()].map(trimRelNameChar).join('')
                                        )
                                    );
                                };
                                return (
                                    <>
                                        <Form.Item
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '스키마 이름을 입력하세요',
                                                },
                                                {
                                                    min: 2,
                                                    message: '최소 2글자를 입력해주세요',
                                                },
                                            ]}
                                        >
                                            <Input
                                                maxLength={100}
                                                data-test="input-schema-name-in-graph-schema-panel"
                                                placeholder="스키마 이름 입력"
                                                disabled={!isNewMode}
                                                onPressEnter={(e) => e.preventDefault()}
                                                onChange={(e) =>
                                                    setFieldsValue({
                                                        name: validateOnKKSchemaName(e.currentTarget.value),
                                                    })
                                                }
                                            />
                                        </Form.Item>

                                        <Form.Item label="스키마 속성" required={isNodeType}>
                                            <InputAndSelectFormList
                                                name="props"
                                                typeOptions={['text', 'number']}
                                                isPropNameRequired={isPropNameRequired}
                                                disabled={isReadOnly}
                                                useStaticUid={
                                                    getFieldValue('type') === 'node' && isStaticUid
                                                }
                                            />
                                        </Form.Item>

                                        {isNodeType && (
                                            <NodeTypeMatchingFields disabled={isReadOnly}/>
                                        )}
                                    </>
                                );
                            }}
                        </Form.Item>
                        <Divider/>
                        <Row justify="space-between" align="middle">
                            {isEditMode ? (
                                <Typography.Text
                                    data-test="btn-delete-in-graph-schema-panel"
                                    type="danger"
                                    underline
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={onDeleteClick}
                                >
                                    삭제
                                </Typography.Text>
                            ) : (
                                <div/>
                            )}

                            <Space>
                                <Button
                                    data-test="btn-cancel-in-graph-schema"
                                    htmlType="button"
                                    onClick={() => {
                                        if (isEditMode || isNewMode) {
                                            showCancelConfirm();
                                        } else {
                                            onCancel();
                                        }
                                    }}
                                >
                                    취소
                                </Button>
                                <Button
                                    data-test="btn-save-in-graph-schema"
                                    loading={isPending}
                                    type="primary"
                                    htmlType="submit"
                                    disabled={isReadOnly}
                                >
                                    저장
                                </Button>
                            </Space>
                        </Row>
                    </Form>
                </Col>
            )}
        </>
    );
};

export default SchemaPanel;
