/* eslint-disable quotes */
import axios from 'axios';
import Parse from 'parse';
import * as querystring from "querystring";

// const kmsSafeString = (str: string | any) => typeof str !== 'string' || str.length === 0 ? '""' : str;
// const kmsSafeValue = (v) => (typeof v === 'string' && v.length > 0) ? `"${v}"` : (typeof v === 'number' ? `${v}` : '""');

export interface KMSNode {
    nid: number;
    uid?: string | number;
    labels: string[];
    properties?: any;
}

export interface KMSRel {
    type: string;
    properties: any;
}

export interface KMSRelation {
    // nid: number
    start: KMSNode;
    end: KMSNode;
    rel: KMSRel;
}

export interface KMSGraph {
    nodes: KMSNode[];
    relations: KMSRelation[];
}

const getUserToken = () => {
    const cur = Parse.User.current();
    const userToken = cur !== undefined && cur.get('accessToken');
    return userToken || 'not_valid';
};

export const getUserDomain = () => {
    const cur = Parse.User.current();
    const userDomain = cur !== undefined && cur.get('domain');
    return userDomain || 'not_valid';
};

export const validateUserDomain = (domainName: string) =>
    (domainName || 'not_valid') === getUserDomain();

const getGeneralHeader = () => ({
    'X-Laiv-API-Key': getUserToken(),
    'X-Laiv-APP-ID': process.env.REACT_APP_KMS_API_APP_ID,
    'Content-Type': 'application/json',
});

const kmsGet = async (url: string) =>
    axios.get(url, {
        headers: getGeneralHeader(),
    });

const kmsPost = async (url: string, body: any) =>
    axios.post(url, body, {
        headers: getGeneralHeader(),
    });

export const getNeighborWithNId = async (
    nId: string,
    exceptionNIds: string
): Promise<KMSGraph> => {
    // todo: exceptionNIds는 불필요.... 일단 데이터는 가져오도록 하는 것이 나을 듯
    return kmsGet(
        process.env.REACT_APP_KMS_API_URL +
        `/v2/subgraph/nbr_nid/${nId}/${exceptionNIds}?useNeo4jId=true`
    )
        .then((result) => result.data?.data?.graph)
        .catch((err) => console.error('error: getNeighborWithNId:: ', err));
};

export const getBigMatrixWithNIds = async (
    startNIds: string[],
    endNIds: string[]
): Promise<KMSGraph> => {
    return kmsPost(
        process.env.REACT_APP_KMS_API_URL +
        `/v2/subgraph/relation/matrix_nid?useNeo4jId=true`,
        {
            startNIds,
            endNIds,
        }
    )
        .then((result) => result.data?.data?.graph)
        .catch((err) => console.error('error: getBigMatrixWithNIds:: ', err));
};

export {getBigMatrixWithNIds as getMatrixWithNIds};

export const getNodeMatch = async (
    label: string,
    propertyName = 'undefined',
    propertyValue = 'undefined',
    useNeo4jId = true,
    limit = -1
): Promise<KMSGraph> => {
    const useNeo4jIdString = useNeo4jId ? 'true' : '';
    return kmsGet(
        process.env.REACT_APP_KMS_API_URL +
        `/v2/subgraph/node/match/${label}/${propertyName}/${propertyValue}?useNeo4jId=${useNeo4jIdString}&limit=${limit}`
    )
        .then((result) => result.data?.data?.graph)
        .catch((err) => console.error('error: getNodeMatch:: ', err));
};

export const getRelationMatch = async (
    relationType: string,
    propertyName = 'undefined',
    propertyValue = 'undefined',
    useNeo4jId = true,
    limit = -1
): Promise<KMSGraph> => {
    const useNeo4jIdString = useNeo4jId ? 'true' : '';
    return kmsGet(
        process.env.REACT_APP_KMS_API_URL +
        `/v2/subgraph/relation/match/${relationType}/${propertyName}/${propertyValue}?useNeo4jId=${useNeo4jIdString}&limit=${limit}`
    )
        .then((result) => result.data?.data?.graph)
        .catch((err) => console.error('error: getRelationMatch:: ', err));
};

export const getAllEntityCount = async (options: any) => {
    let url = process.env.REACT_APP_KMS_API_URL + '/v2/info/lnt?' + querystring.stringify(options);

    return (
        kmsGet(url)
            .then((result) => result?.data?.data)
            // Parse.Cloud.run('getEntityCount')
            //   .then((result) => result)
            .catch((err) => console.error('error: getAllEntityCount:: ', err))
    );
};

export const getAllEntityCountByCompany = async (company: string) => {
    let url = process.env.REACT_APP_KMS_API_URL + '/v2/info/lnt/company/'+company;

    return (
        kmsGet(url)
            .then((result) => result?.data?.data)
            // Parse.Cloud.run('getEntityCount')
            //   .then((result) => result)
            .catch((err) => console.error('error: getAllEntityCountByCompanyId:: ', err))
    );
};
