import Graphin, { Behaviors } from '@antv/graphin'; //{ Behaviors }
import { Row, Col } from 'antd';
const { DragCanvas, ZoomCanvas, DragNode, ActivateRelations, ClickSelect } =
  Behaviors;
const RelationPreview = ({ nodeSchemaFrom, nodeSchemaTo, relationName }) => {
  const data = {
    nodes: [
      {
        id: 'node-0',
        x: 75,
        y: 250,
        style: {
          keyshape: { fill: 'red', stroke: 'red', fillOpacity: 0.5, size: 78 }, //default size: 26
          label: {
            value: 'From\n',
            fontSize: 15,
            position: 'top',
          },
          icon: {
            value: `${nodeSchemaFrom ? nodeSchemaFrom : ''}`,
            type: 'text',
            fill: 'black',
          },
        },
      },
      {
        id: 'node-1',
        x: 225,
        y: 250,
        style: {
          keyshape: {
            fill: 'green',
            stroke: 'green',
            fillOpacity: 0.5,
            size: 78, //default size: 26
          },
          label: {
            value: 'To\n',
            fontSize: 15,
            position: 'top',
          },
          icon: {
            value: `${nodeSchemaTo ? nodeSchemaTo : ''}`,
            type: 'text',
            fill: 'black',
          },
        },
      },
    ],
    edges: [
      {
        source: 'node-0',
        target: 'node-1',
        style: {
          label: {
            value: relationName ? relationName : '',
            fill: 'black',
            fontSize: 12,
          },
          keyshape: {
            stroke: 'black',
            lineWidth: 2,
            lineDash: [10, 5],
            endArrow: {
              path: 'M 0,0 L 8,4 L 8,-4 Z',
              fill: 'black',
            },
          },
        },
      },
    ],
  };
  return (
    <Row>
      <Col span={24}>
        <Graphin data={data} layout={{ type: 'preset' }}>
          <DragCanvas disabled={true} />
          <ZoomCanvas enableOptimize disabled={true} />
          <DragNode disabled={true} />
          <ActivateRelations trigger=" click " disabled={true} />
          <ClickSelect disabled={true} />
        </Graphin>
      </Col>
    </Row>
  );
};

export default RelationPreview;
