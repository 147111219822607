import { Row, Col, Button, Typography, DatePicker, Form, Select, Input } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
// import { CirclePicker } from 'react-color';


// interface ProblemProp extends Parse.Attributes {
//   user_cnt: number;
//   correct_rate: number;
//   intime_cnt: number;
//   solved_cnt: number;
//   pass_rate: number;
// }

interface AnalyticsProps {
  selectedKey: string;
  handleOnClick: any;
  onHightLightValuesChange: (values: any) => void
}

const AnalyticsSelect: React.FC<AnalyticsProps> = ({
  selectedKey,
  handleOnClick,
  onHightLightValuesChange
}) => {
  // problemInfos: 주어진 problem_id와 매칭된 parse 서버에서 가져온 모든 데이터 객체

  const analyticOptions: { name: string; key: string }[] = [
    { name: '학습자수', key: 'user_cnt' },
    { name: '정답율', key: 'correct_rate' },
    { name: '시간내 풀이율', key: 'intime_solved_rate' },
    { name: '통과율', key: 'pass_rate' },
  ];
  const initialConditionValue = {
    condition: {
      number: 30,
      op: 'lte',
      color: '#E93B81'
    }
  }

  useEffect(() => {
    onHightLightValuesChange(initialConditionValue.condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Col>
      <Row>
        <Typography.Title level={5}>분석정보</Typography.Title>
      </Row>
      <Row>
        <DatePicker.RangePicker
          defaultValue={[moment('2020-01-01', 'YYYY-MM-DD'), moment('2020-12-31', 'YYYY-MM-DD')]}
          style={{ width: '100%', marginBottom: '10px' }}
          disabled
        />
      </Row>
      {analyticOptions.map((analyticOption) => (
        <Button
          key={analyticOption.name}
          onClick={() => {
            handleOnClick(analyticOption.key);
          }}
          size="small"
          type={selectedKey === analyticOption.key ? "primary" : "default"}
          style={{
            width: '100%',
            marginTop: '5px'
          }}
        >
          {analyticOption.name}
        </Button>
      ))}
      {selectedKey && selectedKey.endsWith('_rate') && (

        <Form style={{ marginTop: '20px' }} layout="vertical" initialValues={initialConditionValue} onValuesChange={(changedValue, allValues) => {
          onHightLightValuesChange(allValues.condition);
        }}>
          <Form.Item label="분석 정보 하이라이트 조건">
            <Input.Group compact>
              <Form.Item noStyle name={['condition', 'number']}>
                <Select
                  style={{ width: 80 }}
                  // onChange={onCurrencyChange}
                  options={[
                    10, 20, 30, 40, 50, 60, 70, 80, 90
                  ].map(n => ({ value: n, label: n + '%', key: n }))}
                >

                </Select>
              </Form.Item>
              <Form.Item noStyle name={['condition', 'op']}>
                <Select
                  style={{ width: 80, margin: '0 8px' }}
                // onChange={onCurrencyChange}
                >
                  <Select.Option value="gte">이상</Select.Option>
                  <Select.Option value="lte">이하</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item noStyle name={['condition', 'color']}>
                <Input type="hidden" />
                <div style={{ width: 30, height: 30, backgroundColor: '#E93B81' }} />

              </Form.Item>
              {/* <Form.Item>
                <CirclePicker />
              </Form.Item> */}

            </Input.Group>
          </Form.Item>
        </Form>

      )}
    </Col>
  );
};

export default AnalyticsSelect;
