import _ from 'lodash';
import { Row, Col, Tag } from 'antd';
import type { NameAndCount } from '../components/NodeAndEdgeSelectorContainer';
import { getBlackOrWhiteForContrast } from '../helper';
import { pickColor } from '../helper/color';

enum Type {
  'NODE' = 'Node',
  'EDGE' = 'Relation',
}

interface ViewerContainerProps {
  data: { nodes: NameAndCount[]; edges: NameAndCount[] };
}

const NodeAndEdgeViewerContainer: React.FC<ViewerContainerProps> = ({
  data: { nodes, edges },
}) => {
  return (
    <Col>
      {_.map(
        {
          [Type.NODE]: nodes,
          [Type.EDGE]: edges,
        },
        (list, title) => (
          <Row key={title} style={{ margin: '5px 0' }} align="middle">
            <Col style={{ width: '100px' }}>
              {title === 'Node' ? '노드' : '릴레이션'} ({_.sumBy(list, 'count')}
              )
            </Col>
            {list.map((info: NameAndCount, idx: number) => {
              const color = pickColor(info.name, idx);

              // return <Button key={idx} size="small" style={{ borderColor: kkSchemaColor, color: kkSchemaColor, marginRight: '5px' }}>{`${info.name}(${info.count})`}</Button>
              return (
                <Tag
                  key={idx}
                  color={color}
                  style={{ color: getBlackOrWhiteForContrast(color) }}
                >{`${info.name}(${info.count})`}</Tag>
              );
            })}
          </Row>
        )
      )}
    </Col>
  );
};
export default NodeAndEdgeViewerContainer;
