import { useEffect, useState, useCallback } from 'react';
import { useDomain } from '../hooks/authManager';
import { Table, Typography, Button } from 'antd';
import AuthDomainModal from './AuthDomainModal';
import { ModalMode, DomainData } from '../hooks/authType';
import { useRecoilState } from 'recoil';
import { selectedCompanyState, selectedDomainState } from '../hooks/authAtom';

import './AuthGeneral.css';
const { Title } = Typography;

const AuthDomain: React.FC = () => {
  const [company] = useRecoilState(selectedCompanyState);
  const [domain, selectDomain] = useRecoilState(selectedDomainState);
  const { domains, getCompanyDomains, addDomain, updateDomain, hasDomain } =
    useDomain();

  const [modalMode, setModalMode] = useState('close' as ModalMode);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const openAddDomain = () => company?.id && setModalMode('add');
  const openEditDomain = () => domain?.id && setModalMode('edit');
  const closeEditDomain = () => setModalMode('close');
  const clearSelection = useCallback(() => {
    selectDomain({} as DomainData);
    setSelectedRowKeys([]);
  }, [selectDomain]);

  useEffect(() => {
    // console.log('echo: selected company: ', company.id);
    getCompanyDomains(company.id);
    clearSelection();
  }, [clearSelection, company.id, getCompanyDomains]);

  useEffect(() => {
    if (selectedRowKeys.length === 0) return;
    const selected_id = selectedRowKeys[0];
    const found_domain = domains.find((domain) => domain.id === selected_id);
    if (found_domain) {
      selectDomain(found_domain);
    }
  }, [domains, selectDomain, selectedRowKeys]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '서비스별 설정 (JSON)',
      dataIndex: 'domain_info',
    },
    {
      title: '회사',
      dataIndex: 'company',
    },
  ];

  return (
    <>
      <div className="part_header" id="domain_header">
        <Title> 서비스 </Title>
      </div>
      <Table
        sortDirections={['ascend', 'descend', 'ascend']}
        rowKey={'id'}
        columns={columns}
        dataSource={domains}
        rowSelection={{
          selectedRowKeys,
          type: 'radio',
          onChange: (selectedKeys: React.Key[], selectedRows: any) => {
            // console.log(`selected row keys: [${selectedKeys}]]`, selectedRows);
            setSelectedRowKeys(selectedKeys);
          },
        }}
        pagination={{
          hideOnSinglePage: true,
        }}
      ></Table>
      <div className="right_buttons" id="domain_buttons">
        <Button shape="round" onClick={openAddDomain} disabled={!company?.id}>
          추가
        </Button>
        <Button shape="round" onClick={openEditDomain} disabled={!domain?.id}>
          변경
        </Button>
        <Button shape="round" onClick={clearSelection} disabled={!domain?.id}>
          선택 취소
        </Button>
      </div>
      {modalMode !== 'close' ? (
        <AuthDomainModal
          key={'auth_domain_modal'}
          mode={modalMode}
          company_id={company?.id}
          close={closeEditDomain}
          addDomain={addDomain}
          updateDomain={updateDomain}
          hasDomain={hasDomain}
          domain={domain}
        ></AuthDomainModal>
      ) : undefined}
    </>
  );
};

export default AuthDomain;
