import {Layout} from 'antd';
import KKBreadcrumb from './KKBreadcrumb';

const {Content} = Layout;

const InnerContentWithKKBreadcrumb = ({children }) => {
  return (
    <>
      <Content
        style={{
          padding: '0 50px 50px 50px',
          backgroundColor: '#f2f2f2',
        }}
      >
        <KKBreadcrumb style={{margin: '16px 0'}}/>
        {children}
      </Content>
    </>
  );
};

export default InnerContentWithKKBreadcrumb;
