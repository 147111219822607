import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryDBConnectionRequest } from '../hooks/DBConnectionInfo';
import {Col, Row, Table, Button, Modal, Typography, Layout} from 'antd';
import { ExclamationCircleOutlined, RedoOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { useDynamicList } from 'ahooks';
import { UploadModal } from '../components/UploadModal';
import dayjs from 'dayjs';
const { Text } = Typography;

const ImporterList = ({ withNewModal = false }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const history = useHistory();
  const { confirm } = Modal;

  const { remove: removeMatchedItem, getIndex } = useDynamicList([]);
  const [
    { data },
    { run: runLoadDBConnectionList },
  ] = useQueryDBConnectionRequest();

  useEffect(() => {
    runLoadDBConnectionList();
    // eslint-disable-next-line
  }, [data]);

  const columns = [
    {
      title: '파일명',
      dataIndex: 'name',
      key: 'name',
      render: (v, record) => {
        return record.status === 'fulfilled' ? v : <Text disabled>{v}</Text>;
      },
    },
    {
      title: '회사',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: '서비스',
      dataIndex: 'service',
      key: 'service',
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      render: (status) =>
        status === 'fulfilled'
          ? '완료'
          : status === 'pending'
          ? '데이터 생성중'
          : '실패',
    },
    {
      title: '일시',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (t) => (t ? dayjs(t).format('L LT') : t),
    },
    {
      title: '계정',
      dataIndex: 'account',
      key: 'account',
    },
    {
      title: '유저',
      dataIndex: 'author',
      key: 'author',
      render: (v, record) => {
        return record.author ? record.author.username : '-';
      },
    },
    {
      title: 'DB',
      dataIndex: 'database',
      key: 'database',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
  ];

  const emptySelectedRowKeys = () => {
    setSelectedRowKeys([]);
  };

  const deleteSelectedMatchedItems = () => {
    _.each(selectedRowKeys, (key) => removeMatchedItem(getIndex(key)));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const showDeleteConfirm = () => {
    confirm({
      title: '선택 항목을 삭제하겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        deleteSelectedMatchedItems();
        emptySelectedRowKeys();
      },
      onCancel() {},
    });
  };

  return (
    <Layout style={{padding: '24px', backgroundColor: 'white'}}>
      <Row justify="space-between">
        <Row justify="start">
          <Col>
            <Button
              htmlType="button"
              onClick={() => {
                showDeleteConfirm();
              }}
              type="primary"
              danger
            >
              삭제
            </Button>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Button
              icon={<RedoOutlined />}
              style={{ marginBottom: '22px', marginRight: '12px' }}
              onClick={runLoadDBConnectionList}
            >
              새로고침
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                history.push(`/importerWithSQL/new`);
              }}
              style={{ marginBottom: '22px' }}
            >
              파일업로드
            </Button>
            <UploadModal
              isModalOpen={withNewModal}
              onSuccess={() => {
                history.push(`/importerWithSQL`);
              }}
              onCancel={() => {
                history.push(`/importerWithSQL`);
              }}
            />
          </Col>
        </Row>
      </Row>
      <Table
        rowKey={'objectId'}
        rowSelection={{ ...rowSelection }}
        columns={columns}
        dataSource={data && data.list}
        onRow={(record, rowIndex) => {
          return {
            onClick:
              record.status === 'fulfilled'
                ? () => {
                    history.push(`/importerWithSQL/${record.objectId}`);
                  }
                : undefined, // click row
          };
        }}
        // pagination={{
        //   defaultPageSize: pageSize,
        //   onChange: onPaginationChange,
        //   total,
        // }}
        // loading={isPending}
      />
    </Layout>
  );
};

export default ImporterList;
