import React from 'react';
import ReactDOM from 'react-dom';
import Parse from 'parse';
import { RecoilRoot } from 'recoil';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.locale('ko'); // global로 한국어 locale 사용
dayjs.extend(localizedFormat);

//@ts-ignore
Parse.initialize(process.env.REACT_APP_PARSE_APP_ID);
//@ts-ignore
Parse.serverURL = process.env.REACT_APP_PARSE_SERVER_URL;
// Parse.serverURL = "https://api-staging.laivdata.kr/api";
//@ts-ignore
Parse.Object.disableSingleInstance();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
