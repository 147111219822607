import React from 'react';
import { Breadcrumb, BreadcrumbProps } from 'antd';
import _ from 'lodash';
import { Link, useLocation } from 'react-router-dom';

const pathAlias: { [key: string]: string } = {
  schema: '스키마 관리',
  importerWithSQL: '대량 데이터 업로드 (SQL)',
  importer: '대량 데이터 업로드',
  authManager: 'API 권한 관리',
  company: '회사',
  service: '서비스',
  token: 'Access Token',
  acl: 'ACL',
  userManager: "IAM 관리",
  kmManager: "지식맵 관리",
  bill: "정산",
  tenantManager: "테넌트 관리",
  usage: "사용량",
};

const getPathAlias = (v: string) => pathAlias[v] || v;

const KKBreadcrumb: React.FC<BreadcrumbProps> = ({ style }) => {
  const { pathname } = useLocation();
  const items = pathname.split('/');
  return pathname === '/' ? (
    <div style={style} />
  ) : (
    <Breadcrumb style={style}>
      {items.map((v, idx) => (
        <Breadcrumb.Item key={v}>
          <Link to={_.slice(items, 0, idx + 1).join('/') || '/'}>
            {
              v ? getPathAlias(v) : '홈'
              // : <HomeOutlined />
            }
          </Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default KKBreadcrumb;
