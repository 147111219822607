import {Button, Form, Input, Row, Col, Checkbox, message} from 'antd';
import {useHistory, useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import styles from './Sign.module.css';
import logoImgSrc from '../assets/images/logo.png';
import {useUserState} from "../hooks";
import {useCallback, useState} from "react";
import {passwordPolicy} from "../helper";

const Sign = () => {
  const history = useHistory();
  const location = useLocation();

  const {from} = (location.state !== undefined && location.state) as {
    from: any
  };

  // api
  const [{
    isSigningUp
  }, {
    signUpRun
  }] = useUserState();

  // event
  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const [password, setPassword] = useState("")
  const passwordValidator = useCallback((value, required) => passwordPolicy(value, required), []);

  // render
  return (
    <Row justify="center" align="middle">
      <Col>
        <Link to="/">
          <Row
            style={{
              padding: '10px 15px',
              backgroundColor: '#001628',
              marginTop: '50px',
            }}
            align="middle"
          >
            <img
              src={logoImgSrc}
              alt="logo"
              style={{height: '40px'}}
            />
          </Row>
        </Link>

        <Form
          layout="vertical"
          name="basic"
          initialValues={{remember: true}}
          onFinish={(values) => {
            if (values.agreement !== true) {
              message.error('개인정보 취급 및 서비스 이용약관에 동의해주세요.');
              return;
            }

            const userData = {
              email: values.email.trim(),
              password: values.password.trim(),
              name: values.name.trim(),
              phoneNo: values.phoneNo.trim(),
              domainName: values.domainName.trim(),
              domain: values.domain.trim(),
            };

            signUpRun && signUpRun.run(userData)
            .then(() => {
              history.replace(from || {pathname: '/sign/complete'})
            })
            .catch((err) => {
              console.error(err);
              message.error('입력하신 항목들을 확인해주세요.')
            })
          }}
          onFinishFailed={onFinishFailed}
          className={styles.signForm}
        >
          <Form.Item
            data-test="error-message-of-email-in-sign"
            name="email"
            label="이메일"
            required-mark="true"
            rules={[
              {required: true, message: '이메일을 입력해주세요'},
              {pattern: RegExp(/^\S+@\S+\.\S+$/), message: "이메일 형식을 입력해주세요."}
            ]}
          >
            <Input data-test="sign_email"/>
          </Form.Item>

          <Form.Item
            data-test="error-message-of-password-in-sign"
            name="password"
            label="비밀번호"
            required-mark="true"
            rules={[
              {required: true, message: "비밀번호는 필수 입니다."},
              {validator: (_, value) => passwordValidator(value, true)}
            ]}
          >
            <Input data-test="sign_password" type="password" value={password} onInput={(v) => {
              setPassword(v.currentTarget.value)
            }}/>
          </Form.Item>

          <Form.Item
            data-test="error-message-of-password-verify-in-sign"
            name="passwordVerify"
            label="비밀번호 확인"
            required-mark="true"
            rules={[
              {required: true, message: '비밀번호 확인을 입력해주세요'},
              {
                validator: (rule, value) => {
                  if (password !== value) return Promise.reject();
                  return Promise.resolve();

                }, message: "비밀번호가 다릅니다."
              }
            ]}
          >
            <Input data-test="sign_passwordVerify" type="password"/>
          </Form.Item>

          <Form.Item
            data-test="error-message-of-name-in-sign"
            name="name"
            label="이름"
            required-mark="true"
            rules={[
              {required: true, message: '이름을 입력해주세요'},
              {min: 2, message: "이름은 최소 2글자 이상입니다."}
            ]}
          >
            <Input data-test="sign_compnay_name"/>
          </Form.Item>

          <Form.Item
            data-test="error-message-of-phone-no-in-sign"
            name="phoneNo"
            label="전화번호"
            required-mark="true"
            rules={[
              {required: true, message: '전화번호를 입력해주세요.'},
              {pattern: RegExp(/^\d+$/), message: "전화번호는 숫자로만 입력해주세요."}
            ]}
          >
            <Input data-test="sign_phone_no"/>
          </Form.Item>

          <Form.Item
            data-test="error-message-of-domain-name-in-sign"
            name="domainName"
            label="회사명"
            required-mark="true"
            rules={[
              {required: true, message: '회사명을 입력해주세요'},
              {min: 2, message: "회사명은 최소 2글자 이상입니다."}
            ]}
          >
            <Input data-test="sign_compnay_name"/>
          </Form.Item>

          <Form.Item
            data-test="error-message-of-domain-in-sign"
            name="domain"
            label="회사아이디 (영대문자 2~4자)"
            required-mark="true"
            rules={[
              {required: true, message: '회사아이디를 입력해주세요.'},
              {pattern: RegExp(/^([A-Z]).{1,3}$/), message: "회사아이디는 영어대문자로 2~4자입니다."}
            ]}
          >
            <Input data-test="sign_domain"/>
          </Form.Item>

          <Form.Item
            data-test="error-message-of-agreement-in-sign"
            name="agreement"
            valuePropName="checked"
          >
            <Checkbox data-test="sign_agreement">개인정보취급 및 서비스 이용 약관에 동의합니다.</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.signButton}
              disabled={isSigningUp}
            >
              가입하기
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default Sign;