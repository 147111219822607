import {Button, Col, Divider, Form, Input, Modal, Row, Select, Space, Spin, Typography} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";

interface BillApi {
  createBill?: (kmServiceForm: any) => Promise<void>,
  updateBill?: (objectId: String, kmServiceForm: any) => Promise<void>,
  deleteBill?: (objectId: String) => Promise<void>,
}

interface BilPanelProps {
  objectId: string;
  initialBill: any;
  isPending: boolean;
  api: BillApi;
  onCreate: () => void;
  onUpdate: () => void;
  onDelete: () => void;
  onCancel: () => void;
  statuses: any[],
  companies: any[],
}

const BilPanel = ({
                          objectId,
                          initialBill,
                          isPending,
                          api,
                          onCreate,
                          onUpdate,
                          onDelete,
                          onCancel,
                          statuses,
                          companies,
                        }: BilPanelProps) => {


  // mode
  const mode = (objectId === "new") ? "new" : "edit";
  const isEditMode = mode === 'edit';
  const isNewMode = mode === 'new';

  // form - company
  const [companyOptions, setCompanyOptions] = useState<any[]>([]);
  useEffect(() => {
    if (companies !== undefined) {
      setCompanyOptions(companies.map((e) => ({
        label: e.name,
        value: e.id,
      })));
    }
  }, [companies]);

  // form
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialBill);
  }, [form, initialBill]);

  // event
  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const safeConfirm = (title: string, content: string, confirmFunc: () => void) => {
    Modal.confirm({
      title,
      content,
      icon: <ExclamationCircleOutlined/>,
      okText: '네',
      okType: 'danger',
      cancelText: '아니오',
      onOk() {
        confirmFunc();
      },
      onCancel() {
      },
    });
  };

  const showCancelConfirm = () => {
    Modal.confirm({
      title: '작성중인 내용을 취소하겠습니까?',
      icon: <ExclamationCircleOutlined/>,
      okText: '네',
      okType: 'danger',
      cancelText: '아니오',
      onOk() {
        onCancel();
      },
      onCancel() {
      },
    });
  };

  return (
    <>
      {isEditMode && isPending ? (
        <Spin
          tip="Loading..."
          style={{position: 'fixed', top: '50%', left: '50%'}}
        ></Spin>
      ) : (
        <Col>
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => {
              safeConfirm(
                objectId === undefined ? "정산 정보을 발행합니다.." : "정산 정보 정보를 수정합니다.",
                "수정하신 내용을 저장하시겠습니까?",
                () => {
                  if (isEditMode) {
                    api.updateBill && api.updateBill(objectId, values).then(() => {
                      onUpdate();
                    });
                  } else {
                    api.createBill && api.createBill(values).then(() => {
                      onCreate();
                    })
                  }
                },
              );
            }}
            onFinishFailed={onFinishFailed}
          >

            <Form.Item
              data-test="error-message-of-company-in-bill"
              name="company"
              label="회사"
              required-mark="true"
              rules={[{required: true, message: '회사를 선택해주세요.'}]}
            >
              <Select options={companyOptions} />
            </Form.Item>

            <Form.Item
              data-test="error-message-of-name-in-bill"
              name="name"
              label="날짜"
              required-mark="true"
              rules={[{required: true, message: '날짜를 입력해주세요'}]}
            >
              <Input data-test="test_name"/>
            </Form.Item>

            <Form.Item
              data-test="error-message-of-fee-in-bill"
              name="fee"
              label="금액"
              required-mark="true"
              rules={[{required: true, message: '금액을 입력해주세요'}]}
            >
              <Input data-test="test_fee" type="number"/>
            </Form.Item>

            <Form.Item
              data-test="error-message-of-status-in-bill"
              name="status"
              label="상태"
              required-mark="true"
              rules={[{required: true, message: '상태를 선택해주세요.'}]}
            >
              <Select options={statuses} />
            </Form.Item>

            <Divider/>
            <Row justify="space-between" align="middle">
              {isEditMode && objectId !== undefined ? (
                <Typography.Text
                  data-test="btn-delete-in-knowledge-panel"
                  type="danger"
                  underline
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    safeConfirm(
                      "정산 정보 삭제",
                      "정산 정보를 삭제하시겠습니까?",
                      () => {
                        api.deleteBill && api.deleteBill(objectId).then((r) => {
                          onDelete();
                        });
                      });
                  }}
                >
                  삭제
                </Typography.Text>
              ) : (
                <div/>
              )}

              <Space>
                <Button
                  data-test="btn-cancel-in-knowledge"
                  htmlType="button"
                  onClick={() => {
                    if (isEditMode || isNewMode) {
                      showCancelConfirm();
                    } else {
                      onCancel();
                    }
                  }}
                >
                  취소
                </Button>
                <Button
                  data-test="btn-save-in-knowledge"
                  loading={isPending}
                  type="primary"
                  htmlType="submit"
                  // disabled={isReadOnly}
                >
                  저장
                </Button>
              </Space>
            </Row>
          </Form>
        </Col>
      )}
    </>
  );

}

export default BilPanel;