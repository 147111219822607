/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRequest } from '../hooks/request';
import {
  Form,
  Button,
  Radio,
  Input,
  Row,
  Col,
  Space,
  Modal,
  Select,
  Divider,
} from 'antd';
import _ from 'lodash';

import NodePreview from './NodePreview';
import RelationPreview from './RelationPreview';
import Parse from 'parse';

interface GraphItemPanelProp {
  graphData: { nodes: Node[]; edges: Edge[] };
  initialItem: any;
  kkSchemaList: KKSchemaListProp;
  onSave: (userCreatedGraphChange: Parse.Object<Parse.Attributes>) => void;
  onCancel: () => void;
}

interface Node {
  id: string;
  label: string;
  kkSchemaName: string;
  properties: { [key: string]: string };
  style: {
    icon: {
      value: string;
    };
  };
}

interface Edge {
  id: string;
  kkSchemaName: string;
  source: string;
  style: { label: { value: string; fill: string } };
  target: string;
}

interface KKSchemaProp {
  type: string;
  objectId: string;
  name: string;
  props: any[];
  labelPropName: string;
  uniqPropName: string;
}

type KKSchemaListProp = KKSchemaProp[];

interface FormDataProp {
  type: string;
  schemaName: string;
  props: any[];
  fromNodeKKSchemaName: string;
  fromNodeId: string;
  toNodeKKSchemaName: string;
  toNodeId: string;
}

const createGraphItem = ({
  formData,
  kkSchemaList,
  renderingNodesOnDisplay,
}: {
  formData: FormDataProp;
  kkSchemaList: KKSchemaListProp;
  renderingNodesOnDisplay: Node[];
}) => {
  const newUserCreatedGraphChange = new Parse.Object('UserCreatedGraphChange');
  // const target
  const fromKKSchema = _.find(
    kkSchemaList,
    (ks) => ks.name === formData.fromNodeId
  ) || { uniqPropName: '' };
  const toKKSchema = _.find(
    kkSchemaList,
    (ks) => ks.name === formData.toNodeId
  ) || { uniqPropName: '' };

  const data =
    formData.type === 'node'
      ? {
          type: 'create',
          kkSchemaName: formData.schemaName,
          data: {
            properties: {
              ...formData.props.reduce(
                (pre: any, curr: any) => ({
                  ...pre,
                  [curr.prop]: curr.value,
                }),
                {}
              ),
            },
          },
        }
      : {
          type: 'create',
          kkSchemaName: formData.schemaName,
          data: {
            from: {
              kkSchemaName: formData.fromNodeKKSchemaName,
              properties: renderingNodesOnDisplay?.find(
                (node) => node.id === formData.fromNodeId
              )?.properties,
            },
            to: {
              kkSchemaName: formData.toNodeKKSchemaName,
              properties: renderingNodesOnDisplay?.find(
                (node) => node.id === formData.toNodeId
              )?.properties,
            },
            fromNodeId: formData.fromNodeId,
            toNodeId: formData.toNodeId,
            properties: {
              ...formData.props.reduce(
                (pre: any, curr: any) => ({
                  ...pre,
                  [curr.prop]: curr.value,
                }),
                {}
              ),
            },
          },
        };
  // console.log(data);
  return newUserCreatedGraphChange.save(data);
};

const GraphItemPanel: React.FC<GraphItemPanelProp> = ({
  graphData,
  onSave,
  onCancel,
  kkSchemaList = [],
}) => {
  const [{ pending, error }, { run: runCreate }] = useRequest(createGraphItem);
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const { nodes: renderingNodesOnDisplay } = graphData;

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  // Node, Relation을 save하는 관련 함수 안에 넣기
  const showSaveInfo = () => {
    Modal.success({
      content: '저장되었습니다.',
    });
  };
  const showSaveFailInfo = (e: any) => {
    Modal.success({
      content: `${e}로 인해 저장에 실패했습니다.`,
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(formData) => {
        runCreate({ formData, kkSchemaList, renderingNodesOnDisplay })
          .then((graphChangeObj) => {
            onSave(graphChangeObj);
            form.resetFields();
            form.setFieldsValue({
              type: form.getFieldValue('type') ? 'node' : 'relation',
              props: [],
            });
            showSaveInfo();
            return graphChangeObj;
          })
          .catch((e) => {
            showSaveFailInfo(e);
            return e;
          });
      }}
      onFinishFailed={onFinishFailed}
      initialValues={{
        type: 'node',
        props: [],
      }}
    >
      <Row justify={'space-between'}>
        <Col>
          <Form.Item label="스키마 유형" name="type">
            <Radio.Group
              onChange={({ target: { value } }) => {
                form.resetFields();
                form.setFieldsValue({
                  type: value,
                });
              }}
            >
              <Radio value="node">노드</Radio>
              <Radio value="relation">릴레이션</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue, setFieldsValue, resetFields }) => {
          return (
            <>
              <Form.Item
                name="schemaName"
                rules={[
                  {
                    required: true,
                    message: '생성할 그래프 아이템의 스키마를 선택하세요',
                  },
                ]}
              >
                <Select
                  allowClear={true}
                  options={_.chain(kkSchemaList)
                    .filter(({ type }) => type === getFieldValue('type'))
                    .map(({ name }) => ({
                      value: name,
                    }))
                    .value()}
                  placeholder="스키마를 선택해주세요"
                  // showSearch
                  onChange={(v) => {
                    // currentItemSchema && v
                    const isNode = getFieldValue('type') === 'node';
                    const currentItemSchema = _.find(
                      kkSchemaList,
                      (ks) => ks.name === v
                    );
                    // resetFields(['props']);
                    if (currentItemSchema) {
                      setFieldsValue({
                        props: _.map(currentItemSchema.props, (p) => ({
                          prop: p.name,
                          value: undefined,
                        })),
                        labelPropName: currentItemSchema.labelPropName,
                        uniqPropName: currentItemSchema.uniqPropName,
                      });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label="스키마 속성"
                hidden={getFieldValue('props').length === 0}
              >
                <Form.List name="props">
                  {(fields) => (
                    <div>
                      {fields.map((field, idx) => (
                        <Row gutter={8} key={idx}>
                          <Col span={10}>
                            <Form.Item
                              {...field}
                              fieldKey={[field.fieldKey, 'prop']}
                              name={[field.name, 'prop']}
                              rules={[
                                {
                                  required: true,
                                  message: '데이터를 입력하세요',
                                },
                              ]}
                            >
                              <Input disabled={true} />
                            </Form.Item>
                          </Col>
                          <Col span={14}>
                            <Form.Item
                              {...field}
                              fieldKey={[field.fieldKey, 'value']}
                              name={[field.name, 'value']}
                              rules={[
                                {
                                  required: true,
                                  message: '속성 값을 입력하세요',
                                },
                              ]}
                            >
                              <Input placeholder="스키마 속성을 입력하세요" />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  )}
                </Form.List>
              </Form.Item>
            </>
          );
        }}
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue, setFieldsValue }) => {
          const isNodeType = getFieldValue('type') === 'node';
          const renderingKKNodeSchemasOnDisplay = _.uniqBy(
            renderingNodesOnDisplay.map(({ kkSchemaName }) => kkSchemaName),
            (kkSchemaName) => kkSchemaName
          );
          // (새로운 릴레이션 생성 UI에서) From field에 선택된 Node 타입 스키마가 더 이상 지식맵에 활성화 되어 있지 않을 때 호출되는 Validation
          if (
            getFieldValue('fromNodeKKSchemaName') &&
            !_.includes(
              renderingKKNodeSchemasOnDisplay,
              getFieldValue('fromNodeKKSchemaName')
            )
          ) {
            setFieldsValue({
              fromNodeKKSchemaName: undefined,
              fromNodeId: undefined,
            });
          }
          // (새로운 릴레이션 생성 UI에서) To field에 선택된 Node 타입 스키마가 더 이상 지식맵에 활성화 되어 있지 않을 때 호출되는 Validation
          if (
            getFieldValue('toNodeKKSchemaName') &&
            !_.includes(
              renderingKKNodeSchemasOnDisplay,
              getFieldValue('toNodeKKSchemaName')
            )
          ) {
            setFieldsValue({
              toNodeKKSchemaName: undefined,
              toNodeId: undefined,
            });
          }

          const getNodeSelectOptions = (schemaName: string) => {
            const curSchema = _.find(
              kkSchemaList,
              (sc) => sc.name === schemaName
            );
            return curSchema
              ? _.chain(renderingNodesOnDisplay)
                  .filter(
                    ({ kkSchemaName }) => kkSchemaName === schemaName //getFieldValue('fromNodeKKSchemaName')
                  )
                  .map(({ kkSchemaName, properties, id }) => {
                    const displayPropName = _.filter(
                      _.uniq([
                        curSchema?.uniqPropName,
                        curSchema?.labelPropName,
                      ]),
                      (v) => v
                    );

                    return {
                      label: _.map(
                        displayPropName,
                        (n: string) => properties[n]
                      ).join(', '),
                      value: id,
                    };
                  })
                  .sortBy(['label'])
                  .value()
              : [];
          };

          const displayingNodeSchemas = renderingKKNodeSchemasOnDisplay.map(
            (name) => ({
              value: name,
            })
          );

          const fromKKSchema = _.find(
            kkSchemaList,
            (ks) => ks.name === getFieldValue('fromNodeKKSchemaName')
          );
          const toKKSchema = _.find(
            kkSchemaList,
            (ks) => ks.name === getFieldValue('toNodeKKSchemaName')
          );

          const fromNode = _.find(
            renderingNodesOnDisplay,
            ({ id }) => id === getFieldValue('fromNodeId')
          );
          const toNode = _.find(
            renderingNodesOnDisplay,
            ({ id }) => id === getFieldValue('toNodeId')
          );

          const fromPreviewTitle = fromNode?.properties
            ? fromNode?.properties[fromKKSchema?.labelPropName || '']
            : '';
          const toPreviewTitle = toNode?.properties
            ? toNode?.properties[toKKSchema?.labelPropName || '']
            : '';

          return isNodeType ? (
            <>
              <Form.Item label="레이블" name="labelPropName">
                <Select placeholder="" disabled={true} allowClear />
              </Form.Item>
              <Form.Item label="유니크" name="uniqPropName">
                <Select placeholder="" disabled={true} allowClear />
              </Form.Item>
              <Form.Item name="preview" label="미리보기">
                <NodePreview
                  nodeTitle={
                    _.find(
                      getFieldValue('props'),
                      ({ prop }: { prop: string }) => prop === 'title'
                    )?.value
                  }
                />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                label="From"
                name="fromNodeKKSchemaName"
                rules={[
                  {
                    required: true,
                    message: 'From 노드 타입 스키마를 선택하세요',
                  },
                ]}
              >
                <Select
                  options={displayingNodeSchemas}
                  allowClear={true}
                  placeholder="노드 선택"
                  // onChange: From Node의 Schema가 재지정 되거나 Clear 될 때마다 From Node의 속성 입력 창의 Value와 Options가 초기화
                  onChange={() => {
                    setFieldsValue({ fromNodeId: undefined });
                  }}
                />
              </Form.Item>
              <Form.Item
                name="fromNodeId"
                rules={[{ required: true, message: 'From 노드를 설정하세요' }]}
              >
                <Select
                  notFoundContent="입력한 노드가 존재하지 않습니다."
                  showSearch
                  allowClear
                  showArrow={false}
                  placeholder="From에 해당하는 노드를 선택해주세요"
                  disabled={!getFieldValue('fromNodeKKSchemaName')}
                  filterOption={(input, option) =>
                    option?.props.label.includes(input)
                  }
                  options={getNodeSelectOptions(
                    getFieldValue('fromNodeKKSchemaName')
                  )}
                />
              </Form.Item>
              <Form.Item
                label="To"
                name="toNodeKKSchemaName"
                rules={[
                  {
                    required: true,
                    message: 'To 노드 타입 스키마를 선택하세요',
                  },
                ]}
              >
                <Select
                  options={displayingNodeSchemas}
                  allowClear={true}
                  placeholder="노드 선택"
                  // onChange: To Node의 Schema가 재지정 되거나 Clear 될 때마다 To Node의 속성 입력 창의 Value와 Options가 초기화
                  onChange={() => {
                    setFieldsValue({ toNodeId: undefined });
                  }}
                />
              </Form.Item>
              <Form.Item
                name="toNodeId"
                rules={[{ required: true, message: 'To 노드를 설정하세요' }]}
              >
                <Select
                  notFoundContent="입력한 노드가 존재하지 않습니다."
                  showSearch
                  allowClear
                  showArrow={false}
                  placeholder="To에 해당하는 노드를 선택해주세요"
                  disabled={!getFieldValue('toNodeKKSchemaName')}
                  filterOption={(input, option) =>
                    option?.props.label.includes(input)
                  }
                  options={getNodeSelectOptions(
                    getFieldValue('toNodeKKSchemaName')
                  )}
                />
              </Form.Item>
              <Form.Item name="preview" label="미리보기">
                <RelationPreview
                  nodeSchemaFrom={fromPreviewTitle}
                  nodeSchemaTo={toPreviewTitle}
                  relationName={
                    _.find(
                      kkSchemaList,
                      ({ objectId }) => objectId === getFieldValue('schemaName')
                    )?.name
                  }
                />
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
      <Divider />
      <Row justify="end">
        <Space>
          <Button htmlType="button" onClick={() => onCancel()}>
            취소
          </Button>
          <Button type="primary" htmlType="submit" loading={pending}>
            저장
          </Button>
        </Space>
      </Row>
    </Form>
  );
};

export default GraphItemPanel;
