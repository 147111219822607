import {Button, Drawer, Layout, message, Row, Space, Table, Typography} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import dayjs from "dayjs";
import TenantManagerPanel from "../components/TenantManagerPanel";
import {useTenantState} from "../hooks";

const {Text} = Typography;

const statuses = [
  {
    label: '인증대기',
    value: 1,
  },
  {
    label: '인증',
    value: 2,
  },
  {
    label: '정지',
    value: 3,
  },
  {
    label: '탈퇴신청',
    value: 4,
  },
]


const TenantManager = () => {
  const history = useHistory();
  const {objectId} = useParams() as {
    objectId: string
  };

  // api
  const [
    {
      listData = [],
      listTotal,
      getData,
      isListing,
      isCreating,
      isGetting,
      isUpdating,
      isDeleting,
    },
    {
      listRun,
      createRun,
      getRun,
      updateRun,
      deleteRun,
    },
  ] = useTenantState();

  // refresh
  const refreshPage = useCallback(() => {
    listRun && listRun.run();
    if (objectId !== "new" && objectId !== undefined) {
      getRun && getRun.run(objectId);
    }
  }, [objectId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refreshPage();
  }, [objectId]); // eslint-disable-line react-hooks/exhaustive-deps

  // render
  const [orderInfo] = useState({
    orderDirection: 'descend',
    selectedOrderField: 'updatedAt',
  });
  const {orderDirection, selectedOrderField} = orderInfo;
  const sorterTooltipMSG = orderDirection === 'ascend' ? '내림차순으로 정렬하기' : '오름차순으로 정렬하기';

  const columns = [
    {
      title: '회사명',
      dataIndex: 'name',
      key: 'name',
      render: (v: string) => v,
      showSorterTooltip: {
        title: selectedOrderField === 'name' ? sorterTooltipMSG : '오름차순으로 정렬하기',
      },
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: '회사아이디',
      dataIndex: 'domain',
      key: 'domain',
      render: (v: string) => v,
      showSorterTooltip: {
        title: selectedOrderField === 'domain' ? sorterTooltipMSG : '오름차순으로 정렬하기',
      },
      sorter: (a: any, b: any) => a.domain.localeCompare(b.domain),
    },
    {
      title: '가입자 이름',
      dataIndex: 'userName',
      key: 'userName',
      render: (v: string) => v,
      showSorterTooltip: {
        title: selectedOrderField === 'userName' ? sorterTooltipMSG : '오름차순으로 정렬하기',
      },
      sorter: (a: any, b: any) => a.userName.localeCompare(b.userName),
    },
    {
      title: '가입자 이메일',
      dataIndex: 'email',
      key: 'email',
      render: (v: string) => v,
      showSorterTooltip: {
        title: selectedOrderField === 'email' ? sorterTooltipMSG : '오름차순으로 정렬하기',
      },
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
    },
    {
      title: '가입자 전화번호',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      render: (v: string) => v,
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      render: (v: number) => {
        let label = ""
        const target = statuses.find((e) => e.value === v);
        if (target !== undefined) {
          label = target.label;
        }
        return (
          <Text>{label}</Text>
        )
      },
      width: 100,
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (v: string) => v && dayjs(v).format('L LT'),
      width: 200,
    },
    {
      title: '',
      // dataIndex: 'dashboard',
      key: 'dashboard',
      render: (_: any, {objectId}: any) => {
        return (
            <Space>
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  history.push(`/tenantManager/${objectId}/usage`);
                }}>
                대시보드
              </Button>
            </Space>
        )
      },
      width: 300,
    },
  ];

  // event
  const onDrawerClose = () => {
    history.replace('/tenantManager');
  };

  return (
    <Layout style={{padding: '24px', backgroundColor: 'white'}}>
      <Space direction="vertical">
        <Row justify="space-between">
          <Space align="center">
            <Text style={{margin: 0}}>{`총 ${listTotal}개`}</Text>
          </Space>
          <div>
            <Button
              type="default"
              onClick={() => {
                history.replace('/tenantManager/new');
              }}
            >
              회사 추가
            </Button>
          </div>
        </Row>
        <Table
          sortDirections={['ascend', 'descend', 'ascend']}
          rowKey={'objectId'}
          rowSelection={{
            selectedRowKeys: [objectId],
            columnWidth: 0, // Set the width to 0
            renderCell: () => '', // Render nothing inside
            hideSelectAll: true,
            type: 'radio',
          }}
          columns={columns}
          dataSource={listData}
          loading={isListing}
          onRow={(record) => {
            return {
              onClick: () => {
                history.push(`/tenantManager/${record.objectId}`);
              },
            };
          }}
        >
        </Table>
      </Space>

      <Drawer
        title={objectId !== 'new' ? '회사 수정' : '회사 추가'}
        placement="right"
        onClose={() => {
          onDrawerClose();
        }}
        visible={objectId !== undefined}
        width={350}
        mask={false}
        closable
        destroyOnClose
      >
        <TenantManagerPanel
          objectId={objectId}
          isPending={isCreating || isGetting || isUpdating || isDeleting}
          initialTenant={getData}
          api={{
            createTenant: createRun?.run,
            updateTenant: updateRun?.run,
            deleteTenant: deleteRun?.run,
          }}
          onCreate={() => {
            message.info('회사를 추가하였습니다.');
            history.push(`/tenantManager`);
            onDrawerClose();
          }}
          onUpdate={() => {
            message.info('회사 정보를 수정하였습니다.');
            history.push(`/userManager`);
            onDrawerClose();
          }}
          onDelete={() => {
            message.info('회사를 삭제하였습니다.');
            history.push(`/tenantManager`);
            onDrawerClose();
          }}
          onCancel={() => {
            history.push(`/tenantManager`);
            onDrawerClose();
          }}
          statuses={statuses}
        />

      </Drawer>
    </Layout>
  );
}

export default TenantManager;