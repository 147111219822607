import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';
import styles from './ImporterItemList.module.css';
import {useState, useEffect, useRef} from 'react';
import {
    useCompanyState,
    useImporterItemState,
    useKMServiceState, useSchemaState,
} from '../hooks';
import {
    replaceToBoldWithCaseInsensitive,
    urlRewriteToShortenFilename,
} from '../helper';
import {CycleWarningDetail} from '../components/CycleWarningDetail';
import {
    Tag,
    Input,
    Form,
    Table,
    Row,
    Col,
    Button,
    Space,
    Typography,
    Tooltip,
    Spin,
    Modal,
    Layout,
    Pagination,
} from 'antd';
import Parse from 'parse';
import dayjs from 'dayjs';
import {
    RedoOutlined,
    LoadingOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import {removeUntilFirstUnderBar} from '../helper';
// import InnerContentWithKKBreadcrumb from '../components/InnerContentWithKKBreadcrumb';
import ImporterUploadPanel from '../components/ImporterUploadPanel';
import {useAuth} from "../hooks/auth";

const {Sider} = Layout;

const {Text} = Typography;
const {confirm, warning} = Modal;

const ImporterItemList = () => {

    const {isKKMaster} = useAuth();

    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [iptItemSearchKeyword, setIptItemSearchKeyword] = useState(null);

    const [companies, setCompanies] = useState([]);
    const [kmServices, setKMServices] = useState([]);
    const [schemas, setSchemas] = useState([]);

    // api
    const [{}, {listAll: listCompanyAll}] = useCompanyState(); // eslint-disable-line no-empty-pattern

    const [{}, {listAll: listKMServiceAll}] = useKMServiceState(); // eslint-disable-line no-empty-pattern

    const [{}, {listAll: listSchemaAll}] = useSchemaState(); // eslint-disable-line no-empty-pattern

    const [
        {
            listData = [],
            listTotal,
            isListing,
            isSaving,
            runImporterJobState,
        },
        {
            listRun,
            saveRun,
            runImporterJobRun,
        },
    ] = useImporterItemState();

    // refresh
    const listKMServiceCall = (company) => {
        listKMServiceAll && listKMServiceAll(company).then((res) => {
            setKMServices(res.results);
        })
    }

    const listSchemaCall = (kmService) => {
        listSchemaAll && listSchemaAll(kmService).then((res) => {
            setSchemas(res.results);
        })
    }

    const refreshPage = () => {
        listRun && listRun.run({
            pageNum,
            pageSize,
            keyword: iptItemSearchKeyword,
        });

        listCompanyAll && listCompanyAll().then((res) => {
            setCompanies(res.results);
        })

        listKMServiceCall();
        listSchemaCall();
    };

    useEffect(() => {
        refreshPage();
    }, [pageNum, pageSize, iptItemSearchKeyword]); // eslint-disable-line react-hooks/exhaustive-deps


    // render
    const [orderInfo, setOrderInfo] = useState({
        orderDirection: 'descend',
        selectedOrderField: 'createdAt',
    });
    const {orderDirection, selectedOrderField} = orderInfo;
    const sorterTooltipMSG = orderDirection === 'ascend' ? '내림차순으로 정렬하기' : '오름차순으로 정렬하기';

    // form
    const searchInputRef = useRef(null);
    const [form] = Form.useForm();

    const [selectedRows, setSelectedRows] = useState([]);

    const [columns, setColumns] = useState([]);
    useEffect(() => {
        let c = [
            {
                title: '지식맵',
                dataIndex: 'domain',
                key: 'domain',
                render: (v) => v,
                sorter: (a, b) => a.domain.localeCompare(b.domain),
                showSorterTooltip: {
                    title: selectedOrderField === 'schemaType' ? sorterTooltipMSG : '오름차순으로 정렬하기',
                },
            },
            {
                title: '스키마 유형',
                dataIndex: 'selectedKKSchema',
                key: 'schemaType',
                render: (schema) => {
                    if (!schema) return '-';
                    return schema.get('type') === 'relation' ? '릴레이션' : '노드';
                    // const matchingItem = v[0];
                    // const label = { node: '노드', relation: '릴레이션' };
                    // return matchingItem ? label[matchingItem.schemaType] : '-';
                },
                sorter: (a, b) => a.selectedKKSchema?.get('type').localeCompare(b.selectedKKSchema?.get('type')),
                showSorterTooltip: {
                    title: selectedOrderField === 'schemaType' ? sorterTooltipMSG : '오름차순으로 정렬하기',
                },
            },
            {
                title: '스키마 이름',
                dataIndex: 'selectedKKSchema',
                key: 'schemaName',
                showSorterTooltip: {
                    title:
                        selectedOrderField === 'schemaName'
                            ? sorterTooltipMSG
                            : '오름차순으로 정렬하기',
                },
                render: (schema, record) => {
                    if (!schema) return '삭제된 스키마';
                    const replaceToBold = (text) =>
                        replaceToBoldWithCaseInsensitive(text, iptItemSearchKeyword);

                    const subTitle = (() => {
                        if (schema.get('type') !== 'relation') return null;

                        const text = [
                            '(From: ',
                            record.fromKKSchema
                                ? replaceToBold(record.fromKKSchema.get('name'))
                                : '삭제된 스키마',
                            ' To: ',
                            record.toKKSchema
                                ? replaceToBold(record.toKKSchema.get('name'))
                                : '삭제된 스키마',
                            ')',
                        ].join('');
                        return <Text type="secondary">{ReactHtmlParser(text)}</Text>;
                    })();

                    const containedBoldSchemaName = replaceToBold(schema.get("name"));

                    return (
                        <>
                            <Text>{ReactHtmlParser(containedBoldSchemaName)}</Text>
                            <br/>
                            {subTitle}
                        </>
                    );
                },
                sorter: (a, b) =>
                    a.selectedKKSchema?.get('name').localeCompare(b.selectedKKSchema?.get('name')),
            },

            {
                title: '업로드 생성일시',
                dataIndex: 'createdAt',
                showSorterTooltip: {
                    title:
                        selectedOrderField === 'createdAt'
                            ? sorterTooltipMSG
                            : '오름차순으로 정렬하기',
                },
                render: (v) => dayjs(v).format('LLL'),
                defaultSortOrder: 'descend',
                sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
            },
            {
                title: '생성 계정',
                showSorterTooltip: {
                    title: selectedOrderField === 'author' ? sorterTooltipMSG : '오름차순으로 정렬하기',
                },
                dataIndex: 'author',
                render: (v) => (v ? v.get("username") : '-'),
                sorter: (a, b) => a.author.get('username').localeCompare(b.author.get('username')),
            },
            {
                title: '지식맵 생성',
                dataIndex: 'status',
                sorter: (a, b) => a.status.localeCompare(b.status),
                showSorterTooltip: {
                    title:
                        selectedOrderField === 'status'
                            ? sorterTooltipMSG
                            : '오름차순으로 정렬하기',
                },
                render: (v, record) => {
                    if (
                        !record.selectedKKSchema ||
                        (record.selectedKKSchema.type === 'relation' &&
                            (!record.fromKKSchema || !record.toKKSchema))
                    ) {
                        return <span>-</span>;
                    } else if (v === 'default') {
                        return (
                            <Button
                                disabled={runImporterJobState.pending}
                                loading={
                                    runImporterJobState.pending &&
                                    runImporterJobState.runArgs === record.objectId
                                }
                                onClick={() => {
                                    runImporterJobRun.run(record.objectId).then(() => {
                                        refreshPage();
                                    });
                                }}
                            >
                                지식맵 생성하기
                            </Button>
                        );
                    } else if (v === 'fulfilled') {
                        return <span>완료 (ID: {record.objectId})</span>;
                    } else if (v === 'invalid') {
                        return <span>유효하지 않은 csv 형식</span>;
                    } else if (v === 'pending') {
                        return (
                            <Row align="middle">
                                <Spin
                                    indicator={<LoadingOutlined style={{fontSize: 12}} spin/>}
                                />
                                &nbsp; 생성중
                            </Row>
                        );
                    } else if (v === 'rejected') {
                        return <span>생성 중 오류</span>;
                    } else if (v === 'csvFailed') {
                        return <span>유효하지 않은 csv 형식</span>;
                    }
                },
            },
            {
                title: '지식맵 생성일시',
                showSorterTooltip: {
                    title:
                        selectedOrderField === 'knowledMapCreatedAt'
                            ? sorterTooltipMSG
                            : '오름차순으로 정렬하기',
                },
                dataIndex: 'status',
                key: 'knowledMapCreatedAt',
                sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
                render: (v, record) => {
                    return v === 'fulfilled' ? dayjs(record.updatedAt).format('LLL') : '-';
                },
            },
            {
                // TODO: 정렬 기준이 정해지면 sorter prop 추가하기
                title: '지식맵 생성 현황',
                dataIndex: 'numberOfItemsOfFile',
                render: (v, record) => {
                    if (!record.selectedKKSchema) return '-';
                    // {/* {record.selectedKKSchema.type === 'relation' ? '릴레이션' : '노드'} */}

                    return v ? (
                        <>
                            {record.numberOfProcessedItems} /{' '}
                            <Tooltip
                                title={
                                    record.sampleData
                                        ? JSON.stringify(record.sampleData[0])
                                        : '데이터 없음'
                                }
                            >
                                <span>{v}</span>
                            </Tooltip>
                        </>
                    ) : (
                        <span> - </span>
                    );
                },
            },
            {
                title: '지식맵 데이터 검증',
                sorter: (a, b) => a.status.localeCompare(b.status),
                showSorterTooltip: {
                    title:
                        selectedOrderField === 'status'
                            ? sorterTooltipMSG
                            : '오름차순으로 정렬하기',
                },
                dataIndex: 'status',
                render: (v, record) => {
                    const st = record.statusDetail;
                    if (st !== undefined) {
                        const msg =
                            st?.failureType === 'cycleDetected' ? '사이클 확인' : '데이터 오류';
                        return (
                            <Button
                                style={{
                                    background: '#FFFFFF',
                                    boxSizing: 'border-box',
                                    borderRadius: '20px',
                                }}
                                onClick={() => showStatusDetail(record)}
                            >
                                {msg}
                            </Button>
                        );
                    } else {
                        const stateMsg = {
                            fulfilled: '데이터 오류 없음',
                            invalid: '데이터 오류',
                            csvFailed: '데이터 오류',
                        };
                        let msg = stateMsg[v];
                        if (msg === undefined) msg = '';
                        return msg;
                    }
                },
            },
            // {
            //   title: 'matchingInfo',
            //   dataIndex: 'matchingInfo',
            //   render: (v) => {
            //     return _.map(_.groupBy(v, 'schemaType'), (v, k) => {
            //       return `${k} : ${v.map((i) => i.schemaName)}`;
            //     }).join(', ');
            //   },
            // },
        ];

        if (isKKMaster) {
            c = [
                {
                    title: "회사",
                    dataIndex: 'company',
                    key: 'company',
                    render: (v) => v,
                    showSorterTooltip: {
                        title: selectedOrderField === 'company' ? sorterTooltipMSG : '오름차순으로 정렬하기',
                    },
                    sorter: (a, b) => a.company.localeCompare(b.company),
                },
                ...c];
        }
        // @ts-ignore
        setColumns(c);
    }, [isKKMaster]); // eslint-disable-line react-hooks/exhaustive-deps

    const deleteButtonConfirm = () => {
        confirm({
            title: '정말 삭제하시겠습니까?',
            okText: '삭제',
            okType: 'danger',
            cancelText: '취소',
            onOk: async () => {
                const deleteResult = await Promise.all(
                    selectedRows.map((selectedRow) => {
                        const deleteIptItem = new Parse.Object('ImporterItem').set(
                            'objectId',
                            selectedRow.objectId
                        );
                        return deleteIptItem
                            .destroy()
                            .then((res) => res.toJSON())
                            .catch((e) => {
                                console.error('error: ', e);
                                return e;
                            });
                    })
                );
                const failedDeleteIptItemList = selectedRows.filter(
                    (selectedRow) =>
                        !deleteResult.find((dr) => dr?.objectId === selectedRow.objectId)
                );
                setSelectedRows(failedDeleteIptItemList);
                !_.isEmpty(failedDeleteIptItemList) &&
                alertDeleteFail(failedDeleteIptItemList);
                refreshPage();
            },
        });
    };

    const alertDeleteFail = (failedDeleteIptItemList) => {
        warning({
            title: '삭제 실패한 Importer Item:',
            content: `${failedDeleteIptItemList
                .map(
                    ({selectedKKSchema: {name}, objectId}) => `${name}(${objectId})`
                )
                .join(',\n ')}`,
            okText: '확인',
        });
    };

    const CsvFailedDetail = (record) => {
        const statusDetail = record.statusDetail;
        const ft = statusDetail.failureType;
        const msg = ['업로드된 CSV파일에 문제가 있어 지식맵을 생성할 수 없습니다.'];
        if (ft === 'invalidUtf8') {
            msg.push('utf-8 포맷으로 인코딩된 CSV 파일인지 확인해 주세요.');
        } else if (ft === 'schemaMismatched') {
            msg.push('스키마와 업로드 데이터의 유형이 일치하는지 확인해 주세요.');
        } else if (ft === 'columnMismatched') {
            msg.push('CSV의 컬럼 구성과 스키마의 속성이 일치하는지 확인해 주세요.');
        } else if (ft === 'emptyData') {
            msg.push('비어있는 데이터 입니다.');
        } else if (ft === 'castingFailed') {
            msg.splice(0, -1);
            msg.push('등록된 데이터로 일부 지식맵 생성이 실패하였습니다.');
            msg.push('');

            const fi = statusDetail.failedItems;
            const l = Math.min(fi?.length || 0, 5);
            fi.find((item, idx) => {
                msg.push(`line: ${item.line}, ${JSON.stringify(item.item)}`);
                return idx >= l - 1;
            });

            if (l < fi.length) {
                msg.push(`... 외 ${fi.length - l} 개의 데이터 입력을 실패했습니다.`);
            }

            if (record.failedInfoFile !== undefined) {
                const simpleFileName = removeUntilFirstUnderBar(
                    record.failedInfoFile.name
                );
                // const urlRewritten = record.file.url;
                const urlRewritten = urlRewriteToShortenFilename(
                    record.failedInfoFile.url
                );
                return (
                    <>
                        <div dangerouslySetInnerHTML={{__html: `${msg.join('<br/>')}`}}/>
                        <div>
                            <br/>
                            {'실패 데이터 파일: '}
                            <a href={urlRewritten}>{simpleFileName}</a>
                        </div>
                    </>
                );
            }
        }

        return <div dangerouslySetInnerHTML={{__html: `${msg.join('<br/>')}`}}/>;
    };

    const StatusDetail = (record) => {
        if (record.statusDetail?.failureType === 'cycleDetected') {
            return CycleWarningDetail(
                record.statusDetail,
                record.failedInfoFile,
                '업로드 후 그래프에 사이클이 발견되었습니다.'
            );
        }
        return CsvFailedDetail(record);
    };

    const showStatusDetail = (record) => {
        confirm({
            icon: null,
            content: StatusDetail(record),
            okText: '확인',
            cancelButtonProps: {style: {display: 'none'}},
        });
    };

    return (
        <Layout style={{padding: '24px', backgroundColor: 'white'}}>
            <Space direction="vertical" style={{width: "100%"}}>
                <Row justify="space-between">
                    <Space align="center">
                        <Button
                            icon={<RedoOutlined/>}
                            loading={isListing}
                            onClick={refreshPage}
                            size={'small'}
                        />

                        {iptItemSearchKeyword ? (
                            <>
                                <Text style={{margin: 0}}>{`검색 결과: ${listTotal}건`}</Text>
                                <Tag
                                    closable
                                    onClose={() => {
                                        setIptItemSearchKeyword(null);
                                        setPageNum(1);
                                    }}
                                >
                                    {iptItemSearchKeyword}
                                </Tag>
                            </>
                        ) : (
                            <Text
                                data-test="importer_item_count"
                                style={{margin: 0}}
                            >{`총 ${listTotal}개`}</Text>
                        )}
                    </Space>
                    {/* <Button type="primary">업로드</Button> */}
                    <Button
                        type="danger"
                        onClick={deleteButtonConfirm}
                        disabled={selectedRows.length === 0}
                        data-test="delete-button"
                    >
                        <DeleteOutlined/>
                        삭제
                    </Button>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table
                            onChange={(pagination, filters, sorter) => {
                                const {order, columnKey, field} = sorter;
                                setOrderInfo({
                                    orderDirection: order,
                                    selectedOrderField: columnKey || field,
                                });
                            }}
                            sortDirections={['ascend', 'descend', 'ascend']}
                            rowSelection={{
                                type: 'radio',
                                onChange: () => {
                                },
                                onSelect: (record, selected, selectedRows) => {
                                    setSelectedRows(selectedRows.filter((row) => row));
                                },
                                onSelectAll: (selected, selectedRows, changeRows) => {
                                    setSelectedRows(selectedRows.filter((row) => row));
                                },
                                getCheckboxProps: (record) => {
                                    // return {
                                    //     disabled: record?.status === 'pending' || (auth.isCurriculumMaster
                                    //         ? false
                                    //         : auth.user?.get('username') !== record.author?.username),
                                    // };
                                    return {
                                        disabled: false,
                                    }
                                },
                            }}
                            rowKey={'objectId'}
                            columns={columns}
                            dataSource={listData}
                            loading={isListing}
                            // pagination={{
                            //   total: count,
                            //   showTotal: (total, range) => `총 ${total} 개`,
                            // }}
                            pagination={{
                                position: ['none', 'none'],
                                current: pageNum,
                                pageSize,
                            }}
                            expandable={{
                                expandedRowRender: (record) => {
                                    const simpleFileName = removeUntilFirstUnderBar(
                                        record.file.name()
                                    );
                                    const urlRewritten = urlRewriteToShortenFilename(
                                        record.file.url()
                                    );
                                    return <a href={urlRewritten}>{simpleFileName}</a>;
                                },
                            }}
                        />
                    </Col>
                </Row>
                <Row justify="end">
                    <Space
                        size="large"
                        align="center"
                        className={styles.tableControllerContainer}
                    >
                        <Form form={form} initialValues={{schemaSearchInput: ''}}>
                            <Form.Item name="schemaSearchInput" noStyle>
                                <Input.Search
                                    className={styles.searchInput}
                                    placeholder="임포터 아이템 검색"
                                    ref={searchInputRef}
                                    loading={isListing}
                                    onSearch={(enterText) => {
                                        enterText === ''
                                            ? (() => {
                                                setIptItemSearchKeyword(null);
                                                setPageNum(1);
                                            })()
                                            : (() => {
                                                setIptItemSearchKeyword(enterText);
                                                setPageNum(1);
                                            })();
                                        form.resetFields();
                                        // setTimeout을 사용한 이유:
                                        // 사용하지 않으면 focus된다하더라도 onSearch가 호출 종료된 이후에 바로 blur되기 때문입니다.
                                        // setTimeout을 통해 onSearch 함수의 호출이 종료된 이후에
                                        // focus를 호출하기 위함입니다.
                                        setTimeout(
                                            () =>
                                                searchInputRef.current.focus({
                                                    cursor: 'first',
                                                }),
                                            0
                                        );
                                    }}
                                />
                            </Form.Item>
                        </Form>
                        <Pagination
                            current={pageNum}
                            defaultPageSize={pageSize}
                            total={listTotal}
                            onChange={(inputPageNum, inputPageSize) => {
                                setPageNum(inputPageNum);
                                setPageSize(inputPageSize);
                            }}
                        />
                    </Space>
                </Row>
            </Space>
            {/* </InnerContentWithKKBreadcrumb> */}
            <Sider width="350px" style={{backgroundColor: '#fff', padding: '20px'}}>
                <ImporterUploadPanel
                    isPending={isSaving}
                    api={{
                        saveImporterItem: saveRun?.run,
                        listKMServiceCall,
                        listSchemaCall,
                    }}
                    onSave={() => {
                        setPageNum(1);
                        refreshPage();

                        // if (pageNum === 1) {
                        //     //pageNum이 달라지지 않으므로 강제 새로고침
                        // }
                    }}
                    onCancel={() => {

                    }}
                    companies={companies}
                    kmServices={kmServices}
                    schemas={schemas}
                />
            </Sider>
        </Layout>
    );
};

export default ImporterItemList;

// const expandedRowRender = (record) => {
//   return (
//     <>
//       <code>
//         {`MATCH (n {_importJobInfoId:"${record.objectId}"}) RETURN n LIMIT 50`}
//       </code>
//       <br />
//       <code>
//         {`MATCH p=()-[r:PREREQUISITE {_importJobInfoId:"${record.objectId}"}]->() RETURN p LIMIT 50`}
//       </code>
//       <br />
//       <br />
//       <code>
//         - 노드 삭제 (연결 relation 포함) <br />
//         {`MATCH (n {_importJobInfoId:"${record.objectId}"}) DETACH_DELETE`}
//       </code>
//       <br />
//       <code>
//         - relation만 삭제 <br />
//         {`MATCH p=()-[r:PREREQUISITE {_importJobInfoId:"${record.objectId}"}]->() DETACH DELETE r`}
//       </code>
//       <pre style={{ margin: 0, fontSize: 8 }}>
//         {JSON.stringify(record.matchingInfo, null, 1)}
//       </pre>
//     </>
//   );
// };
