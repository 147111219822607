import {Button, Drawer, Layout, message, Row, Space, Table, Tag, Typography} from "antd";
import {useState, useEffect, useCallback} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useCompanyState, useUserState} from "../hooks";
import dayjs from "dayjs";
import UserManagerPanel from "../components/UserManagerPanel";
import {useProvideAuth} from "../hooks/auth";

const {Text} = Typography;

const roles = [
  {
    label: '도메인 관리자',
    value: "domain-master",
    extra: "도메인 관리자는 사용자,서비스,정산등 모든 권한을 가지고 있습니다.",
  },
  {
    label: '서비스 관리자',
    value: "curr-master",
    extra: "서비스 관리자는 서비스의 수정 권한을 가지고 있습니다.",
  },
  {
    label: '서비스 뷰어',
    value: "con-master",
    extra: "서비스 뷰어는 서비스의 수정 권한이 없습니다.",
  },
]


const UserManager = () => {
  const history = useHistory();
  const {objectId} = useParams() as {
    objectId: string
  };

  const {isKKMaster, logOut} = useProvideAuth();

  const [companies, setCompanies] = useState();

  // api
  const [{}, {listAll: listCompanyAll}] = useCompanyState(); // eslint-disable-line no-empty-pattern

  const [
    {
      listData = [],
      listTotal,
      getData,
      isListing,
      isCreating,
      isGetting,
      isUpdating,
      isDeleting,
    },
    {
      listRun,
      createRun,
      getRun,
      updateRun,
      deleteRun,
    },
  ] = useUserState();
  // refresh
  const refreshPage = useCallback(() => {
    listRun && listRun.run();

    if (objectId !== "new") {
      getRun && getRun.run(objectId);
    }

    if (objectId !== undefined) {
      listCompanyAll && listCompanyAll().then((res: any) => {
        setCompanies(res.results);
      });
    }

  }, [objectId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refreshPage();
  }, [objectId]); // eslint-disable-line react-hooks/exhaustive-deps

  // render
  const [orderInfo] = useState({
    orderDirection: 'descend',
    selectedOrderField: 'updatedAt',
  });
  const {orderDirection, selectedOrderField} = orderInfo;
  const sorterTooltipMSG = orderDirection === 'ascend' ? '내림차순으로 정렬하기' : '오름차순으로 정렬하기';

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    let c = [
      {
        title: '이름',
        dataIndex: 'name',
        key: 'name',
        render: (v: string) => v,
        showSorterTooltip: {
          title: selectedOrderField === 'name' ? sorterTooltipMSG : '오름차순으로 정렬하기',
        },
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      },
      {
        title: '이메일',
        dataIndex: 'email',
        key: 'email',
        render: (v: string) => v,
        showSorterTooltip: {
          title: selectedOrderField === 'email' ? sorterTooltipMSG : '오름차순으로 정렬하기',
        },
        sorter: (a: any, b: any) => a.email.localeCompare(b.email),
      },
      {
        title: '권한',
        dataIndex: 'roles',
        key: 'roles',
        render: (v: string[], {owner}: any) => (
          <Space>
            {(v.indexOf("kk-master") !== -1) && <Tag key={'tag-kk'}>KK 관리자</Tag>}
            {v.map((r, idx) => {
              const target = roles.find((e) => e.value === r);
              if (target === undefined) {
                return (<></>);
              }

              return <Tag key={`tag-${idx}`}>{target.label}</Tag>
            })}
            {owner === true ? (<Tag key={`tag-owner`}>소유자</Tag>) : (<></>)}
          </Space>
        ),
      },
      {
        title: '생성일',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (v: string) => v && dayjs(v).format('L LT'),
        width: 200,
      },
    ];

    if (isKKMaster) {
      c = [
        {
          title: "회사",
          dataIndex: 'company',
          key: 'company',
          render: (v: string) => v,
          showSorterTooltip: {
            title: selectedOrderField === 'company' ? sorterTooltipMSG : '오름차순으로 정렬하기',
          },
          sorter: (a: any, b: any) => a.company.localeCompare(b.company),
        },
        ...c];
    }
    // @ts-ignore
    setColumns(c);
  }, [isKKMaster]); // eslint-disable-line react-hooks/exhaustive-deps

  // event
  const onDrawerClose = () => {
    history.replace('/userManager');
  };

  return (
    <Layout style={{padding: '24px', backgroundColor: 'white'}}>
      <Space direction="vertical">
        <Row justify="space-between">
          <Space align="center">
            <Text style={{margin: 0}}>{`총 ${listTotal}개`}</Text>
          </Space>
          <div>
            <Button
              type="default"
              onClick={() => {
                history.replace('/userManager/new');
              }}
            >
              사용자 추가
            </Button>
          </div>
        </Row>
        <Table
          rowKey={'objectId'}
          rowSelection={{
            selectedRowKeys: [objectId],
            columnWidth: 0, // Set the width to 0
            renderCell: () => '', // Render nothing inside
            hideSelectAll: true,
            type: 'radio',
          }}
          columns={columns}
          dataSource={listData}
          loading={isListing}
          onRow={(record) => {
            return {
              onClick: () => {
                // @ts-ignore
                history.push(`/userManager/${record.objectId}`);
              },
            };
          }}
        >

        </Table>
      </Space>

      <Drawer
        title={objectId !== 'new' ? '사용자 수정' : '사용자 추가'}
        placement="right"
        onClose={() => {
          onDrawerClose();
        }}
        visible={objectId !== undefined}
        width={350}
        mask={false}
        closable
        destroyOnClose
      >
        <UserManagerPanel
          objectId={objectId}
          initialUser={getData}
          isPending={isGetting || isCreating || isUpdating || isDeleting}
          api={{
            createUser: createRun?.run,
            updateUser: updateRun?.run,
            deleteUser: deleteRun?.run,
          }}
          onCreate={() => {
            message.info('사용자를 추가하였습니다.');
            history.push(`/userManager`);
            onDrawerClose();
          }}
          onUpdate={(logout) => {
            console.log(logout);

            if (logout) {
              message.info('로그인 정보가 변경되었습니다.\n다시 로그인해주세요.');
              logOut && logOut().then(() => {
                history.push(`/login`);
              })
            } else {
              message.info('사용자 정보를 수정하였습니다.');
              history.push(`/userManager`);
              onDrawerClose();
            }
          }}
          onDelete={() => {
            message.info('사용자를 삭제하였습니다.');
            history.push(`/userManager`);
            onDrawerClose();
          }}
          onCancel={() => {
            history.push(`/userManager`);
            onDrawerClose();
          }}
          roles={roles}
          companies={companies ?? []}
        />
      </Drawer>
    </Layout>
  );
}

export default UserManager;