import { useRequest } from './request';
import Parse from 'parse';

export const useQueryDBConnectionRequest = () => {
  return useRequest(() => {
    const query = new Parse.Query('DBConnectionInfo');
    return (
      query
        .withCount()
        .include('author')
        .addDescending('updatedAt')
        // @ts-ignore
        .find({ json: true })
        // @ts-ignore
        .then(({ results: list, count }) => {
          return {
            list,
            count,
          };
        })
    );
  });
};

export const useSaveDBConnectionRequest = () => {
  return useRequest((params) => {
    const DBInfo = Parse.Object.extend('DBConnectionInfo');
    const newDBInfo = new DBInfo();
    return newDBInfo.save(params);
  });
};

export const useSaveImportJobInfoRequest = () => {
  return useRequest((params) => {
    const ImportJobInfo = Parse.Object.extend('ImportJobInfo');
    const importJobInfo = new ImportJobInfo();
    return importJobInfo.save(params);
  });
};

// export const useNewDBConnectionInfo = () => {
//   const [isPending, setIsPending] = useState(false);
//   const [data] = useState(false);
//   const [error, setError] = useState();

//   const save = (data) => {
//     const Schema = Parse.Object.extend('DBConnectionInfo');
//     const schema = new Schema();
//     schema.set('provider', data.provider);
//     schema.set('service', data.service);
//     schema.set('name', data.fileUploader.file.name);

//     const res = schema.save();
//     setIsPending(true);

//     return res
//       .catch((error) => {
//         console.log(error);
//         setError(error);
//       })
//       .finally(() => {
//         setIsPending(false);
//       });
//   };

//   return [{ data, error, isPending }, { save }];
// };
