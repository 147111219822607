/* eslint-disable no-template-curly-in-string */
import { FC, Fragment, useCallback } from 'react';
import { Form, Button, Modal, Input } from 'antd';
import { ModalMode, AclData } from '../hooks/authType';

interface AuthAclModalProps {
  mode: ModalMode;
  close: () => void;
  addAcl: (data: AclData) => Promise<boolean>;
  updateAcl: (data: AclData) => Promise<boolean>;
  hasAcl: (acl_id: string) => Promise<boolean>;
  acl: AclData;
}

export const AuthCompanyModal: FC<AuthAclModalProps> = ({
  mode,
  close,
  addAcl,
  updateAcl,
  hasAcl,
  acl,
}) => {
  const [form] = Form.useForm();
  // const { Text } = Typography;

  const edit_mode = mode === 'edit';

  const validateAclId = useCallback(async (_, value) => {
    return Promise.resolve();
  }, []);

  const handleCancel = () => {
    form.resetFields();
    close();
  };

  const handleOk = () => {
    form.submit();
  };

  const onFinish = (values: any) => {
    // console.log('acl values (onFinish): ', values);
    const { id, acl_info } = values;
    const flat_acl = { id, acl_info };
    if (edit_mode) {
      updateAcl(flat_acl).then((updated) => {
        if (updated) {
          close();
        } else {
          alert('failed to update acl');
        }
      });
      return;
    }
    hasAcl(id)
      .then((exist_id) => {
        if (exist_id) {
          form.setFields([
            {
              name: 'id',
              errors: ['사용중인 ID입니다.'],
            },
          ]);
          return;
        }

        addAcl(flat_acl).then((saved) => {
          if (saved) {
            close();
          } else {
            alert('failed to save acl.');
          }
        });
      })
      .catch((err) => {
        console.error('failed to save acl', err);
        alert('failed to save acl.');
      });
  };

  // const onChange = (value: any) => {
  //   console.log('acl values (onChange): ', value);
  // };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      string: '${label} is not a valid string!',
    },
    string: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const initialValues = { ...(edit_mode && acl) };

  return (
    <Fragment>
      <Modal
        key={'acl_modal'}
        visible={true}
        title={'ACL' + (mode === 'add' ? '추가' : '편집')}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={[
          <div style={{ textAlign: 'center' }}>
            <Button onClick={handleCancel}>취소</Button>
            <Button type="primary" onClick={handleOk}>
              저장
            </Button>
          </div>,
        ]}
      >
        <Form
          form={form}
          onFinish={onFinish}
          // onChange={onChange}
          labelCol={{ span: 4 }}
          layout="horizontal"
          // labelAlign="left"
          key={'acl_edit_box'}
          initialValues={initialValues}
          validateMessages={validateMessages}
        >
          <Form.Item
            key={'acl_id'}
            name="id"
            label="ID"
            wrapperCol={{ span: 6 }}
            rules={[
              { required: true },
              { type: 'string', min: 4, max: 20 },
              { validator: validateAclId },
            ]}
          >
            <Input disabled={edit_mode} placeholder="enter ID"></Input>
          </Form.Item>
          <Form.Item
            key={'acl_info'}
            name="acl_info"
            label="도메인 설정"
            rules={[{ type: 'string', min: 2, max: 500 }]}
          >
            <Input.TextArea showCount autoSize={true} />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default AuthCompanyModal;
