import {Button, Col, Row} from "antd";
import {Link} from "react-router-dom";
import logoImgSrc from "../assets/images/logo.png";
import styles from "./SignComplete.module.css";
import {useHistory} from "react-router";


const SignComplete = () => {
    const history = useHistory();

    const toLogin = () => {
        history.push({pathname: '/login'});
    }

    return (
        <Row justify="center" align="middle">
            <Col>
                <Link to="/">
                    <Row
                        style={{
                            padding: '10px 15px',
                            backgroundColor: '#001628',
                            marginTop: '50px',
                        }}
                        align="middle"
                    >
                        <img
                            src={logoImgSrc}
                            alt="logo"
                            style={{
                                height: '40px',
                                // border: '1px solid #fff',
                                // borderRadius: '2px',
                            }}
                        />
                    </Row>
                </Link>

                <div className={styles.signCompletePanel}>
                    <div className={styles.signCompleteTextPanel}>
                        <p className={styles.signCompleteText}>
                            회원가입이 완료되었습니다.
                        </p>
                    </div>

                    <div className={styles.signCompleteButtonPanel}>
                        <Button type="primary"
                                htmlType="button"
                                onClick={toLogin}
                                className={styles.signCompleteButton}>
                            로그인하기
                        </Button>
                    </div>

                </div>
            </Col>
        </Row>
    )
}

export default SignComplete;