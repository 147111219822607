import React, {useEffect, useState} from 'react'
import {Col, Layout, Row, Select, Table, Typography} from "antd";
import styles from "./Home.module.css";
import {useAuth} from "../hooks/auth";
import {useBillState, useKMServiceUsageMonthState, useKMSState} from "../hooks";
import {Chart} from "react-google-charts";

const {Title, Text} = Typography

const nodeColumns = [
  {
    title: '지식맵',
    dataIndex: 'domain',
    key: 'domain',
    render: (v: string) => v,
  },
  {
    title: '스키마',
    dataIndex: 'name',
    key: 'name',
    render: (v: string) => v,
  },
  {
    title: '등록수',
    dataIndex: 'count',
    key: 'count',
    render: (v: number) => v,
  }
];
const relationColumns = [
  {
    title: '지식맵',
    dataIndex: 'domain',
    key: 'domain',
    render: (v: string) => v,
  },
  {
    title: '스키마',
    dataIndex: 'name',
    key: 'name',
    render: (v: string) => v,
  },
  {
    title: '등록수',
    dataIndex: 'count',
    key: 'count',
    render: (v: number) => v,
  }
];

const Home = () => {
  const {user, isCurriculumMaster, isDomainMaster, isKKMaster, isContentMaster} = useAuth();

  // current state
  const [nodeInfo, setNodeInfo] = useState<any>({});
  const [relationInfo, setRelationInfo] = useState<any>({});
  const [{}, {getAllEntityCountByCompanyRun}] = useKMSState(); // eslint-disable-line no-empty-pattern

  useEffect(() => {
    if (isCurriculumMaster || isDomainMaster) {
      getAllEntityCountByCompanyRun && getAllEntityCountByCompanyRun.run(user?.get('company')).then((res) => {
        for (const [k, v] of Object.entries(res)) {
          const sorted = v.sort((a: any, b: any) => b.count - a.count)

          let total = 0;
          const items = [];

          for (const [index, data] of sorted.entries()) {
            total += data.count;

            if (index < 3) {
              items.push({key: `${k}-${index}`, ...data});
            } else {
              if (items.length === 3) {
                items.push({
                  key: `${k}-etc`,
                  domain: "-",
                  name: "기타",
                  count: 0,
                })
              }

              items[3].count += data.count;
            }
          }

          switch (k) {
            case "nodes":
              setNodeInfo({items, total})
              break;
            case "edges":
              setRelationInfo({items, total})
              break;
          }
        }
      });
    }
  }, [isKKMaster, isDomainMaster, isCurriculumMaster, isContentMaster]); // eslint-disable-line react-hooks/exhaustive-deps

  const createCurrentState = (
    tableName: string,
    columns: any[],
    data: any,
  ) => {
    return (
      <Col span={12}>
        <Typography.Title level={5}>{tableName} ({data.total ?? 0})</Typography.Title>
        <Table
          rowKey={'key'}
          bordered
          columns={columns}
          dataSource={data.items ?? []}
          pagination={false}
        />
      </Col>
    )
  }

  // bill
  const [billItems, setBillItems] = useState<any[]>([]);
  const [bill, setBill] = useState("");
  const [billPrice, setBillPrice] = useState("");
  const [{}, {listOptions: listBillOptions}] = useBillState(); // eslint-disable-line no-empty-pattern

  useEffect(() => {
    if (isCurriculumMaster || isDomainMaster) {
      listBillOptions && listBillOptions().then((res) => {
        setBillItems(res);
        if (res.length > 0) {
          setBill(res[0].value);
          setBillPrice(res[0].fee);
        }
      });
    }
  }, [isKKMaster, isDomainMaster, isCurriculumMaster, isContentMaster]); // eslint-disable-line react-hooks/exhaustive-deps


  // graph
  const defaultLineData =  ["날짜", 'Node', 'Relation'];
  const [lineData, setLineData] = useState([["날짜", 'Node', 'Relation'], ["", 0, 0]]);
  const lineChartOptions = {
    curveType: 'function',
  }

  const [{}, {listMonths: listKMServiceUsageMonthRun,}] = useKMServiceUsageMonthState(); // eslint-disable-line no-empty-pattern

  useEffect(() => {
    if (isCurriculumMaster || isDomainMaster) {
      listKMServiceUsageMonthRun && listKMServiceUsageMonthRun().then((res) => {
        const lData = [defaultLineData];
        res.forEach((item) => {
          lData.push([item.name, item.nodeCount, item.relationCount]);
        })
        setLineData(lData);
      });
    }

  }, [isKKMaster, isDomainMaster, isCurriculumMaster, isContentMaster]); // eslint-disable-line react-hooks/exhaustive-deps

  //
  return isCurriculumMaster || isDomainMaster
    ? (<>
      <Layout>
        <Title level={3} style={{margin: "0"}}>서비스 대시보드</Title>

        {/*<div className={styles.notFoundKK}>*/}
        {/*  <Text>아직 등록된 지식맵이 없습니다. 지식맵을 생성해 주세요.</Text>*/}
        {/*  <Button*/}
        {/*    type="primary"*/}
        {/*    htmlType="button"*/}
        {/*    onClick={toKKGraph}*/}
        {/*    className={styles.signCompleteButton}>*/}
        {/*    지식맵 생성*/}
        {/*  </Button>*/}
        {/*</div>*/}
      </Layout>

      <Row gutter={16} style={{marginTop: "20px"}}>
        <Col span={12}>
          <Layout className={styles.layout} style={{height: 404}}>
            <Title level={4} style={{margin: "0"}}>지식맵 등록현황</Title>

            <Row style={{marginTop: "20px"}} gutter={24}>
              {createCurrentState("Node", nodeColumns, nodeInfo)}
              {createCurrentState("Relation", relationColumns, relationInfo)}
            </Row>

          </Layout>
        </Col>
        <Col span={12}>
          <Layout className={styles.layout} style={{height: '100%'}}>
            <Title level={4} style={{margin: "0"}}>이용 요금</Title>

            <div style={{marginTop: "20px", height: '100%'}}>
              <Select style={{width: '100%'}} options={billItems} value={bill} onSelect={(value) => {
                const t = billItems.find((item) => item.value === value);
                if (t !== undefined) setBillPrice(t.fee);
              }}/>

              <div
                style={{display: "flex", justifyContent: "center", alignItems: "center", height: '100%'}}>
                <Text style={{fontSize: '20pt', fontWeight: 700, paddingBottom: '50px'}}>{billPrice}</Text>
              </div>

            </div>

          </Layout>
        </Col>
      </Row>

      <Layout style={{marginTop: "20px"}} className={styles.layout}>
        <Title level={4} style={{margin: "0"}}>누적 지식맵 등록현황</Title>

        <div style={{marginTop: "20px", height: '400px'}}>
          <Chart
            width={'100%'}
            height={'100%'}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={lineData}
            options={lineChartOptions}
            rootProps={{'data-testid': '2'}}
          />
        </div>

      </Layout>

    </>)
    : (
      <div className={styles.layout}>
        <Typography.Title level={3} style={{margin: 0}}>Knowledge Keeper</Typography.Title>
      </div>
    )
}

export default Home
