import Graphin, {GraphinContext, Behaviors} from '@antv/graphin';
import {
    useState,
    useEffect,
    useContext,
    useMemo,
    useLayoutEffect,
} from 'react';
import _ from 'lodash';
import {
    useSelectedNodeNameState,
    useSelectedPropertyNameState,
} from '../hooks/graphMap';
import {useKKGraphData} from '../hooks/graphData';
import NodeAndEdgeViewerContainer from '../components/NodeAndEdgeViewerContainer';
import NodeAndEdgeSelectorContainer from '../components/NodeAndEdgeSelectorContainer';
import NodePropertySelectorContainer from '../components/NodePropertySelectorContainer';
import AnalyticsSelect from '../components/AnalyticsSelect';
import {Row, Col, Layout, Divider, Button, Tag, Drawer} from 'antd';
import GraphItemPanel from '../components/GraphItemPanel';
import Parse from 'parse';
import {
    useCompanyState,
    useFirstRenderState,
    useGraphMapState,
    useKMServiceState,
    useKMSState,
    useSchemaState
} from '../hooks';

import {Toolbar, LayoutSelector} from '@antv/graphin-components';
import {ZoomOutOutlined, ZoomInOutlined} from '@ant-design/icons';
import {useThrottleFn} from 'ahooks';
import GraphItemDetail from '../components/GraphItemDetail';

import {
    getNodeMatch,
    getRelationMatch,
    getNeighborWithNId,
} from '../kms-request';

import {pickColor} from '../helper/color';
import {useAuth} from "../hooks/auth";

const {Content} = Layout;
const {DragNodeWithForce, ActivateRelations, ClickSelect, BrushSelect} = Behaviors;

const getScaleStepAndDisplay = (key, value) => {
    if (key && key.endsWith('_rate') && _.isNumber(value)) {
        const fixedValue = +value.toFixed(2);
        return {
            step: Math.floor((value * 100) / 20),
            text: Math.floor(fixedValue * 100) + '%',
        };
    }
    return;
};

const GraphMap = () => {

    const {user, isKKMaster} = useAuth();

    const [curSelectedNode, setCurSelectedNode] = useState();
    const [targetRelFromNbrNode, setTargetRelFromNbrNode] = useState();
    const [idFromNeighborRelOfClickedNbrNode, setIdFromNeighborRelOfClickedNbrNode,] = useState();
    const [idFromClickedNeighborNode, setIdFromClickedNeighborNode] = useState();
    const [curNeighbourNode, setCurNeighbourNode] = useState();
    const [curNeighbourRel, setCurNeighbourRel] = useState();
    const [hiddenNeighbourRel, setHiddenNeighbourRel] = useState();
    const [neighbourNodeCount, setNeighbourNodeCount] = useState();
    const [hiddenNeighbourNode, setHiddenNeighbourNode] = useState();
    const [selectedAnalyticsKey, setSelectedAnalyticsKey] = useState();

    const [selectedKKSchemaInfo] = useSelectedNodeNameState();
    const [selectedPropertyName, setSelectedPropertyName] = useSelectedPropertyNameState();
    const [isFirstRender] = useFirstRenderState();

    const [hoveringNode, setHoveringNode] = useState();
    const [clickedGraphItem, setClickedGraphItem] = useState();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [analyticsHightLightCondition, setAnalyticsHightLightCondition] = useState({});

    const [companies, setCompanies] = useState([]);
    const [kmServices, setKMServices] = useState([]);
    const [schemas, setSchemas] = useState([]);

    const [selectedCompany, setSelectedCompany] = useState("");

    // api
    const [{}, {listAll: listCompanyAll}] = useCompanyState(); // eslint-disable-line no-empty-pattern

    const [{}, {listAll: listKMServiceAll}] = useKMServiceState(); // eslint-disable-line no-empty-pattern

    const [{allEntityCountByCompanyState}, {getAllEntityCountByCompanyRun}] = useKMSState();

    const [{}, {listAll: listSchemaAll}] = useSchemaState(); // eslint-disable-line no-empty-pattern

    const [layout, setLayout] = useState({name: 'force', options: {}});

    const [{}, {extend: extendGraphMap}] = useGraphMapState(); // eslint-disable-line no-empty-pattern

    const listKMServiceCall = (company) => {
        setSelectedCompany(company);

        listKMServiceAll && listKMServiceAll(company).then((res) => {
            setKMServices(res.results);
        })
    }

    const listSchemaCall = (kmService) => {
        listSchemaAll && listSchemaAll(kmService).then((res) => {
            setSchemas(res.results);
        })

        if (isKKMaster) {
            getAllEntityCountByCompanyRun.run(selectedCompany);
        } else {
            getAllEntityCountByCompanyRun.run(user.get("company"));
        }
    }

    useEffect(() => {
        extendGraphMap();
        listCompanyAll && listCompanyAll().then((res) => {
            setCompanies(res.results);
            setSchemas([]);
        });

        listKMServiceCall();
        listSchemaCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const [
        {graphData, graphDataWithExtraData},
        // UI에서 화면 그리는 데이터(graphData: 순수그래프데이터, graphDataWithExtraData: 추가통계데이터가 섞인 데이터)
        // 화면자체에서 그래프와 관련된 것에 사용하는 함수들
        {
            resetGraphDataWithKMSQuery,
            toggleNeighbour, // 더블클릭하면 주변이 열리고 닫힘
            setExtraDataMap,
            setExtraTransformMap,
            deleteItemById,
            getAddGraphData,
            addEdge,
            getNodesAndRelationsFromGraph,
            findNodeNeighbourIds,
            getPropertyNames,
        },
    ] = useKKGraphData();

    useEffect(() => {
        const getNodeViewText = (curSchema, node) => {
            const visibleProperty =
                node.properties[selectedPropertyName.name] ||
                node.properties[curSchema && curSchema.labelPropName];
            return [node.kkSchemaName, '\n', visibleProperty].join('');
        };
        const transformBaseMap = _.reduce(
            _.filter(schemas, (sk) => sk.type === 'node'),
            (prev, cur, idx) => {
                prev[cur.name] = (node, extraData = {}) => {
                    const stepAndDisplay = getScaleStepAndDisplay(
                        selectedAnalyticsKey,
                        extraData[selectedAnalyticsKey]
                    );

                    const isAnalyticsHL =
                        selectedAnalyticsKey &&
                        selectedAnalyticsKey.endsWith('_rate') &&
                        analyticsHightLightCondition.number &&
                        ((analyticsHightLightCondition.op === 'lte' &&
                                analyticsHightLightCondition.number / 100 >=
                                extraData[selectedAnalyticsKey]) ||
                            (analyticsHightLightCondition.op === 'gte' &&
                                analyticsHightLightCondition.number / 100 <=
                                extraData[selectedAnalyticsKey])) &&
                        true;
                    const nodeColor = pickColor(cur.name, idx);

                    return {
                        style: {
                            keyshape: {
                                fill: nodeColor,
                                stroke: nodeColor,
                                size: _.isUndefined(stepAndDisplay)
                                    ? 40
                                    : [10, 17, 25, 32, 60, 60][stepAndDisplay.step],
                                fillOpacity: _.isUndefined(stepAndDisplay)
                                    ? 0.4
                                    : [0.2, 0.3, 0.4, 0.6, 1, 1][stepAndDisplay.step],
                            },
                            badges:
                                selectedAnalyticsKey && extraData[selectedAnalyticsKey]
                                    ? [
                                        {
                                            position: 'RT',
                                            type: 'text',
                                            value: stepAndDisplay
                                                ? stepAndDisplay.text
                                                : extraData[selectedAnalyticsKey],
                                            size: [40, 20],
                                            color: '#fff',
                                            fill: isAnalyticsHL ? '#E93B81' : '#C490E4',
                                            offset: [-10, 0],
                                        },
                                    ]
                                    : undefined,
                            icon: {
                                value: getNodeViewText(cur, node),
                                fill: '#333',
                                size: 10,
                            },
                        },
                    };
                };
                return prev;
            },
            {}
        );
        setExtraTransformMap(transformBaseMap);
    }, [
        selectedAnalyticsKey,
        setExtraTransformMap,
        schemas,
        analyticsHightLightCondition,
        selectedPropertyName,
    ]);

    const [maximumNodeNumber, setMaximumNodeNumber] = useState(100);
    const [maximumRelNumber, setMaximumRelNumber] = useState(100);
    useEffect(() => {
        if (selectedKKSchemaInfo.type === 'node') {
            resetGraphDataWithKMSQuery(
                getNodeMatch(
                    `${selectedKKSchemaInfo.name}`,
                    '""',
                    '""',
                    true,
                    maximumNodeNumber
                )
            );
        } else if (selectedKKSchemaInfo.type === 'relation') {
            resetGraphDataWithKMSQuery(
                getRelationMatch(
                    `${selectedKKSchemaInfo.name}`,
                    '""',
                    '""',
                    true,
                    maximumRelNumber
                )
            );
        }
    }, [
        selectedKKSchemaInfo,
        resetGraphDataWithKMSQuery,
        maximumNodeNumber,
        maximumRelNumber,
    ]);

    // update selected property name if selected node changed
    useEffect(() => {
        let propertyName = '';
        if (selectedKKSchemaInfo.type === 'node' && schemas !== undefined) {
            const foundSchema = _.find(
                schemas,
                (kkSL) => kkSL.name === selectedKKSchemaInfo.name
            );
            if (foundSchema !== undefined) {
                propertyName = foundSchema.labelPropName;
            }
        }
        setSelectedPropertyName({name: propertyName});
    }, [schemas, selectedKKSchemaInfo, setSelectedPropertyName]);

    useEffect(() => {
        if (curNeighbourRel) {
            const targetRelFromNbrNodeInArr = curNeighbourRel
                .map((rel) => {
                    return rel.target === idFromClickedNeighborNode ? rel.target : null;
                })
                .filter((ele) => {
                    return ele !== null;
                });
            setTargetRelFromNbrNode(targetRelFromNbrNodeInArr.pop());
            // eslint-disable-next-line array-callback-return
            curNeighbourRel.find((rel) => {
                if (
                    rel.source === curSelectedNode.id &&
                    rel.target === targetRelFromNbrNode
                ) {
                    return setIdFromNeighborRelOfClickedNbrNode(rel.id);
                }
            });
        }
        // console.log('targetRelFromNbrNode:', targetRelFromNbrNode);
        // console.log(
        //   '선택된 이웃노드의 릴레이션:',
        //   idFromNeighborRelOfClickedNbrNode
        // );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curNeighbourRel, targetRelFromNbrNode, idFromClickedNeighborNode]);

    // Graphin Interactions
    const onNodeDoubleClick = (evt) => {
        // console.log(evt);

        // TODO: 다음 소스 참고하여 더블 클릭 node에 force를 잔뜩 주어서 이웃 열었을 때 자연스럽게
        //Graphin/packages/graphin/src/behaviors/DragNodeWithForce.tsx
        const node = evt.item.getModel();

        // node.x = evt.x;
        // node.y = evt.y;
        // node.layout = {
        //   ...node.layout,
        //   force: {
        //     mass: 1000000000,
        //   },
        // };
        toggleNeighbour(node);
    };

    const onItemClick = (evt) => {
        setIdFromClickedNeighborNode(null);
        const model = evt.item.getModel();
        // console.log('selected schema:', model);
        setCurSelectedNode(model);
        // console.log(model);
        // console.log(evt);

        const currentNeighbourIds = findNodeNeighbourIds(model.id);
        getNeighborWithNId(`${model.id}`, `${currentNeighbourIds.join(',')}`).then(
            function (graph) {
                const currentNeighbourCount = currentNeighbourIds.length;
                const nodesAndEdges = getNodesAndRelationsFromGraph(graph);
                const hiddenConnectedNodeCount = nodesAndEdges.nodes.length;
                setNeighbourNodeCount(
                    currentNeighbourIds.length + hiddenConnectedNodeCount - 1
                );
                if (hiddenConnectedNodeCount === 0) {
                    setNeighbourNodeCount(currentNeighbourCount);
                }

                setHiddenNeighbourNode(
                    // eslint-disable-next-line array-callback-return
                    nodesAndEdges.nodes.filter((n) => {
                        if (n.id !== model.id) {
                            return n;
                        }
                    })
                );

                setHiddenNeighbourRel(
                    nodesAndEdges.edges.map((rel) => {
                        return rel.kkSchemaName;
                    })
                );
            }
        );

        const curNeighbourRel = graphData.edges
            // eslint-disable-next-line array-callback-return
            .map((edge) => {
                if (model.id === edge.source || model.id === edge.target) {
                    return edge;
                }
            })
            .filter((ele) => {
                return ele !== undefined;
            });

        setCurNeighbourRel(curNeighbourRel);

        const curNeighbourWithoutSelectedNode = graphData.nodes
            // eslint-disable-next-line array-callback-return
            .map((node) => {
                if (node.id !== model.id) {
                    return node;
                }
            })
            .filter((ele) => {
                return ele !== undefined;
            });

        const curMatchedNeighbourWithRel = curNeighbourWithoutSelectedNode.map(
            (node) => {
                return (
                    curNeighbourRel
                        // eslint-disable-next-line array-callback-return
                        .map((rel) => {
                            if (node.id === rel.source || node.id === rel.target) {
                                return node;
                            }
                        })
                        .filter((ele) => {
                            return ele !== undefined;
                        })
                );
            }
        );

        let curNeighbourNodes = [];

        curMatchedNeighbourWithRel.forEach((subArr) => {
            subArr.forEach((el) => {
                curNeighbourNodes.push(el);
            });
        });

        // console.log('curNeighbourNodes :', curNeighbourNodes);
        setCurNeighbourNode(curNeighbourNodes);

        if (clickedGraphItem && clickedGraphItem.main === model) {
            onCanvasClick();
        } else {
            if (_.find(schemas, (ks) => ks.name === model.kkSchemaName)) {
                const from =
                    model.source && _.find(graphData.nodes, (n) => n.id === model.source);
                const to =
                    model.target && _.find(graphData.nodes, (n) => n.id === model.target);
                setClickedGraphItem({
                    main: model,
                    from,
                    to,
                });
                setIsDrawerOpen(true);
            } else {
                alert('스키마 정보가 존재하지 않는 그래프 아이템입니다.');
            }
        }
    };

    const onCanvasClick = (evt) => {
        setClickedGraphItem(null);
        setIsDrawerOpen(false);
        setIdFromClickedNeighborNode(null);
    };
    const onNodeHover = (evt) => setHoveringNode(evt.item.getModel());
    const onNodeHoverEnd = () => setHoveringNode(null);

    const convertFormatIntoNameAndCount = (data) =>
        _.chain(data)
            .groupBy('kkSchemaName')
            .map((value, key) => ({name: key, count: value.length}))
            .value();

    const nodeAndEdgeCountData = useMemo(
        //노드, 릴레이션 개수 표기
        () => ({
            nodes: convertFormatIntoNameAndCount(graphData.nodes),
            edges: convertFormatIntoNameAndCount(graphData.edges),
        }),
        [graphData]
    );

    const onAnalyticsSelect = (item) => {
        setSelectedAnalyticsKey(item);
    };

    const nodeGroupByKKSchemaName = _.groupBy(graphData.nodes, (v) => v.kkSchemaName);
    const pIds = _.map(nodeGroupByKKSchemaName.Problem, (p) => p.properties && p.properties.id);
    const kcUIds = _.map(nodeGroupByKKSchemaName.KC, (p) => p.properties && p.properties.uid);
    const lvUIds = _.map(nodeGroupByKKSchemaName.Level, (p) => p.properties && p.properties.uid);
    useEffect(() => {
        const query = new Parse.Query('SummitSpeedProblemProfile');
        query.containedIn('problem_id', pIds);
        const query2 = new Parse.Query('SummitSpeedDerivedProfile');
        query2.containedIn(
            'derivedId',
            _.map(kcUIds, (kcUID) => `KC_${kcUID}`)
        );
        const query3 = new Parse.Query('SummitSpeedDerivedProfile');
        query3.containedIn(
            'derivedId',
            _.map(lvUIds, (lvUID) => `Level_${lvUID}`)
        );

        Promise.all([
            query.find({
                json: true,
            }),
            query2.find({
                json: true,
            }),
            query3.find({
                json: true,
            }),
        ]).then((results) => {
            setExtraDataMap((c) => {
                return {
                    ...c,
                    ..._.reduce(
                        nodeGroupByKKSchemaName.Problem,
                        (prev, cur, idx) => {
                            prev[cur.id] = _.find(
                                results[0],
                                (item) => item.problem_id === cur.properties.id
                            );
                            return prev;
                        },
                        {}
                    ),
                    ..._.reduce(
                        nodeGroupByKKSchemaName.KC,
                        (prev, cur, idx) => {
                            prev[cur.id] = _.find(
                                results[1],
                                (item) => item.derivedId === 'KC_' + cur.properties.uid
                            );
                            return prev;
                        },
                        {}
                    ),
                    ..._.reduce(
                        nodeGroupByKKSchemaName.Level,
                        (prev, cur, idx) => {
                            prev[cur.id] = _.find(
                                results[2],
                                (item) => item.derivedId === 'Level_' + cur.properties.uid
                            );
                            return prev;
                        },
                        {}
                    ),
                };
            });
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pIds.join(''), kcUIds.join(''), lvUIds.join('')]);

    const handleToolbarClick = (graphinContext, config) => {
        const {apis} = graphinContext;
        const {handleZoomIn, handleZoomOut} = apis;
        if (config.key === 'zoomIn') {
            handleZoomIn();
        } else if (config.key === 'zoomOut') {
            handleZoomOut();
        }
    };

    const resetDrawerToClose = () => {
        setIsDrawerOpen(false);
    };

    const getNodePropertySelector = () => {
        if (selectedKKSchemaInfo.type !== 'node') return '';
        return (
            <>
                <Divider style={{margin: '0'}}/>
                <NodePropertySelectorContainer properties={getPropertyNames()}/>
            </>
        );
    };
    // console.log('#render graphDataWithExtraData', graphDataWithExtraData);

    const StateSetter = ({stateOfNodes, stateOfEdges}) => {
        const {graph} = useContext(GraphinContext); // 그래프의 컨텍스트에서 가져온 다음에 거기다가 api를 호출. <Graphin> 태그 내부에서 해야하므로

        // const prevStateOfNodes = usePrev(stateOfNodes);

        useEffect(() => {
            if (stateOfNodes) {
                _.map(graph.getNodes(), (n) => {
                    graph.clearItemStates(n, ['clickedNeighbor']);
                });
                _.map(stateOfNodes, (statusName, id) => {
                    const item = graph.findById(id);
                    item && graph.setItemState(item, statusName, true);
                });
            }
            if (stateOfEdges) {
                _.map(graph.getEdges(), (n) => {
                    graph.clearItemStates(n, ['connectedNeighborRel']);
                });
                _.map(stateOfEdges, (statusName, id) => {
                    const item = graph.findById(id);
                    item && graph.setItemState(item, statusName, true);
                });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [graph, stateOfNodes, stateOfEdges]);

        return null;
    };

    // get valid (with schema) node list only
    const validSchemaNodes = useMemo(() => {
        const nodeInfos = allEntityCountByCompanyState.data && allEntityCountByCompanyState.data.nodes;
        if (!nodeInfos || !schemas) return [];
        const validSchemaList = _.map(
            _.filter(schemas, (sk) => sk.type === 'node'),
            'name'
        );

        const validSchemaSet = new Set(validSchemaList);
        return nodeInfos.filter((n) => validSchemaSet.has(n.name));
    }, [schemas, allEntityCountByCompanyState]);

    // get valid (with schema) relation list only
    const validSchemaEdges = useMemo(() => {
        const edgeInfos = allEntityCountByCompanyState.data && allEntityCountByCompanyState.data.edges;
        if (!edgeInfos || !schemas) return [];
        const validSchemaSet = new Set(_.map(
                _.filter(schemas, (sk) => sk.type === 'relation'),
                'name'
            )
        );
        return edgeInfos.filter((n) => validSchemaSet.has(n.name));
    }, [schemas, allEntityCountByCompanyState]);

    return graphData ? (
        <>
            <Layout.Sider
                width="350px"
                style={{backgroundColor: '#FAFAFA', padding: '20px'}}
            >
                <NodeAndEdgeSelectorContainer
                    nodes={validSchemaNodes}
                    edges={validSchemaEdges}
                    onSetAboutNodeNumberInput={(value) => {
                        setMaximumNodeNumber(value);
                    }}
                    onSetAboutRelNumberInput={(value) => {
                        setMaximumRelNumber(value);
                    }}
                    api={{
                        listKMServiceCall,
                        listSchemaCall,
                    }}
                    companies={companies}
                    kmServices={kmServices}
                />
                <Divider/>
                <AnalyticsSelect
                    selectedKey={selectedAnalyticsKey}
                    handleOnClick={onAnalyticsSelect}
                    onHightLightValuesChange={(values) => {
                        // console.log(values);
                        setAnalyticsHightLightCondition(values);
                    }}
                />
            </Layout.Sider>
            <Content style={{padding: '25px'}}>
                {/* <KKBreadcrumb style={{ margin: '16px 0' }} /> */}
                <Layout
                    style={{
                        background: '#fff',
                        height: '100%',
                    }}
                >
                    <Row
                        justify="space-between"
                        align="middle"
                        style={{padding: '12px 24px'}}
                    >
                        <NodeAndEdgeViewerContainer data={nodeAndEdgeCountData}/>
                        <Col>
                            <Button
                                style={{
                                    background: '#FFFFFF',
                                    boxSizing: 'border-box',
                                    borderRadius: '20px',
                                }}
                                onClick={() => {
                                    setIsDrawerOpen(true);
                                    setClickedGraphItem(null);
                                }}
                            >
                                그래프 아이템 생성
                            </Button>
                        </Col>
                    </Row>
                    <Divider style={{margin: 0}}/>
                    {!isFirstRender && (
                        <Row style={{height: '100%', width: '100%'}}>
                            <Graphin
                                // https://antv.vision/graphin-1.x-site/en/docs/api/layout#layoutoptions
                                // https://graphin.antv.vision/en-US/graphin/behaviors/behaviors
                                layout={{
                                    // neo4j driver 참고
                                    // neo4j-browser/src/browser/modules/D3Visualization/lib/visualization/components/layout.ts

                                    // type: 'force',
                                    // preventOverlap: true,
                                    // nodeSize: 40,
                                    // nodeSpacing: 100,
                                    // // minMovement: 0.1,
                                    // // gravity: 50,
                                    // edgeStrength: 1,
                                    // nodeStrength: 100,
                                    // linkDistance: 85,

                                    preset: {name: 'radial', options: {}},
                                    // layout
                                    // https://antv.vision/graphin-1.x-site/en/docs/manual/main-concepts/layout
                                    // type: 'gForce',  // https://g6.antv.vision/en/docs/manual/middle/layout/graph-layout#gforce
                                    // gpuEnabled: true,
                                    // // damping: 0.5,
                                    // // minMovement: 0.7,
                                    // // gravity: 10,
                                    // nodeSize: 40,
                                    // maxIteration: 100,
                                    // // coulombDisScale: 0.003,
                                    // maxSpeed: 50000,

                                    // 대소문자 주의 문서마다 다다름.
                                    type: 'graphin-force', //// https://antv.vision/graphin-1.x-site/en/docs/api/layout#layoutoptions
                                    // stiffness: 1000,
                                    damping: 0.7, //0.9
                                    minEnergyThreshold: 10,
                                    MaxIterations: 1,
                                    // enableWorker: true,
                                    repulsion: 100.0 * 5, //반발력
                                    // defSpringLen: () => 150,
                                    maxSpeed: 3000,
                                    // centripetalOptions: {
                                    //   leaf: 3.2,
                                    //   single: 3.2,
                                    // },

                                    // /Graphin/packages/graphin/src/layout/inner/registerGraphinForce.ts
                                    // {
                                    //   /** 前置布局，默认为 concentric */
                                    //   preset: {
                                    //     /** 特殊情况处理：前置布局为force，但是前置的数据也为空，则证明是初始化force布局，否则为正常前置force布局 */
                                    //     name: 'grid',
                                    //     options: {},
                                    //   },
                                    //   /** spring stiffness 弹簧劲度系数 * */
                                    //   stiffness: 200.0,
                                    //   /** repulsion 斥力，这里指代 库伦常量Ke */
                                    //   repulsion: 200.0 * 5,
                                    //   /** 向心力 */
                                    //   centripetalOptions: {
                                    //     leaf: 1.6,
                                    //     single: 1.6,
                                    //   },
                                    //   /** 速度的减震因子，其实就是阻尼系数 */
                                    //   damping: 0.9,
                                    //   /** 最小能量阈值，当粒子运动，有阻尼系数的存在，最终会将初始的能量消耗殆尽 */
                                    //   minEnergyThreshold: 0.1,
                                    //   /** 最大的速度 [0,1000] */
                                    //   maxSpeed: 1000,
                                    //   /** 最大迭代数 */
                                    //   MaxIterations: 10000, // 240, // 1000000次/(1000/60) = 60000s = 1min
                                    //   /** 是否开启动画 */
                                    //   animation: true,
                                    // }
                                }}
                                // layout={layout}
                                data={
                                    graphDataWithExtraData.nodes.length === 0
                                        ? {
                                            nodes: [
                                                {
                                                    style: {
                                                        keyshape: {
                                                            opacity: 0,
                                                        },
                                                    },
                                                },
                                            ],
                                            edges: [],
                                        }
                                        : graphDataWithExtraData
                                }
                                defaultNode={{
                                    style: {
                                        keyshape: {
                                            // size: 60,
                                            size: 40,
                                            fill: '#D1D9D9',
                                            stroke: '#D1D9D9',
                                            // fillOpacity: 1,
                                        },
                                        label: {
                                            // offset: [0, -24],
                                        },
                                    },
                                }}
                                defaultEdge={{
                                    type: 'line',
                                    style: {
                                        endArrow: true,
                                        lineWidth: 1,
                                        stroke: '#aaa',
                                    },
                                    labelCfg: {
                                        autoRotate: true,
                                        refY: 10,
                                        style: {
                                            fontSize: 10,
                                            fill: '#aaa',
                                        },
                                    },
                                }}
                                nodeStateStyles={{
                                    status: {
                                        clickedNeighbor: {
                                            keyshape: {
                                                size: 40,
                                                opacity: 1,
                                                fill: 'red',
                                                fillOpacity: 0.3,
                                                stroke: 'red',
                                            },
                                            label: {
                                                fontSize: 12,
                                                fillOpacity: 1,
                                            },
                                            halo: {
                                                animate: {
                                                    attrs: (ratio) => {
                                                        const startR = 40;
                                                        const diff = 50 - startR;
                                                        return {
                                                            r: startR + diff * ratio,
                                                            opacity: 0.5 + 0.5 * ratio,
                                                        };
                                                    },
                                                    duration: 200,
                                                    easing: 'easeCubic',
                                                    delay: 0,
                                                    repeat: false,
                                                    keyshape: {
                                                        fill: '#ff0000',
                                                        opacity: 0.8,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                }}
                                edgeStateStyles={{
                                    status: {
                                        connectedNeighborRel: {
                                            keyshape: {
                                                size: 40,
                                                opacity: 1,
                                                fill: '#4127e3',
                                                fillOpacity: 0.3,
                                                stroke: '#4127e3',
                                            },
                                            label: {
                                                fontSize: 12,
                                                fillOpacity: 1,
                                            },
                                        },
                                    },
                                }}

                                // TODO: g6의 line type에서는 selected가 적용이 안된다.(정확한 원인 아직 모름)
                                // edgeStateStyles={{
                                //   status: {
                                //     selected: {
                                //       stroke: 'steelblue',
                                //       lineWidth: 2,
                                //       shadowColor: 'steelblue',
                                //       shadowBlur: 10,
                                //       'text-shape': {
                                //         fontSize: 12,
                                //         fill: 'steelblue',
                                //       },
                                //     },
                                //   },
                                // }}
                                // fitView={true}
                            >
                                <Toolbar
                                    options={[
                                        {
                                            key: 'zoomOut',
                                            name: <ZoomInOutlined/>,
                                        },
                                        {
                                            key: 'zoomIn',
                                            name: <ZoomOutOutlined/>,
                                        },
                                        // {
                                        //   key: 'delete',
                                        //   name: <DeleteOutlined/>
                                        // }
                                    ]}
                                    onChange={handleToolbarClick}
                                    direction="horizontal"
                                />
                                <DragNodeWithForce autoPin={true}/>
                                <NodeClickBehavior
                                    onDoubleClick={onNodeDoubleClick}
                                    onNodeClick={onItemClick}
                                    onRelationClick={onItemClick}
                                    onNodeHover={onNodeHover}
                                    onNodeHoverEnd={onNodeHoverEnd}
                                    onCanvasClick={onCanvasClick}
                                />
                                <LayoutSelector value={layout} onChange={setLayout}/>
                                {/* <MiniMap visible={false} /> */}
                                <ActivateRelations disabled/>
                                <ClickSelect multiple={false}/>
                                <BrushSelect disabled/>
                                <StateSetter
                                    stateOfNodes={{
                                        [idFromClickedNeighborNode]: 'clickedNeighbor',
                                    }}
                                    stateOfEdges={{
                                        [idFromNeighborRelOfClickedNbrNode]: 'connectedNeighborRel',
                                    }}
                                />
                            </Graphin>
                        </Row>
                    )}
                    {getNodePropertySelector()}
                    <Divider style={{margin: '0'}}/>
                    <Row style={{height: '60px', padding: '0 24px'}} align="middle">
                        <h1>
                            {hoveringNode && <Tag>{hoveringNode.kkSchemaName}</Tag>}
                            {hoveringNode &&
                                _.map(
                                    _.omitBy(hoveringNode.properties, (v, k) =>
                                        _.startsWith(k, '_')
                                    ),
                                    (v, k) => {
                                        return (
                                            <span key={k}>
                        <strong>{k}</strong>:{_.toString(v)} &nbsp;
                      </span>
                                        );
                                    }
                                )}
                        </h1>
                    </Row>
                </Layout>
            </Content>
            <Drawer
                title={`그래프 아이템 ${clickedGraphItem ? '상세' : '생성'}`}
                placement="right"
                closable
                onClose={resetDrawerToClose}
                visible={isDrawerOpen}
                width={350}
                mask={false}
            >
                {clickedGraphItem ? (
                    <GraphItemDetail
                        kkSchemas={_.mapValues(clickedGraphItem, (item) => {
                            return _.find(schemas, (v) => v.name === item?.kkSchemaName);
                        })}
                        graphItem={clickedGraphItem.main}
                        fromGraphItem={clickedGraphItem.from}
                        toGraphItem={clickedGraphItem.to}
                        onCancel={resetDrawerToClose}
                        neighbourNodeCnt={neighbourNodeCount}
                        hiddenNeighbourNode={hiddenNeighbourNode}
                        hiddenNeighbourRel={hiddenNeighbourRel}
                        curNeighbourNode={curNeighbourNode}
                        curNeighbourRel={curNeighbourRel}
                        onNeighborNodeClick={setIdFromClickedNeighborNode}
                        onDelete={(id) => {
                            //TODO: 왼쪽 패널 count 관련 새로고침
                            getAllEntityCountByCompanyRun.run();
                            //TODO: graphData에서 해당 item 삭제
                            deleteItemById(id);
                            resetDrawerToClose();
                        }}
                    />
                ) : (
                    <GraphItemPanel
                        graphData={graphData}
                        kkSchemaList={schemas}
                        onCancel={() => {
                            resetDrawerToClose();
                        }}
                        onSave={(graphChangeObj) => {
                            getAllEntityCountByCompanyRun.run(user.get("company"));
                            const isRelation = !!graphChangeObj.get('data').to;
                            // graphChangeObj.get('kkSchemaName');
                            // graphChangeObj.id;

                            if (isRelation) {
                                addEdge({
                                    id: graphChangeObj.id,
                                    source: graphChangeObj.get('data').fromNodeId,
                                    target: graphChangeObj.get('data').toNodeId,
                                    kkSchemaName: graphChangeObj.get('kkSchemaName'),
                                    label: graphChangeObj.get('kkSchemaName'),
                                    properties: {
                                        ...graphChangeObj.get('data').properties,
                                        _KK_ucgcId: graphChangeObj.id,
                                    },
                                    stateStyles: {
                                        selected: {
                                            stroke: 'steelblue',
                                            lineWidth: 2,
                                            shadowColor: 'steelblue',
                                            shadowBlur: 10,
                                            'text-shape': {
                                                fontSize: 12,
                                                fill: 'steelblue',
                                            },
                                        },
                                    },
                                });
                            } else {
                                getNodeMatch(
                                    `${graphChangeObj.get('kkSchemaName')}`,
                                    '_KK_ucgcId',
                                    `"${graphChangeObj.id}"`
                                ).then(getAddGraphData(graphChangeObj.id));
                            }
                            // 조회 해서 id 알아낸다음에 추가하기
                        }}
                    />
                )}
            </Drawer>
            {/* <Layout.Sider
        width="350px"
        style={{ backgroundColor: '#FAFAFA', padding: '20px' }}
      >
        <Switch>
          <Route path="/graphMap/new">
            <NodeOrRelationPanel graphData={graphData} />
          </Route>
          <Route path="/graphMap/:uid">
            <NodeOrRelationPanel graphData={graphData} />
          </Route>
          <Route path="/graphMap">
            <Row
              className={styles.defaultPanelContainer}
              justify="center"
              align="bottom"
            >
              <div className={styles.createNodeOrRelContainer}>
                <Button
                  type="default"
                  className={styles.createNodeOrRelBtn}
                  disabled={_.isEmpty(selectedNodeInfo)}
                  onClick={() => {
                    history.replace('/graphMap/new');
                  }}
                >
                  신규 생성
                </Button>
                <Text
                  className={styles.createNodeOrRelGuideText}
                >{`버튼을 클릭하여 지식맵에\n신규 노드 또는 릴레이션을 바로 생성할 수 있습니다.`}</Text>
              </div>
            </Row>
          </Route>
        </Switch>
      </Layout.Sider> */}
        </>
    ) : (
        <h1>Loading...</h1>
    );
};

export default GraphMap;

// TODO: 별도의 컴포넌트로 뺄 필요까지는 없어보임. graph Instance 생성해서 Graphin에 제공하는 방식이 있을 듯. 추후 수정
const NodeClickBehavior = ({
                               onNodeClick,
                               onNodeHover,
                               onNodeHoverEnd,
                               onRelationClick,
                               onDoubleClick,
                               onCanvasClick,
                           }) => {
    const {graph} = useContext(GraphinContext);

    const {run: runClickHandler} = useThrottleFn(
        (e) => {
            if (e.originalEvent.type === 'dblclick') {
                _.isFunction(onDoubleClick) && onDoubleClick(e, graph);
            } else {
                const clickEdges = graph.findAllByState('edge', 'selected');
                clickEdges.forEach((ce) => {
                    graph.setItemState(ce, 'selected', false);
                });
                _.isFunction(onNodeClick) && onNodeClick(e, graph);
            }
        },
        {
            wait: 150,
            leading: false,
            trailing: true,
        }
    );
    useLayoutEffect(() => {
        const events = {
            'node:dblclick': (e) => {
                // TODO: dblclick시에 기존 선택되어 있는 model 상태 유지
                runClickHandler(e);
                // _.isFunction(onDoubleClick) && onDoubleClick(e, graph);
            },
            'node:click': (e) => {
                runClickHandler(e);
                // _.isFunction(onNodeClick) && onNodeClick(e, graph);
            },
            'node:mouseover': (e) => {
                _.isFunction(onNodeHover) && onNodeHover(e, graph);
            },
            'node:mouseleave': (e) => {
                _.isFunction(onNodeHoverEnd) && onNodeHoverEnd(e, graph);
            },
            'edge:click': (e) => {
                const clickEdges = graph.findAllByState('edge', 'selected');
                const clickNodes = graph.findAllByState('node', 'selected');
                [...clickEdges, ...clickNodes].forEach((ce) => {
                    graph.setItemState(ce, 'selected', false);
                });
                const edgeItem = e.item; // Get the clicked item
                graph.setItemState(edgeItem, 'selected', true); // Set the state 'click' of the item to be true
                _.isFunction(onRelationClick) && onRelationClick(e, graph);
            },
            'canvas:click': (e) => {
                const clickEdges = graph.findAllByState(
                    'edge',
                    'selected',
                    'clickedNeighbor'
                );
                clickEdges.forEach((ce) => {
                    graph.setItemState(ce, 'selected', false);
                });
                _.isFunction(onCanvasClick) && onCanvasClick(e, graph);
            },
        };
        _.map(events, (fn, name) => {
            graph.on(name, fn);
        });
        // Each click focuses on the click node
        // graph.on('edge:mouseenter', (ev) => {
        //   const edge = ev.item;
        //   graph.setItemState(edge, 'active', true);
        // });

        // Initialize focus to `node-1`
        // apis.focusNodeById('01');

        // graph.on('edge:mouseleave', (ev) => {
        //   const edge = ev.item;
        //   graph.setItemState(edge, 'active', false);
        // });
        return () => {
            _.map(events, (fn, name) => {
                graph.off(name, fn);
            });
        };
    }, [
        graph,
        onNodeClick,
        onRelationClick,
        onDoubleClick,
        onNodeHover,
        onNodeHoverEnd,
        onCanvasClick,
        runClickHandler,
    ]);
    return null;
};
