import {useState, useEffect} from 'react';
import Parse from 'parse';
import {
    Form,
    Button,
    Select,
    Modal,
    Row,
    Col,
    Space,
    Divider,
    message,
} from 'antd';
import {
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    RightOutlined,
} from '@ant-design/icons';
import InputAndSelectFormList from './InputAndSelectFormList';
import NodeTypeMatchingFields from './NodeTypeMatchingFields';
import RelationTypeMatchingFields from './RelationTypeMatchingFields';
import _ from 'lodash';
import ParseFileFormItem from './ParseFileFormItem';
import {CSVLink} from 'react-csv';
import {useAuth} from "../hooks/auth";

type KKSchema = {
    name?: string;
    type?: 'node' | 'relation';
    props?: any;
};

interface ImporterItemApi {
    saveImporterItem: (importerItem: any) => Promise<void>;
    listKMServiceCall: (company: string) => void;
    listSchemaCall: (kmService: string) => void;
}

interface ImporterUploadPanelProps {
    isPending: boolean;
    api: ImporterItemApi;
    onSave: () => void;
    onCancel: () => void;
    companies: any[],
    kmServices: any[]
    schemas: any[],
}

const ImporterUploadPanel = ({
                                 isPending,
                                 api,
                                 onSave,
                                 onCancel,
                                 companies,
                                 kmServices,
                                 schemas,
                             }: ImporterUploadPanelProps) => {

    const {isContentMaster, isKKMaster} = useAuth();

    // form - company
    const [companyOptions, setCompanyOptions] = useState<any[]>([]);
    useEffect(() => {
        if (companies !== undefined) {
            setCompanyOptions(companies.map((e: any) => ({
                label: e.name,
                value: e.id,
            })));
        }
    }, [companies]);

    // form - km services

    const [kmServiceOptions, setKMServiceOptions] = useState<any[]>([]);
    useEffect(() => {
        if (kmServices !== undefined) {
            setKMServiceOptions(kmServices.map((e: any) => ({
                label: e.name,
                value: e.domain,
            })));
        }
    }, [kmServices])

    // form - km services
    const [schemaOptions, setSchemaOptions] = useState<any[]>([]);
    useEffect(() => {
        if (kmServices !== undefined) {
            setSchemaOptions(
                _.chain(schemas)
                    .groupBy('type')
                    .map((v, k) => ({
                        label: k === 'node' ? '노드' : '릴레이션',
                        options: _.map(v, (i: any) => ({
                            label: i.name,
                            value: i.name,
                        })),
                    }))
                    .value()
            );
        }
    }, [schemas]) // eslint-disable-line react-hooks/exhaustive-deps

    // form
    const [form] = Form.useForm();

    // event
    const [selectedSchema, setSelectedSchema] = useState<KKSchema>({});

    const onFinish = (values: any) => {
        const {
            fileUploader: {
                file: {response},
            },
            type: schemaType,
            schemaName: selectedKKSchema,
            fromNodeName: fromKKSchema = undefined,
            toNodeName: toKKSchema = undefined,
        } = values;
        const targetKKSchemas = {selectedKKSchema, fromKKSchema, toKKSchema};
        const result = _.map(
            _.map(
                targetKKSchemas,
                (value, key) =>
                    value && {
                        column: key,
                        objectId: (schemas.find((sc: any) => sc.name === value) as any)
                            ?.objectId,
                    }
            ),
            (schemaInfo) =>
                schemaInfo && {
                    [schemaInfo.column]: new Parse.Object('KKSchema').set(
                        'objectId',
                        schemaInfo.objectId
                    ),
                }
        ).reduce((prev, current) => ({...prev, ...current}));

        api.saveImporterItem && api.saveImporterItem({
            //@ts-ignore
            file: Parse.File.fromJSON({
                __type: 'File',
                ...response,
            }),
            company: values.company,
            domain: values.domain,
            schemaType,
            ...result,
        }).then(() => {
            resetForm();
            message.info('업로드 생성이 완료되었습니다.');
            onSave();
        });
    };

    const resetForm = (extraInitialValues = {}) => {
        form.resetFields();
        form.setFieldsValue({
            ...{props: [{type: 'text'}]},
            ...extraInitialValues,
        });
    };

    const showCancelConfirm = () => {
        Modal.confirm({
            title: '작성중인 내용을 취소하겠습니까?',
            icon: <ExclamationCircleOutlined/>,
            okText: '네',
            okType: 'danger',
            cancelText: '아니오',
            onOk() {
                resetForm();
            },
            onCancel() {
            },
        });
    };

    return (
        <Col>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{type: 'node', props: [{type: 'text'}]}}
            >

                {isKKMaster && (
                    <Form.Item
                        data-test="error-message-of-company-in-importer"
                        name="company"
                        label="회사"
                        required-mark="true"
                        rules={[{required: true, message: '회사를 선택해주세요.'}]}
                    >
                        <Select options={companyOptions} onChange={(value: string) => {
                            api.listKMServiceCall && api.listKMServiceCall(value);
                            form.resetFields(["domain", "schemaName"])
                        }}
                        placeholder="회사를 선택해주세요."
                        />
                    </Form.Item>
                )}

                <Form.Item
                    data-test="error-message-of-domain-in-importer"
                    name="domain"
                    label="지식맵"
                    required-mark="true"
                    rules={[{required: true, message: '지식맵을 선택해주세요.'}]}
                >
                    <Select options={kmServiceOptions} onChange={(value: string) => {
                        api.listSchemaCall && api.listSchemaCall(value);
                        form.resetFields(["schemaName"])
                    }} disabled={isContentMaster} placeholder={"지식맵을 선택해주세요."}/>
                </Form.Item>

                <Form.Item
                    name="schemaName"
                    label="스키마 이름"
                    rules={[{required: true, message: '스키마를 선택해주세요.'}]}
                >
                    <Select
                        allowClear
                        style={{width: '100%'}}
                        showSearch
                        onChange={(data) => data === undefined && form.resetFields()}
                        onSelect={(data) => {
                            form.resetFields(["schemaAttributes"]);
                            const targetSchema = schemas.find((sc: any) => sc.name === data) as any;
                            setSelectedSchema(() => ({...targetSchema}));
                            form.setFieldsValue({
                                schemaName: data,
                                props:
                                    targetSchema?.props.length === 0
                                        ? [{name: 'Property', type: undefined}]
                                        : _.map(targetSchema.props, ({name, type}) => ({
                                            name,
                                            type,
                                        })),
                                labelPropName: targetSchema.labelPropName,
                                uniqPropName: targetSchema.uniqPropName,
                            });
                        }}
                        placeholder="스키마를 선택해주세요"
                        optionFilterProp={'label'}
                        options={schemaOptions}
                        data-test="schema-name"
                        disabled={isContentMaster}
                    />
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                    {({getFieldValue, getFieldsValue}) => {
                        const formValue = getFieldsValue();
                        const isNodeType = selectedSchema.type === 'node';
                        const schemaPropNames = _.map(selectedSchema.props, (p) => p.name);
                        const csvData = isNodeType
                            ? [schemaPropNames] || [[]]
                            : [
                                [
                                    `From_${formValue.fromNodeName}_${formValue.fromNodeUniqKey}`,
                                    `To_${formValue.toNodeName}_${formValue.toNodeUniqKey}`,
                                    ...schemaPropNames,
                                ],
                            ];
                        const disabledTemplateAndUpload = isNodeType
                            ? !getFieldValue('schemaName')
                            : !(
                                getFieldValue('schemaName') &&
                                getFieldValue('fromNodeName') &&
                                getFieldValue('toNodeName')
                            );

                        // const validateButton =
                        //   getFieldValue('fileUploader')?.file?.status === 'done' ? (
                        //     <Button disabled block>
                        //       데이터 오류 검사
                        //     </Button>
                        //   ) : null;
                        return (
                            <>
                                {getFieldValue('schemaName') && (
                                    <>
                                        <Form.Item
                                            label="스키마 속성"
                                            required={isNodeType}
                                            tooltip={
                                                isNodeType
                                                    ? {
                                                        title:
                                                            '노드 타입의 경우 1개 이상의 스키마 속성을 입력해야 합니다.',
                                                        icon: <InfoCircleOutlined/>,
                                                    }
                                                    : ''
                                            }
                                        >
                                            <InputAndSelectFormList
                                                name="props"
                                                typeOptions={['text', 'number']}
                                                disabled={true}
                                                isPropNameRequired={false}
                                                useStaticUid={isNodeType}
                                            />
                                        </Form.Item>
                                        {isNodeType ? (
                                            <NodeTypeMatchingFields disabled={true}/>
                                        ) : (
                                            <RelationTypeMatchingFields
                                                schemaList={schemas}
                                                form={form}
                                            />
                                        )}
                                    </>
                                )}

                                <Form.Item
                                    extra={
                                        '템플릿을 다운로드 받아서 스키마 속성 데이터를 입력 한 후 저장하여 아래 파일 업로드를 진행해 주시기 바랍니다.'
                                    }
                                >
                                    <CSVLink
                                        data={csvData}
                                        filename={`kk_${selectedSchema.type}_${formValue?.schemaName}.csv`}
                                    >
                                        <Button
                                            icon={<RightOutlined/>}
                                            disabled={disabledTemplateAndUpload}
                                        >
                                            템플릿 다운로드
                                        </Button>
                                    </CSVLink>
                                </Form.Item>
                                <ParseFileFormItem
                                    name="file"
                                    disabled={disabledTemplateAndUpload}
                                />
                                {/* {validateButton} */}
                            </>
                        );
                    }}
                </Form.Item>
                <Divider/>
                <Row justify="end">
                    <Space>
                        <Button
                            htmlType="button"
                            onClick={showCancelConfirm}
                            data-test="cancel-button"
                            disabled={isContentMaster}
                        >
                            취소
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isPending}
                            data-test="save-button"
                            disabled={isContentMaster}
                        >
                            저장
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Col>
    );
};

export default ImporterUploadPanel;
