import { useEffect, useState } from 'react';
import { useAcl } from '../hooks/authManager';
import { Table, Typography, Button } from 'antd';
import { useRecoilState } from 'recoil';
import { selectedAclState } from '../hooks/authAtom';
import { ModalMode } from '../hooks/authType';
import AuthAclModal from './AuthAclModal';

import './AuthGeneral.css';

const { Title } = Typography;

const AuthAcl: React.FC = () => {
  const [acl, selectAcl] = useRecoilState(selectedAclState);
  const { acls, addAcl, updateAcl, hasAcl } = useAcl();
  const [modalMode, setModalMode] = useState('close' as ModalMode);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const openAddAcl = () => setModalMode('add');
  const openEditAcl = () => acl?.id && setModalMode('edit');
  const closeEditAcl = () => setModalMode('close');

  useEffect(() => {
    if (selectedRowKeys.length === 0) return;
    const selected_id = selectedRowKeys[0];
    const found_acl = acls.find((acl) => acl.id === selected_id);
    if (found_acl) {
      selectAcl(found_acl);
    }
  }, [acls, selectAcl, selectedRowKeys]);

  const columns = [
    {
      title: '권한 이름',
      dataIndex: 'id',
    },
    {
      title: '접근 권한 설정 (JSON)',
      dataIndex: 'acl_info',
    },
  ];

  return (
    <>
      <div className="part_header" id="acl_header">
        <Title> 접근 권한 </Title>
      </div>
      <Table
        sortDirections={['ascend', 'descend', 'ascend']}
        rowKey={'id'}
        columns={columns}
        dataSource={acls}
        rowSelection={{
          selectedRowKeys,
          type: 'radio',
          onChange: (selectedKeys: React.Key[], selectedRows: any) => {
            // console.log(
            //   `selected row keys: [${selectedRowKeys}]]`,
            //   selectedRows
            // );
            setSelectedRowKeys(selectedKeys);
          },
        }}
        pagination={{
          hideOnSinglePage: true,
        }}
      ></Table>
      <div className="right_buttons" id="acl_buttons">
        <Button shape="round" onClick={openAddAcl}>
          추가
        </Button>
        <Button shape="round" onClick={openEditAcl} disabled={!acl?.id}>
          변경
        </Button>
      </div>
      {modalMode !== 'close' ? (
        <AuthAclModal
          key={'auth_acl_modal'}
          mode={modalMode}
          close={closeEditAcl}
          addAcl={addAcl}
          updateAcl={updateAcl}
          hasAcl={hasAcl}
          acl={acl}
        ></AuthAclModal>
      ) : undefined}
    </>
  );
};

export default AuthAcl;
