const colorMap = {
  KC: '#E93B81',
  // KC: '#E93B81',
  Problem: '#60d394',
  Level: '#96beff',
  Chapter: '#ff9b85',
  Lesson: '#ec6ff9',
  Mastery: '#b7aaff',
  Concept: '#454B61',
  School_grade: '#aaf683',
  School_chapter: '#b371fc',
};

const colorArray = Array.from(Object.entries(colorMap));

const indexedColor = (() => {
  const extendColors = [
    'Aqua',
    'Beige',
    'Blue',
    'Black',
    'BlueViolet',
    'Brown',
    'BurlyWood',
    'Chartreuse',
    'Crimson',
    'DarkOliveGreen',
    'DeepPink',
    'GoldenRod',
    'LightCoral',
    'MediumSpringGreen',
    'RoyalBlue',
    'Yellow',
  ];
  const mapColors = colorArray.map((c) => c[1]);
  return [...mapColors, ...extendColors];
})();

export const pickNamedColor = (
  nodeName: string,
  defaultColor = '#cccccc'
): string =>
  (colorArray.find(([colorName]) => nodeName.indexOf(colorName) !== -1) || [
    '',
    defaultColor,
  ])[1];

export const pickIndexColor = (idx: number, defaultColor = '#cccccc') =>
  idx >= 0 && idx < indexedColor.length ? indexedColor[idx] : defaultColor;

export const pickColor = (
  nodeName: string,
  idx: number,
  defaultColor = '#cccccc'
) => pickNamedColor(nodeName, undefined) || pickIndexColor(idx, defaultColor);
