import {Table, Row, Col, Layout} from 'antd';
import { useRequest } from '../hooks/request';
import { useEffect } from 'react';
import Parse from 'parse';
import { useParams } from 'react-router';
import _ from 'lodash';
import dayjs from 'dayjs';

const ImporterJobs = () => {
  const { objectId } = useParams();
  const [{ data }, { run }] = useRequest(() => {
    const query = new Parse.Query('ImportJobInfo');
    query.equalTo('dbInfoId', objectId);
    query.descending('createdAt');
    return query.find({ json: true });
  });

  useEffect(() => {
    run();
  }, [run]);

  const expandedRowRender = (record) => {
    return (
      <>
        <code>
          {`MATCH (n {_importJobInfoId:"${record.objectId}"}) RETURN n LIMIT 50`}
        </code>
        <br />
        <code>
          {`MATCH p=()-[r:PREREQUISITE {_importJobInfoId:"${record.objectId}"}]->() RETURN p LIMIT 50`}
        </code>
        <br />
        <br />
        <code>
          - 노드 삭제 (연결 relation 포함) <br />
          {`MATCH (n {_importJobInfoId:"${record.objectId}"}) DETACH_DELETE`}
        </code>
        <br />
        <code>
          - relation만 삭제 <br />
          {`MATCH p=()-[r:PREREQUISITE {_importJobInfoId:"${record.objectId}"}]->() DETACH DELETE r`}
        </code>
        <pre style={{ margin: 0, fontSize: 8 }}>
          {JSON.stringify(record.matchingInfo, null, 1)}
        </pre>
      </>
    );
  };
  const columns = [
    {
      title: 'importJobInfoId',
      dataIndex: 'objectId',
    },
    // {
    //   title: 'jobStatusId',
    //   dataIndex: 'jobStatusId',
    // },
    {
      title: 'jobStatus',
      dataIndex: 'jobStatus',
      render: (v) => v.message,
    },
    {
      title: 'matchingInfo',
      dataIndex: 'matchingInfo',
      render: (v) => {
        return _.map(_.groupBy(v, 'schemaType'), (v, k) => {
          return `${k} : ${v.map((i) => i.schemaName)}`;
        }).join(', ');
      },
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
      render: (v) => dayjs(v).format('LLL'),
    },
  ];

  return (
    <Layout style={{padding: '24px', backgroundColor: 'white'}}>
      <Row>
        <h1>Importer Jobs</h1>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            rowKey={'objectId'}
            columns={columns}
            dataSource={data}
            expandable={{ expandedRowRender }}
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default ImporterJobs;
