/* eslint-disable no-template-curly-in-string */
import { FC, Fragment, useCallback } from 'react';
import { Form, Button, Modal, Input } from 'antd';
import { ModalMode, DomainData } from '../hooks/authType';

interface AuthDomainModalProps {
  mode: ModalMode;
  company_id: string;
  close: () => void;
  addDomain: (data: DomainData) => Promise<boolean>;
  updateDomain: (data: DomainData) => Promise<boolean>;
  hasDomain: (domain_id: string) => Promise<boolean>;
  domain: DomainData;
}

export const AuthDomainModal: FC<AuthDomainModalProps> = ({
  mode,
  company_id,
  close,
  addDomain,
  updateDomain,
  hasDomain,
  domain,
}) => {
  const [form] = Form.useForm();
  const edit_mode = mode === 'edit';
  const validateDomainId = useCallback(async (_, value) => {
    if (/\s/.test(value)) {
      return Promise.reject(
        new Error('서비스 ID는 공백을 포함할 수 없습니다.')
      );
    }

    return Promise.resolve();
  }, []);

  const handleCancel = () => {
    form.resetFields();
    close();
  };

  const handleOk = () => {
    form.submit();
  };

  const startWithCompanyId = (id: string) =>
    id.startsWith(company_id) ? id : company_id + '_' + id;

  const onFinish = (values: any) => {
    // console.log('company values (onFinish): ', values);
    const { id, domain_info } = values;
    const domain_info_json = JSON.parse(domain_info);
    const object_domain = {
      company: company_id,
      id,
      domain_info: domain_info_json,
    };
    if (edit_mode) {
      const stringified_domain_info = JSON.stringify(object_domain, null, 4);
      if (stringified_domain_info !== domain.domain_info) {
        updateDomain(object_domain)
          .then((updated) => {
            if (updated) {
              close();
            } else {
              alert('failed to update service');
            }
          })
          .catch((err) => alert('failed to update service'));
      } else {
        close();
      }
      return;
    }

    const valid_id = startWithCompanyId(id);
    hasDomain(valid_id)
      .then((exist_id) => {
        if (exist_id) {
          form.setFields([
            {
              name: 'id',
              errors: ['사용중인 ID입니다.'],
            },
          ]);
          return;
        }

        addDomain(object_domain).then((saved) => {
          if (saved) {
            close();
          } else {
            alert('failed to save domain.');
          }
        });
      })
      .catch((err) => {
        console.error('failed to save domain:', err);
        alert('failed to save domain.');
      });
  };

  // const onChange = (value: any) => {
  //   console.log('domain values (onChange): ', value);
  // };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      string: '${label} is not a valid string!',
    },
    string: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const initialValues = {
    company_id,
    ...(edit_mode && domain),
  };

  return (
    <Fragment>
      <Modal
        key={'domain_modal'}
        visible={true}
        title={'서비스 ' + (mode === 'add' ? '추가' : '편집')}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={[
          <div style={{ textAlign: 'center' }}>
            <Button onClick={handleCancel}>취소</Button>
            <Button type="primary" onClick={handleOk}>
              저장
            </Button>
          </div>,
        ]}
      >
        <Form
          form={form}
          onFinish={onFinish}
          // onChange={onChange}
          labelCol={{ span: 4 }}
          layout="horizontal"
          // labelAlign="left"
          key={'domain_edit_box'}
          initialValues={initialValues}
          validateMessages={validateMessages}
        >
          <Form.Item
            key={'domain_id'}
            name="id"
            label="서비스ID"
            wrapperCol={{ span: 6 }}
            rules={[
              { required: true },
              { type: 'string', min: 2, max: 20 },
              { validator: validateDomainId },
            ]}
          >
            <Input disabled={edit_mode} placeholder="enter ID"></Input>
          </Form.Item>
          <Form.Item
            key={'domain_company_id'}
            name="company"
            label="회사"
            wrapperCol={{ span: 6 }}
            rules={[{ required: true }]}
          >
            <Input disabled={true}></Input>
          </Form.Item>
          <Form.Item
            key={'domain_info'}
            name="domain_info"
            label="도메인 설정"
            rules={[{ type: 'string', min: 2, max: 500 }]}
          >
            <Input.TextArea showCount autoSize={true} />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default AuthDomainModal;
