import _ from 'lodash';
import { Form, Button, Input, Select, Modal, Space } from 'antd';
import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';

const typeDataToView = {
  number: '숫자',
  text: '텍스트',
};

const convertToViewType = (typeName) => {
  if (typeDataToView[typeName] === undefined) return typeName;
  // console.log(`${typeName} => ${typeDataToView[typeName]}`);
  return typeDataToView[typeName];
};

const InputAndSelectFormList = ({
  name,
  typeOptions,
  disabled = false,
  isPropNameRequired,
  useStaticUid,
}) => {
  return (
    <Form.List
      name={name}
      rules={[
        {
          validator: async (rule, props) => {
            const names = _.map(props, 'name');
            if (names.length !== _.uniq(names).length) {
              return Promise.reject(new Error('속성명은 중복될 수 없습니다'));
            }
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => {
        // console.log(JSON.stringify(fields, null, 2));
        return (
          <>
            {fields.map((field, idx) => (
              <Space
                data-test="schema-prop-row-in-graph-schema"
                key={'space_' + idx.toString()}
              >
                <Form.Item
                  {...field}
                  fieldKey={[field.fieldKey, 'name']}
                  name={[field.name, 'name']}
                  rules={
                    isPropNameRequired && [
                      {
                        required: true,
                        message: '속성 이름을 입력하세요',
                      },
                      {
                        min: 2,
                        message: '최소 2글자를 입력해주세요',
                      },
                    ]
                  }
                >
                  <Input
                    data-test="input-schema-prop-name-in-graph-schema-panel"
                    disabled={disabled || (useStaticUid && idx === 0)}
                    placeholder="속성 이름"
                    maxLength={100}
                  />
                </Form.Item>

                <Form.Item
                  {...field}
                  fieldKey={[field.fieldKey, 'type']}
                  name={[field.name, 'type']}
                  initialValue="text"
                  rules={[
                    {
                      required: true,
                      message: '속성 타입을 선택하세요',
                    },
                  ]}
                >
                  <Select
                    data-test="selector-schema-prop-type-in-graph-schema"
                    disabled={disabled || (useStaticUid && idx === 0)}
                    style={{ width: 100 }}
                  >
                    <Select.Option
                      data-test="option-schema-text-prop-type-in-graph-schema"
                      value={typeOptions[0]}
                    >
                      {convertToViewType(typeOptions[0])}
                    </Select.Option>
                    <Select.Option
                      data-test="option-schema-number-prop-type-in-graph-schema-panel"
                      value={typeOptions[1]}
                    >
                      {convertToViewType(typeOptions[1])}
                    </Select.Option>
                  </Select>
                </Form.Item>

                {!disabled && (
                  <Form.Item data-test="delete-btn-for-schema-prop">
                    {/* <Button
                      onClick={() => {
                        Modal.confirm({
                          title: '해당 스키마 속성을 삭제하겠습니까?',
                          icon: <ExclamationCircleOutlined />,
                          okText: 'Yes',
                          okType: 'danger',
                          cancelText: 'No',
                          onOk() {
                            remove(field.name);
                          },
                          onCancel() {},
                          transitionName: '',
                        });
                      }}
                      disabled={fields.length === 1}
                    >
                      삭제
                    </Button> */}
                    {fields.length === 1 || (useStaticUid && idx === 0) ? (
                      <MinusCircleOutlined style={{ color: '#ccc' }} />
                    ) : (
                      <MinusCircleOutlined
                        disabled={true}
                        onClick={() => {
                          Modal.confirm({
                            title: '해당 스키마 속성을 삭제하겠습니까?',
                            icon: <ExclamationCircleOutlined />,
                            okText: '네',
                            okType: 'danger',
                            cancelText: '아니오',
                            onOk() {
                              remove(field.name);
                            },
                            onCancel() {},
                            transitionName: '',
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                )}
              </Space>
            ))}
            {!disabled && (
              <Button
                data-test="add-prop"
                type="dashed"
                icon={<PlusOutlined />}
                disabled={disabled}
                style={{ width: '100%' }}
                onClick={() => add()}
              >
                속성 추가
              </Button>
            )}
            <Form.ErrorList errors={errors} />
          </>
        );
      }}
    </Form.List>
  );
};

export default InputAndSelectFormList;
